import {  useState } from "react";

export default function Stages(params) {
    const [first, setfirst] = useState(1);

    return (
        <section className="stages section">
            <h2 className="section-title">
                Как мы работаем
            </h2>
            <div></div>
            <ol className="stages__list">
                <li className={first === 1 ? "stages__item active" : "stages__item" }>
                    <span className="stages__item-number">
                        01
                    </span>
                    <h3 className="stages__item-title">
                        Определение даты
                    </h3>
                    <p className="stages__item-descr">
                        Позвоните нам по телефону для
                        определения даты Вашего праздника
                    </p>
                    <a
                        href="tel:+79176142307"
                        className="stages__item-link"
                    >
                        8 (917) 614-23-07
                    </a>
                </li>
                <li className={first === 2 ? "stages__item active" : "stages__item" }>
                    <span className="stages__item-number">
                        02
                    </span>
                    <h3 className="stages__item-title">
                        Расскажите нам о своих пожеланиях
                    </h3>
                    <p className="stages__item-descr">
                        Итак, ваша дата свободна? Отлично!
                        Расскажите нам о своих пожеланиях,
                        запланированном бюджете и месте
                        проведения торжества.
                    </p>
                </li>
                <li className={first === 3 ? "stages__item active" : "stages__item" }>
                    <span className="stages__item-number">
                        03
                    </span>
                    <h3 className="stages__item-title">
                        Консультация со специалистом
                    </h3>
                    <p className="stages__item-descr">
                        Далее наш специалист подберет вам
                        наилучший вариант, учитывая все
                        нюансы вашего торжества.
                    </p>
                </li>
                <li className={first === 4 ? "stages__item active" : "stages__item" }>
                    <span className="stages__item-number">
                        04
                    </span>
                    <h3 className="stages__item-title">
                        Согласование деталей
                    </h3>
                    <p className="stages__item-descr">
                        На личной встрече или онлайн обсудим
                        и согласуем все детали, предложим
                        красивые идеи, учитывая ваш бюджет.
                    </p>
                </li>
                <li className={first === 5 ? "stages__item active" : "stages__item" }>
                    <span className="stages__item-number">
                        05
                    </span>
                    <h3 className="stages__item-title">
                        Заключение договора
                    </h3>
                    <p className="stages__item-descr">
                        Все детали согласованы, теперь
                        заключаем договор и ваша счастливая
                        дата забронирована.
                    </p>
                </li>
                <li className={first === 6 ? "stages__item active" : "stages__item" }>
                    <span className="stages__item-number">
                        06
                    </span>
                    <h3 className="stages__item-title">
                        Воплощение вашей мечты
                    </h3>
                    <p className="stages__item-descr">
                        Можете быть уверены, ваше событие
                        будет невероятно красивым и
                        эффектным!
                    </p>
                </li>
            </ol>
            <div className="stages_cheked">
                <ol>
                    <li onClick={()=>setfirst(1)} className={first===1 ? "active" : undefined}>1</li>
                    <li onClick={()=>setfirst(2)} className={first===2 ? "active" : undefined}>2</li>
                    <li onClick={()=>setfirst(3)} className={first===3 ? "active" : undefined}>3</li>
                    <li onClick={()=>setfirst(4)} className={first===4 ? "active" : undefined}>4</li>
                    <li onClick={()=>setfirst(5)} className={first===5 ? "active" : undefined}>5</li>
                    <li onClick={()=>setfirst(6)} className={first===6 ? "active" : undefined}>6</li>
                </ol>
            </div>
        </section>
    );
}
