import ActionBlock from "../bloc/actionBlock";
import Description from "../bloc/description";
import Stages from "../bloc/stages";
import Subscribe from "../bloc/subscribe";
import "../css/professional-salutes.css";
export default function ProfessionalSalutes(params) {
    return (
        <>
            <main className="main">
                <Description
                    name={"Профессиональные салюты"}
                    text={
                        "Профессиональный фейерверк - это высотный салют (может достигать высоты 300 метров), запуск которого производится при помощи специального оборудования. Право на организацию и запуск таких фейерверков имеют фирмы получившие лицензию на применение пиротехнических изделий IV и V классов."
                    }
                    gall={
                        <ul className="description__gallery">
                            <li className="description__gallery-item">
                                <a
                                    className="description__gallery-link"
                                    href="img/pages/professional-salutes/gallery/1.jpg"
                                    data-fslightbox="professional-salutes"
                                >
                                    <img
                                        className="description__gallery-image"
                                        src="img/pages/professional-salutes/gallery/1.jpg"
                                    />
                                </a>
                            </li>
                            <li className="description__gallery-item">
                                <a
                                    className="description__gallery-link"
                                    href="img/pages/professional-salutes/gallery/2.jpg"
                                    data-fslightbox="professional-salutes"
                                >
                                    <img
                                        className="description__gallery-image"
                                        src="img/pages/professional-salutes/gallery/2.jpg"
                                    />
                                </a>
                            </li>
                            <li className="description__gallery-item">
                                <a
                                    className="description__gallery-link"
                                    href="img/pages/professional-salutes/gallery/3.jpg"
                                    data-fslightbox="professional-salutes"
                                >
                                    <img
                                        className="description__gallery-image"
                                        src="img/pages/professional-salutes/gallery/3.jpg"
                                    />
                                </a>
                            </li>
                            <li className="description__gallery-item">
                                <a
                                    className="description__gallery-link"
                                    href="img/pages/professional-salutes/gallery/4.jpg"
                                    data-fslightbox="professional-salutes"
                                >
                                    <img
                                        className="description__gallery-image"
                                        src="img/pages/professional-salutes/gallery/4.jpg"
                                    />
                                </a>
                            </li>
                        </ul>
                    }
                    fease={
                        <>
                         <source srcSet="imgwebp/pages/professional-salutes/bg.webp" type="image/webp"/><img
                    src="img/pages/professional-salutes/bg.jpg" alt="Профессиональные салюты" className="description__img"/>
                        </>
                    }
                />

                <section className="sorts">
                    <div className="sorts__wrapper">
                        <h2 className="sorts__title section-title">
                            Виды
                            профессиональных
                            салютов
                        </h2>
                        <div className="sorts__info">
                            <p className="sorts__text">
                                Высотный фейерверк - самый
                                крупный и эффектный. Для его
                                проведения используется
                                профессиональная пиротехника
                                калибров от 2,5"(63 мм) до
                                12"(300 мм), соотвественно и
                                высота, а так же диаметр
                                раскрытия таких
                                пиротехнических зарядов - от
                                60 до 350 метров. Масштаб
                                такого фейерверка не оставит
                                равнодушным никого на Вашем
                                празднике, и более того, его
                                увидят люди которые
                                находятся довольно далеко от
                                места празднования Вашего
                                мероприятия.
                            </p>
                        </div>
                    </div>
                    <ul className="sorts__images">
                        <li className="sorts__images-item">
                            <picture>
                                <source
                                    srcSet="imgwebp/pages/professional-salutes/sorts/photo-1-575.webp"
                                    media="(max-width: 575px)"
                                    type="image/webp"
                                />
                                <source
                                    srcSet="imgwebp/pages/professional-salutes/sorts/photo-1-991.webp"
                                    media="(max-width: 991px)"
                                    type="image/webp"
                                />
                                <source
                                    srcSet="imgwebp/pages/professional-salutes/sorts/photo-1.webp"
                                    media="(min-width: 992px)"
                                    type="image/webp"
                                />
                                <img
                                    src="img/pages/professional-salutes/sorts/photo-1.jpg"
                                    alt="Профессиональные салюты"
                                    className="sorts__images-img"
                                />
                            </picture>
                        </li>
                        <li className="sorts__images-item">
                            <picture>
                                <source
                                    srcSet="imgwebp/pages/professional-salutes/sorts/photo-2-575.webp"
                                    media="(max-width: 575px)"
                                    type="image/webp"
                                />
                                <source
                                    srcSet="imgwebp/pages/professional-salutes/sorts/photo-2-991.webp"
                                    media="(max-width: 991px)"
                                    type="image/webp"
                                />
                                <source
                                    srcSet="imgwebp/pages/professional-salutes/sorts/photo-2.webp"
                                    media="(min-width: 992px)"
                                    type="image/webp"
                                />
                                <img
                                    src="img/pages/professional-salutes/sorts/photo-2.jpg"
                                    alt="Профессиональные салюты"
                                    className="sorts__images-img"
                                />
                            </picture>
                        </li>
                        <li className="sorts__images-item">
                            <picture>
                                <source
                                    srcSet="imgwebp/pages/professional-salutes/sorts/photo-3-575.webp"
                                    media="(max-width: 575px)"
                                    type="image/webp"
                                />
                                <source
                                    srcSet="imgwebp/pages/professional-salutes/sorts/photo-3-991.webp"
                                    media="(max-width: 991px)"
                                    type="image/webp"
                                />
                                <source
                                    srcSet="imgwebp/pages/professional-salutes/sorts/photo-3.webp"
                                    media="(min-width: 992px)"
                                    type="image/webp"
                                />
                                <img
                                    src="img/pages/professional-salutes/sorts/photo-3.jpg"
                                    alt="Профессиональные салюты"
                                    className="sorts__images-img"
                                />
                            </picture>
                        </li>
                        <li className="sorts__images-item">
                            <picture>
                                <source
                                    srcSet="imgwebp/pages/professional-salutes/sorts/photo-4-575.webp"
                                    media="(max-width: 575px)"
                                    type="image/webp"
                                />
                                <source
                                    srcSet="imgwebp/pages/professional-salutes/sorts/photo-4-991.webp"
                                    media="(max-width: 991px)"
                                    type="image/webp/"
                                />
                                <source
                                    srcSet="imgwebp/pages/professional-salutes/sorts/photo-4.webp"
                                    media="(min-width: 992px)"
                                    type="image/webp"
                                />
                                <img
                                    src="img/pages/professional-salutes/sorts/photo-4.jpg"
                                    alt="Профессиональные салюты"
                                    className="sorts__images-img"
                                />
                            </picture>
                        </li>
                        <li className="sorts__images-item">
                            <picture>
                                <source
                                    srcSet="imgwebp/pages/professional-salutes/sorts/photo-5-575.webp"
                                    media="(max-width: 575px)"
                                    type="image/webp"
                                />
                                <source
                                    srcSet="imgwebp/pages/professional-salutes/sorts/photo-5-991.webp"
                                    media="(max-width: 991px)"
                                    type="image/webp"
                                />
                                <source
                                    srcSet="imgwebp/pages/professional-salutes/sorts/photo-5.webp"
                                    media="(min-width: 992px)"
                                    type="image/webp"
                                />
                                <img
                                    src="img/pages/professional-salutes/sorts/photo-5.jpg"
                                    alt="Профессиональные салюты"
                                    className="sorts__images-img"
                                />
                            </picture>
                        </li>
                        <li className="sorts__images-item">
                            <picture>
                                <source
                                    srcSet="imgwebp/pages/professional-salutes/sorts/photo-6-575.webp"
                                    media="(max-width: 575px)"
                                    type="image/webp"
                                />
                                <source
                                    srcSet="imgwebp/pages/professional-salutes/sorts/photo-6-991.webp"
                                    media="(max-width: 991px)"
                                    type="image/webp"
                                />
                                <source
                                    srcSet="imgwebp/pages/professional-salutes/sorts/photo-6.webp"
                                    media="(min-width: 992px)"
                                    type="image/webp"
                                />
                                <img
                                    src="img/pages/professional-salutes/sorts/photo-6.jpg"
                                    alt="Профессиональные салюты"
                                    className="sorts__images-img"
                                />
                            </picture>
                        </li>
                    </ul>
                </section>
                <section className="content section">
                    <div className="content__wrapper">
                        <div className="content__info">
                            <h2 className="section-title">
                                Заказать
                                профессиональный
                                салют
                            </h2>
                            <div className="content__descr">
                                <p className="content__descr-text">
                                    Традиционно высотные
                                    фейерверки проводятся на
                                    торжественных
                                    мероприятиях: свадьбах,
                                    юбилеях, корпоративных
                                    вечерах. А также на
                                    крупных общественных
                                    праздниках: День города,
                                    День Победы – 9 мая,
                                    Новый год. Цена на
                                    профессиональный салют
                                    зависит от количества
                                    используемой пиротехники
                                    (зарядов, синглов,
                                    роутов, батарей салютов)
                                    и сложности программы.
                                    Мы составим программу и
                                    проведем
                                    профессиональный
                                    фейерверк на любой, даже
                                    небольшой бюджет.
                                </p>
                            </div>
                            <button
                                className="content__order final__button"
                                data-animate-fill
                                data-fos
                            >
                                <span>Забронировать</span>
                            </button>
                        </div>
                        <picture>
                            <source
                                srcSet="imgwebp/pages/professional-salutes/content/order.webp"
                                type="image/webp"
                            />
                            <img
                                src="img/pages/professional-salutes/content/order.jpg"
                                alt="Профессиональные салюты"
                                className="content__img"
                            />
                        </picture>
                    </div>
                </section>
               
                <Stages/>
                <ActionBlock/>
                <Subscribe/>

            </main>
        </>
    );
}
