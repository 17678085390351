import React from "react";
import ReactDOM from "react-dom/client";
import { AppRouste } from "./router";
import "./css/glob.css";

const root = ReactDOM.createRoot(
    document.getElementById("root")
);

root.render(
    <React.StrictMode>
        <AppRouste />
    </React.StrictMode>
);
