import ActionBlock from "../bloc/actionBlock";
import Description from "../bloc/description";
import Stages from "../bloc/stages";
import Subscribe from "../bloc/subscribe";

export function ColdFountains(params) {
    return (
        <>
            <main className="main">
                <Description
                    name={"Холодные фонтаны"}
                    text={
                        "Холодные фонтаны ― это пиротехническое изделие, относится к наиболее популярному виду сценической пиротехнике. При горении выделяет небольшое количество дыма, а искры имеют низкую температуру горения. Безопасное расстояние до людей допускается до 1 метра. Рядом с таким фонтаном можно смело пройти, рядом танцевать или стоять, никакого вреда он Вам не принесет."
                    }
                    gall={
                        <ul className="description__gallery">
                            <li className="description__gallery-item">
                                <a
                                    className="description__gallery-link"
                                    href="img/pages/cold-fountains/gallery/1.jpg"
                                    data-fslightbox="cold-fountains"
                                >
                                    <img
                                        className="description__gallery-image"
                                        src="img/pages/cold-fountains/gallery/1.jpg"
                                    />
                                </a>
                            </li>
                            <li className="description__gallery-item">
                                <a
                                    className="description__gallery-link"
                                    href="img/pages/cold-fountains/gallery/2.jpg"
                                    data-fslightbox="cold-fountains"
                                >
                                    <img
                                        className="description__gallery-image"
                                        src="img/pages/cold-fountains/gallery/2.jpg"
                                    />
                                </a>
                            </li>
                            <li className="description__gallery-item">
                                <a
                                    className="description__gallery-link"
                                    href="img/pages/cold-fountains/gallery/3.jpg"
                                    data-fslightbox="cold-fountains"
                                >
                                    <img
                                        className="description__gallery-image"
                                        src="img/pages/cold-fountains/gallery/3.jpg"
                                    />
                                </a>
                            </li>
                            <li className="description__gallery-item">
                                <a
                                    className="description__gallery-link"
                                    href="img/pages/cold-fountains/gallery/4.jpg"
                                    data-fslightbox="cold-fountains"
                                >
                                    <img
                                        className="description__gallery-image"
                                        src="img/pages/cold-fountains/gallery/4.jpg"
                                    />
                                </a>
                            </li>
                        </ul>
                    }
                    fease={
                        <>
                            <source
                                srcSet="img/pages/cold-fountains/bg.jpg"
                                type="image/webp"
                            />
                            <img
                                src="img/pages/cold-fountains/bg.jpg"
                                alt="Холодные фонтаны"
                                className="description__img"
                            />{" "}
                        </>
                    }
                />
                <section className="content section">
                    <div className="content__wrapper">
                        <div className="content__info">
                            <h2 className="section-title">
                                Купить
                                холодный
                                фонтан на свадьбу
                                в Ульяновске
                            </h2>
                            <div className="content__descr">
                                <p className="content__descr-text">
                                    Холодный сценический
                                    фонтан не разбрасывает
                                    горячих искр (в отличие
                                    от бытовых), а это
                                    значит, что вы можете
                                    продумать целый сценарий
                                    для своего выхода: к
                                    холодным фонтанам можно
                                    подходить вплотную, его
                                    искры не способны
                                    причинить вред даже
                                    воздушному свадебному
                                    платью!
                                </p>
                                <p className="content__descr-text">
                                    Это основное отличие
                                    холодных фонтанов от
                                    бытовых, продающихся в
                                    пиротехнических
                                    магазинах. Что нужно
                                    знать, если вы хотите
                                    заказать холодные
                                    фонтаны на свадьбу? Не
                                    стоит экономить на своих
                                    эмоциях. Сценическая
                                    пиротехника не продается
                                    в обычных магазинах, а
                                    если вы приобретаете
                                    бытовые фонтаны,
                                    безопасными они не
                                    будут. Покупая бытовую
                                    пиротехнику
                                    самостоятельно, вы
                                    берете всю
                                    ответственность на себя:
                                    за то, как они
                                    отработают и за то, как
                                    их поджечь.
                                </p>
                            </div>
                            <button
                                className="content__order final__button"
                                data-animate-fill
                                data-fos
                            >
                                <span>Забронировать</span>
                            </button>
                        </div>
                        <picture>
                            <source
                                srcSet="imgwebp/pages/cold-fountains/content/order.webp"
                                type="image/webp"
                            />
                            <img
                                src="img/pages/cold-fountains/content/order.jpg"
                                alt="Холодные фонтаны"
                                className="content__img"
                            />
                        </picture>
                    </div>
                </section>
                <Stages />
                <ActionBlock/>
                <Subscribe />
            </main>
        </>
    );
}
