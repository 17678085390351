import ActionBlock from "../bloc/actionBlock";
import Description from "../bloc/description";
import Stages from "../bloc/stages";
import Subscribe from "../bloc/subscribe";
import "../css/pyrotechnic-show.css"
export default function PyrotechnicShow(params) {
    return (
        <main className="main">
            <Description
                name={"Пиротехническое шоу"}
                text={
                    "В пиротехническое шоу входят: дорожка из холодных фонтанов, горящее сердце, крутящиеся фонтаны, свадебный фейерверк и музыкальное сопровождение. Ярко и необычно! Почему это стоит сделать? Потому что впечатления от того, что ты идёшь сквозь огонь, усиленные общим впечатлением от пиротехнического шоу, волнением, непередаваемы."
                }
                gall={
                    <ul className="description__gallery">
                        <li className="description__gallery-item">
                            <a
                                className="description__gallery-link"
                                href="img/pages/pyrotechnic-show/gallery/1.jpg"
                                data-fslightbox="pyrotechnic-show"
                            >
                                <img
                                    className="description__gallery-image"
                                    src="img/pages/pyrotechnic-show/gallery/1.jpg"
                                />
                            </a>
                        </li>
                        <li className="description__gallery-item">
                            <a
                                className="description__gallery-link"
                                href="img/pages/pyrotechnic-show/gallery/2.jpg"
                                data-fslightbox="pyrotechnic-show"
                            >
                                <img
                                    className="description__gallery-image"
                                    src="img/pages/pyrotechnic-show/gallery/2.jpg"
                                />
                            </a>
                        </li>
                        <li className="description__gallery-item">
                            <a
                                className="description__gallery-link"
                                href="img/pages/pyrotechnic-show/gallery/3.jpg"
                                data-fslightbox="pyrotechnic-show"
                            >
                                <img
                                    className="description__gallery-image"
                                    src="img/pages/pyrotechnic-show/gallery/3.jpg"
                                />
                            </a>
                        </li>
                        <li className="description__gallery-item">
                            <a
                                className="description__gallery-link"
                                href="img/pages/pyrotechnic-show/gallery/4.jpg"
                                data-fslightbox="pyrotechnic-show"
                            >
                                <img
                                    className="description__gallery-image"
                                    src="img/pages/pyrotechnic-show/gallery/4.jpg"
                                />
                            </a>
                        </li>
                    </ul>
                }
                fease={
                    <>
                        <source
                            srcSet="img/pages/pyrotechnic-show/bg.jpg"
                            type="image/webp"
                        />
                        <img
                            src="img/pages/pyrotechnic-show/bg.jpg"
                            alt="Пиротехническое шоу"
                            className="description__img"
                        />
                    </>
                }
            />

            <section className="advantages section">
                <h2 className="section-title advantages__title">
                    Преимущества
                    пиротехнического
                    шоу
                </h2>
                <ul className="advantages__list">
                    <li className="advantages__item advantages__item_safety">
                        <p className="advantages__descr">
                            Мы приезжаем на площадку,
                            безопасно устанавливаем холодные
                            фонтаны и управляем поджигом
                            фонтанов с помощью дистанционных
                            пультов.
                        </p>
                        <picture>
                            <source
                                srcSet="img/pages/pyrotechnic-show/advantages/photo-1.jpg"
                                type="image/webp"
                            />
                            <img
                                src="img/pages/pyrotechnic-show/advantages/photo-1.jpg"
                                alt="Безопасность"
                                className="advantages__img"
                            />
                        </picture>
                    </li>
                    <li className="advantages__item advantages__item_variety">
                        <p className="advantages__descr">
                            Установка холодный фонтанов
                            может быть разнообразна:
                            классическая дорожка, в одну
                            линию, полукругом, квадратом,
                            всё зависит от ваших пожеланий.
                        </p>
                        <picture>
                            <source
                                srcSet="img/pages/pyrotechnic-show/advantages/photo-2.jpg"
                                type="image/webp"
                            />
                            <img
                                src="img/pages/pyrotechnic-show/advantages/photo-2.jpg"
                                alt="Разнообразие"
                                className="advantages__img"
                            />
                        </picture>
                    </li>
                    <li className="advantages__item advantages__item_duration">
                        <p className="advantages__descr">
                            Стандартное время работы
                            холодных фонтанов: 30-60 секунд
                        </p>
                        <picture>
                            <source
                                srcSet="img/pages/pyrotechnic-show/advantages/photo-3.jpg"
                                type="image/webp"
                            />
                            <img
                                src="img/pages/pyrotechnic-show/advantages/photo-3.jpg"
                                alt="Длительность"
                                className="advantages__img"
                            />
                        </picture>
                    </li>
                    <li className="advantages__item advantages__item_height">
                        <p className="advantages__descr">
                            Высота фонтанов – 3 или 6
                            метров. Цвет искр – серебро или
                            золото.
                        </p>
                        <picture>
                            <source
                                srcSet="img/pages/pyrotechnic-show/advantages/photo-4.jpg"
                                type="image/webp"
                            />
                            <img
                                src="img/pages/pyrotechnic-show/advantages/photo-4.jpg"
                                alt="Высота"
                                className="advantages__img"
                            />
                        </picture>
                    </li>
                    <li className="advantages__item advantages__item_resistance">
                        <p className="advantages__descr">
                            Фонтаны работают в любую погоду
                            - дождь не страшен. А ещё, как
                            они горят - видно в любое время.
                        </p>
                        <picture>
                            <source
                                srcSet="img/pages/pyrotechnic-show/advantages/photo-5.jpg"
                                type="image/webp"
                            />
                            <img
                                src="img/pages/pyrotechnic-show/advantages/photo-5.jpg"
                                alt="Устойчивость"
                                className="advantages__img"
                            />
                        </picture>
                    </li>
                </ul>
            </section>
            <section className="spinners section">
                <h2 className="section-title spinners__title">
                    Пиротехнические
                    вертушки
                </h2>
                <div className="spinners__descr">
                    <p className="spinners__text spinners__text_left">
                        Роскошная композиция из крутящихся
                        холодных фонтанов. Композиция может
                        состоять из одной или двух вертушек.
                        На одну вертушку устанавливаем от
                        3-х до 12-ти холодных фонтанов.
                        Кроме холодных фонтанов и
                        пиротехнических вертушек, есть ещё
                        немало эффектных вариантов
                        инсталляций для наземного шоу:
                    </p>
                    <p className="spinners__text spinners__text_right">
                        это горящие буквы, цифры и огненное
                        сердце, которое молодожёны зажигают
                        самостоятельно с помощью
                        специального факела. Это
                        трогательная и романтическая
                        церемония, символизирующая
                        неугасающий огонь любви.
                    </p>
                    <a
                        href="https://shop-salut.ru/"
                        className="spinners__link spinners__link_1920"
                        data-animate-fill
                    >
                        <span>Хочу так же</span>
                    </a>
                </div>
                <picture>
                    <source
                        srcSet="img/pages/pyrotechnic-show/spinners.jpg"
                        // srcSet="imgwebp/pages/pyrotechnic-show/spinners.webp"
                        type="image/webp"
                    />
                    <img
                        src="img/pages/pyrotechnic-show/spinners.jpg"
                        alt="Пиротехнические вертушки"
                        className="spinners__img"
                    />
                </picture>
                <p className="spinners__text spinners__text_bottom">
                    Мы приезжаем на место, устанавливаем
                    холодные фонтаны и управляем их поджигом
                    с помощью дистанционных пультов.
                    Представленные пиротехнические средства
                    работают в любую погоду, дождь и ветер
                    им не страшны. Наши приоритеты:
                    безопасность, качество представления,
                    удовольствие клиента. Стоимость
                    специальных эффектов зависит от их вида
                    и количества Подробности можно уточнить
                    по телефону или через наш каталог.
                </p>
                <a
                    href="https://shop-salut.ru/"
                    className="spinners__link spinners__link_576"
                    data-animate-fill
                >
                    <span>Хочу так же</span>
                </a>
            </section>
            <section className="content section">
                <div className="content__wrapper">
                    <div className="content__info">
                        <h2 className="section-title">
                            Огнепад или
                            огненный
                            водопад
                        </h2>
                        <div className="content__descr">
                            <p className="content__descr-text">
                                Огнепад или огненный водопад
                                – потоки золотых или
                                серебряных искр обрушиваются
                                на землю с высоты, формируя
                                настоящую стену огня! Он
                                легко комбинируется с
                                дорожкой из холодных
                                фонтанов, свадебным
                                фейерверком.
                                Продолжительность огнепада
                                40-60 сек.
                            </p>
                        </div>
                        <button
                            className="content__order final__button"
                            data-animate-fill
                            data-fos
                        >
                            <span>Забронировать</span>
                        </button>
                    </div>
                    <picture>
                        <source
                            srcSet="imgwebp/pages/pyrotechnic-show/content/firefall.webp"
                            type="image/webp"
                        />
                        <img
                            src="img/pages/pyrotechnic-show/content/firefall.jpg"
                            alt="Огнепад или огненный водопад"
                            className="content__img"
                        />
                    </picture>
                </div>
            </section>
            <section className="content section">
                <div className="content__wrapper">
                    <div className="content__info content__info_second">
                        <h2 className="section-title">
                            Горящие сердца
                        </h2>
                        <div className="content__descr">
                            <p className="content__descr-text">
                                Сердце – символ любви.
                                Завершите свое торжество
                                эффектно и не забываемо.
                                Красивым завершением вечера
                                Вы и ваши гости почувствуете
                                важность этого момента, его
                                торжественность. В своей
                                работе мы используем 2 вида
                                сердец: пиротехническое и
                                огненное. Пиротехническое
                                сердце – это конструкция в
                                виде сердца или пары сердец,
                                дополненная пиротехническими
                                элементами по контуру, при
                                зажжении которых получается
                                яркий эффект искрящегося
                                сердца. Поджигает пиротехник
                                с помощью дистанционных
                                пультов.
                            </p>
                            <p className="content__descr-text">
                                Огненное сердце -
                                конструкция из огнеупорного
                                каната на стальном каркасе.
                                Поджигается молодожёнами с
                                помощью огненного факела.
                            </p>
                        </div>
                        <button
                            className="content__order final__button"
                            data-animate-fill
                            data-fos
                        >
                            <span>Забронировать</span>
                        </button>
                    </div>
                    <picture className="content__picture content__picture_first">
                        <source
                            srcSet="imgwebp/pages/pyrotechnic-show/content/fiery-numbers-and-letters.webp"
                            type="image/webp"
                        />
                        <img
                            src="img/pages/pyrotechnic-show/content/fiery-numbers-and-letters.jpg"
                            alt="Огненные цифры и буквы"
                            className="content__img"
                        />
                    </picture>
                </div>
            </section>


            <Stages/>
            <ActionBlock/>
            <Subscribe/>

        </main>
    );
}
