import axios from "axios";
import { useEffect, useState } from "react";
import ReactInputMask from "react-input-mask";
import { Spinner } from "./callback";

export default function PopupFos(params) {
    const [popup, setpopup] = useState(false);

    useEffect(() => {
        if (document.querySelectorAll("[data-fos]")) {
            document
                .querySelectorAll("[data-fos]")
                .forEach((element) => {
                    element.addEventListener(
                        "click",
                        () => {
                            setpopup(!popup);
                        }
                    );
                });
        }
        if (document.querySelector(".popupFos.active")) {
            document
                .querySelector(".popupFos.active")
                .addEventListener("click", (e) => {
                    if (
                        e.target.classList.contains(
                            "active"
                        )
                    ) {
                        setpopup(!popup);
                    }
                });
        }
    }, [document.querySelectorAll("[data-fos]")]);

    function SubForm(e) {
        e.preventDefault();
        let shou = "";
        let index = 0;
        e.target.schou.forEach((element) => {
            if (element.checked) {
                shou += element.value + " ";
                index++;
            }
        });
        let obj = {
            date: e.target.date.value,
            name: e.target.name.value,
            phone: e.target.phone.value,
            type: e.target.type.value,
            schou: shou,
        };
        document.querySelector(
            ".popupFos_fos .spinner-wrapper"
        ).style.display = "block";
        document.querySelector(
            ".popupFos_fos .popupFos_fos_container"
        ).style.display = "none";
        axios
            .post(
                "/date/mail.php",
                JSON.stringify(obj)
            )
            .then((response) => {
                console.log(response.data);
                if (response.data) {
                    document.querySelector(
                        ".popupFos_fos .spinner-wrapper"
                    ).style.display = "none";
                    document.querySelector(".modal__message_success").style.display = "block";
                  
                }
            })
            .catch((error) => {
                console.log(error);
                document.querySelector(".modal__message_success").style.display = "block";

            });
    }

    return (
        <>
            <div
                className={
                    popup ? "popupFos active" : "popupFos"
                }
            >
                <div className="popupFos_content">
                    <div onClick={()=>setpopup(!popup)} className="popupFos_exit">
                    ×
                    </div>
                    <form
                        className="popupFos_fos"
                        action=""
                        onSubmit={SubForm}
                    >
                        <img
                            className="inputOutput__img"
                            src="img/blocks/header/logo.svg"
                        />
                        <div className="popupFos_fos_container">
                            <div className="popupFos_fos_container-a">
                                <div className="popupFos_fos_box">
                                    <p className="modal__text">
                                        Укажите дату и время
                                        Вашего мероприятия:
                                    </p>
                                    <input
                                        type="hidden"
                                        defaultValue={
                                            "Заказ мероприятия"
                                        }
                                        name="type"
                                    />
                                    <input
                                        required
                                        type="date"
                                        name="date"
                                    />
                                </div>
                                <div className="popupFos_fos_box">
                                    <p className="modal__text">
                                        Выберите элементы
                                        шоу:
                                    </p>
                                    <div className="popupFos_fos_box_chek">
                                        <label htmlFor="schou1">
                                            <picture className="modal__item-picture">
                                                <source
                                                    srcSet="imgwebp/blocks/modal/radiobuttons/tyazhelii-dim.webp"
                                                    type="image/webp"
                                                />
                                                <img
                                                    src="#"
                                                    data-src="img/blocks/modal/radiobuttons/tyazhelii-dim.jpg"
                                                    alt="Тяжелый дым"
                                                    className="modal__item-img"
                                                />
                                            </picture>
                                            <p>
                                                Тяжелый дым
                                            </p>
                                            <input
                                                defaultValue={
                                                    "Тяжелый дым"
                                                }
                                                type="checkbox"
                                                name="schou"
                                                id="schou1"
                                            />
                                        </label>
                                        <label htmlFor="schou2">
                                            <picture className="modal__item-picture">
                                                <source
                                                    srcSet="imgwebp/blocks/modal/radiobuttons/goryatschie-serdsta.webp"
                                                    type="image/webp"
                                                />
                                                <img
                                                    src="#"
                                                    data-src="img/blocks/modal/radiobuttons/goryatschie-serdsta.jpg"
                                                    alt="Горящие сердца"
                                                    className="modal__item-img"
                                                />
                                            </picture>
                                            <p>
                                                Горящие
                                                сердца
                                            </p>
                                            <input
                                                defaultValue={
                                                    "Горящие сердца"
                                                }
                                                type="checkbox"
                                                name="schou"
                                                id="schou2"
                                            />
                                        </label>
                                        <label htmlFor="schou3">
                                            <picture className="modal__item-picture">
                                                <source
                                                    srcSet="imgwebp/blocks/modal/radiobuttons/bengalskie-ogni.webp"
                                                    type="image/webp"
                                                />
                                                <img
                                                    src="#"
                                                    data-src="img/blocks/modal/radiobuttons/bengalskie-ogni.jpg"
                                                    alt="Бенгальские огни"
                                                    className="modal__item-img"
                                                />
                                            </picture>
                                            <p>
                                                Бенгальские
                                                огни
                                            </p>
                                            <input
                                                defaultValue={
                                                    "Бенгальские огни"
                                                }
                                                type="checkbox"
                                                name="schou"
                                                id="schou3"
                                            />
                                        </label>
                                        <label htmlFor="schou4">
                                            <picture className="modal__item-picture">
                                                <source
                                                    srcSet="imgwebp/blocks/modal/radiobuttons/salut.webp"
                                                    type="image/webp"
                                                />
                                                <img
                                                    src="#"
                                                    data-src="img/blocks/modal/radiobuttons/salut.jpg"
                                                    alt="Салют"
                                                    className="modal__item-img"
                                                />
                                            </picture>
                                            <p>Салют</p>
                                            <input
                                                defaultValue={
                                                    "Салют"
                                                }
                                                type="checkbox"
                                                name="schou"
                                                id="schou4"
                                            />
                                        </label>
                                        <label htmlFor="schou5">
                                            <picture className="modal__item-picture">
                                                <source
                                                    srcSet="imgwebp/blocks/modal/radiobuttons/piro-shou.webp"
                                                    type="image/webp"
                                                />
                                                <img
                                                    src="#"
                                                    data-src="img/blocks/modal/radiobuttons/piro-shou.jpg"
                                                    alt="Пиро-шоу"
                                                    className="modal__item-img"
                                                />
                                            </picture>
                                            <p>Пиро-шоу</p>
                                            <input
                                                defaultValue={
                                                    "Пиро-шоу"
                                                }
                                                type="checkbox"
                                                name="schou"
                                                id="schou5"
                                            />
                                        </label>
                                        <label htmlFor="schou6">
                                            <picture className="modal__item-picture">
                                                <source
                                                    srcSet="imgwebp/blocks/modal/radiobuttons/tsvetnoi-dim.webp"
                                                    type="image/webp"
                                                />
                                                <img
                                                    src="#"
                                                    data-src="img/blocks/modal/radiobuttons/tsvetnoi-dim.jpg"
                                                    alt="Цветной дым"
                                                    className="modal__item-img"
                                                />
                                            </picture>
                                            <p>
                                                Цветной дым
                                            </p>
                                            <input
                                                defaultValue={
                                                    "Цветной дым"
                                                }
                                                type="checkbox"
                                                name="schou"
                                                id="schou6"
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="popupFos_fos_box">
                                    <p className="modal__text">
                                        Как с Вами
                                        связаться?
                                    </p>
                                    <input
                                        className="modal__form-input modal__user-name"
                                        type="text"
                                        name="name"
                                        style={{
                                            marginBottom:
                                                "20px",
                                        }}
                                        minLength="2"
                                        maxLength="50"
                                        placeholder="Имя"
                                        autoComplete="off"
                                        required
                                    />
                                    <ReactInputMask
                                        className="modal__form-input modal__user-phone"
                                        required
                                        placeholder="Ваш телефон"
                                        name="phone"
                                        mask="+7 (999) 999 99 99"
                                        type="text"
                                    />
                                </div>
                            </div>
                            <div className="popupFos_fos_container-b">
                                <span className="modal__text">
                                    Вы можете связаться с
                                    нами:
                                </span>

                                <div className="modal__contact-info">
                                    <div className="modal__contact-info-left">
                                        <p className="popupFos_p">
                                            По телефону:
                                        </p>
                                        <a
                                            className="header__list-link modal__contact-info-tel modal__contact-info-tel--mb"
                                            href="tel:+79510962111"
                                        >
                                            +7 (951)
                                            096-21-11
                                        </a>
                                        <p className="popupFos_p">
                                            По почте:
                                        </p>
                                        <a
                                            className="header__list-link modal__contact-info-mail"
                                            href="mailto:salyt73@yandex.ru"
                                        >
                                            salyt73@yandex.ru
                                        </a>
                                    </div>
                                    <div className="modal__contact-info-right">
                                        <p className="popupFos_p">
                                            По адресам:
                                        </p>
                                        <a
                                            className="header__list-link modal__contact-info-address"
                                            href="/contacts"
                                        >
                                            <svg
                                                width="19"
                                                height="25"
                                                viewBox="0 0 19 25"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    opacity="0.3"
                                                    d="M9.50001 0C4.25331 0 0 4.21919 0 9.42383C0 11.1644 0.343441 12.9621 1.32901 14.2578L9.50001 25L17.671 14.2578C18.5662 13.0809 19 11.0004 19 9.42383C19 4.21919 14.7467 0 9.50001 0ZM9.50001 5.45806C11.7076 5.45806 13.4978 7.23392 13.4978 9.42381C13.4978 11.6138 11.7076 13.3896 9.50001 13.3896C7.29239 13.3896 5.5022 11.6138 5.5022 9.42383C5.5022 7.23392 7.29239 5.45806 9.50001 5.45806Z"
                                                    fill="white"
                                                ></path>
                                            </svg>{" "}
                                            г. Ульяновск,
                                            Проспект Врача
                                            Сурова 2а
                                        </a>
                                        <br />
                                        <a
                                            className="header__list-link modal__contact-info-address"
                                            href="/contacts"
                                        >
                                            <svg
                                                width="19"
                                                height="25"
                                                viewBox="0 0 19 25"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    opacity="0.3"
                                                    d="M9.50001 0C4.25331 0 0 4.21919 0 9.42383C0 11.1644 0.343441 12.9621 1.32901 14.2578L9.50001 25L17.671 14.2578C18.5662 13.0809 19 11.0004 19 9.42383C19 4.21919 14.7467 0 9.50001 0ZM9.50001 5.45806C11.7076 5.45806 13.4978 7.23392 13.4978 9.42381C13.4978 11.6138 11.7076 13.3896 9.50001 13.3896C7.29239 13.3896 5.5022 11.6138 5.5022 9.42383C5.5022 7.23392 7.29239 5.45806 9.50001 5.45806Z"
                                                    fill="white"
                                                ></path>
                                            </svg>{" "}
                                            г. Ульяновск,
                                            ул. Промышленная
                                            59
                                        </a>
                                        <br />
                                        <a
                                            className="header__list-link modal__contact-info-address"
                                            href="/contacts"
                                        >
                                            <svg
                                                width="19"
                                                height="25"
                                                viewBox="0 0 19 25"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    opacity="0.3"
                                                    d="M9.50001 0C4.25331 0 0 4.21919 0 9.42383C0 11.1644 0.343441 12.9621 1.32901 14.2578L9.50001 25L17.671 14.2578C18.5662 13.0809 19 11.0004 19 9.42383C19 4.21919 14.7467 0 9.50001 0ZM9.50001 5.45806C11.7076 5.45806 13.4978 7.23392 13.4978 9.42381C13.4978 11.6138 11.7076 13.3896 9.50001 13.3896C7.29239 13.3896 5.5022 11.6138 5.5022 9.42383C5.5022 7.23392 7.29239 5.45806 9.50001 5.45806Z"
                                                    fill="white"
                                                ></path>
                                            </svg>{" "}
                                            г. Чердаклы, ул.
                                            Советская 34
                                        </a>
                                        <br />
                                        <a
                                            className="header__list-link modal__contact-info-address"
                                            href="/contacts"
                                        >
                                            <svg
                                                width="19"
                                                height="25"
                                                viewBox="0 0 19 25"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    opacity="0.3"
                                                    d="M9.50001 0C4.25331 0 0 4.21919 0 9.42383C0 11.1644 0.343441 12.9621 1.32901 14.2578L9.50001 25L17.671 14.2578C18.5662 13.0809 19 11.0004 19 9.42383C19 4.21919 14.7467 0 9.50001 0ZM9.50001 5.45806C11.7076 5.45806 13.4978 7.23392 13.4978 9.42381C13.4978 11.6138 11.7076 13.3896 9.50001 13.3896C7.29239 13.3896 5.5022 11.6138 5.5022 9.42383C5.5022 7.23392 7.29239 5.45806 9.50001 5.45806Z"
                                                    fill="white"
                                                ></path>
                                            </svg>{" "}
                                            г. Ульяновск
                                            пр-т. Туполева
                                            15
                                        </a>
                                        <br />
                                        <a
                                            className="header__list-link modal__contact-info-address"
                                            href="/contacts"
                                        >
                                            <svg
                                                width="19"
                                                height="25"
                                                viewBox="0 0 19 25"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    opacity="0.3"
                                                    d="M9.50001 0C4.25331 0 0 4.21919 0 9.42383C0 11.1644 0.343441 12.9621 1.32901 14.2578L9.50001 25L17.671 14.2578C18.5662 13.0809 19 11.0004 19 9.42383C19 4.21919 14.7467 0 9.50001 0ZM9.50001 5.45806C11.7076 5.45806 13.4978 7.23392 13.4978 9.42381C13.4978 11.6138 11.7076 13.3896 9.50001 13.3896C7.29239 13.3896 5.5022 11.6138 5.5022 9.42383C5.5022 7.23392 7.29239 5.45806 9.50001 5.45806Z"
                                                    fill="white"
                                                ></path>
                                            </svg>{" "}
                                            г. Ульяновск ул.
                                            Рябикова 106а
                                        </a>
                                        <a
                                            className="header__list-link modal__contact-info-address"
                                            href="/contacts"
                                        >
                                            <svg
                                                width="19"
                                                height="25"
                                                viewBox="0 0 19 25"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    opacity="0.3"
                                                    d="M9.50001 0C4.25331 0 0 4.21919 0 9.42383C0 11.1644 0.343441 12.9621 1.32901 14.2578L9.50001 25L17.671 14.2578C18.5662 13.0809 19 11.0004 19 9.42383C19 4.21919 14.7467 0 9.50001 0ZM9.50001 5.45806C11.7076 5.45806 13.4978 7.23392 13.4978 9.42381C13.4978 11.6138 11.7076 13.3896 9.50001 13.3896C7.29239 13.3896 5.5022 11.6138 5.5022 9.42383C5.5022 7.23392 7.29239 5.45806 9.50001 5.45806Z"
                                                    fill="white"
                                                ></path>
                                            </svg>{" "}
                                            г. Димитровград
                                            ул. Юнг
                                            Северного Флота
                                            8
                                        </a>
                                        <br />
                                        <button
                                            className="description__more final__button"
                                            data-animate-fill="true"
                                            style={{
                                                "--x": "125.65625px",
                                                "--y": "6px",
                                            }}
                                        >
                                            <span>
                                                Отправить
                                                заявку
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="otvetFos">
                            <span className="modal__message modal__message_success">
                                Спасибо, Ваша заявка успешно
                                отправлена!
                            </span>
                            <span className="modal__message modal__message_error">
                                Произошла ошибка!
                                Пожалуйста, попробуйте
                                позже.
                            </span>
                            <Spinner />
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
