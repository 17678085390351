import { useEffect } from "react"


export default function Meta({title, keywords, description}) {
    useEffect(() => {
      document.title = title;
      document.querySelector('meta[name="keywords"]').content = keywords;
      document.querySelector('meta[name="description"]').content= description;
    }, [title, keywords, description])
    return <></>;
}