export default function Description(params) {
    return (
        <section className="description">
            <div className="description__wrapper">
                {window.innerWidth > 991 && (
                    <nav
                        className="breadcrumbs"
                        aria-label="Хлебные крошки"
                    >
                        <ol
                            className="breadcrumbs__list"
                            itemScope
                            itemType="https://schema.org/BreadcrumbList"
                        >
                            <li
                                className="breadcrumbs__item"
                                itemProp="itemListElement"
                                itemScope
                                itemType="https://schema.org/ListItem"
                            >
                                <a
                                    href="/"
                                    className="breadcrumbs__link"
                                    itemProp="item"
                                >
                                    <span itemProp="name">
                                        Главная
                                    </span>
                                    <meta
                                        itemProp="position"
                                        content="1"
                                    />
                                </a>
                            </li>
                            <li
                                className="breadcrumbs__item"
                                itemProp="itemListElement"
                                itemScope
                                itemType="https://schema.org/ListItem"
                            >
                                <p
                                    className="breadcrumbs__current breadcrumbs__current_white"
                                    aria-current="page"
                                    itemProp="item"
                                >
                                    <span itemProp="name">
                                        {params.name}
                                    </span>
                                </p>
                                <meta
                                    itemProp="position"
                                    content="2"
                                />
                                <p></p>
                            </li>
                        </ol>
                    </nav>
                )}
                <h1 className="description__title section-title">
                    {params.name}
                </h1>
                <div className="description__info">
                    <p
                        style={{ whiteSpace: "pre-line" }}
                        className="description__info-text"
                    >
                        {params.text}
                    </p>
                </div>
                <button
                    className="description__more final__button"
                    data-animate-fill
                    data-fos
                >
                    <span>Узнать подробнее</span>
                </button>
                {params.gall}
            </div>
            <picture className="im">{params.fease}</picture>
            {window.innerWidth < 991 && (
                    <nav
                        className="breadcrumbs"
                        aria-label="Хлебные крошки"
                    >
                        <ol
                            className="breadcrumbs__list"
                            itemScope
                            itemType="https://schema.org/BreadcrumbList"
                        >
                            <li
                                className="breadcrumbs__item"
                                itemProp="itemListElement"
                                itemScope
                                itemType="https://schema.org/ListItem"
                            >
                                <a
                                    href="/"
                                    className="breadcrumbs__link"
                                    itemProp="item"
                                >
                                    <span itemProp="name">
                                        Главная
                                    </span>
                                    <meta
                                        itemProp="position"
                                        content="1"
                                    />
                                </a>
                            </li>
                            <li
                                className="breadcrumbs__item"
                                itemProp="itemListElement"
                                itemScope
                                itemType="https://schema.org/ListItem"
                            >
                                <p
                                    className="breadcrumbs__current breadcrumbs__current_white"
                                    aria-current="page"
                                    itemProp="item"
                                >
                                    <span itemProp="name">
                                        {params.name}
                                    </span>
                                </p>
                                <meta
                                    itemProp="position"
                                    content="2"
                                />
                                <p></p>
                            </li>
                        </ol>
                    </nav>
                )}
        </section>
    );
}
