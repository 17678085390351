import ActionBlock from "../bloc/actionBlock";
import "../css/actions.css";
export default function Actions(params) {
    
    return (
        <main className="main">
            <nav
                className="breadcrumbs"
                aria-label="Хлебные крошки"
            >
                <ol
                    className="breadcrumbs__list"
                    itemScope
                    itemType="https://schema.org/BreadcrumbList"
                >
                    <li
                        className="breadcrumbs__item"
                        itemProp="itemListElement"
                        itemScope
                        itemType="https://schema.org/ListItem"
                    >
                        <a
                            href="/"
                            className="breadcrumbs__link"
                            itemProp="item"
                        >
                            <span itemProp="name">
                                Главная
                            </span>
                            <meta
                                itemProp="position"
                                content="1"
                            />
                        </a>
                    </li>
                    <li
                        className="breadcrumbs__item"
                        itemProp="itemListElement"
                        itemScope
                        itemType="https://schema.org/ListItem"
                    >
                        <p
                            className="breadcrumbs__current"
                            aria-current="page"
                            itemProp="item"
                        >
                            <span itemProp="name">
                                Акции
                            </span>
                        </p>
                        <meta
                            itemProp="position"
                            content="2"
                        />
                        <p></p>
                    </li>
                </ol>
            </nav>

           <ActionBlock/>
        </main>
    );
}
