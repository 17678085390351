export default function ActionBlock(params) {
    return (
        <>
            <section className="portfolio section-first">
                <div className="reviews__flex">
                    <h1 className="reviews__title section-title">
                        Акции
                    </h1>
                </div>
                <p className="actions__descr">
                    Мы любим яркие эмоции и незабываемые
                    впечатления! И хотим делиться с вами! А
                    больше ярких эмоций только с нашими
                    скидками и приятными бонусами!
                </p>
            </section>
            <section className="banner section">
                <h2 className="sr-only">Акции</h2>
                <picture>
                    <source
                        srcSet="imgwebp/blocks/banner/wedding-575.webp"
                        media="(max-width: 575px)"
                        type="image/webp"
                    />
                    <source
                        srcSet="imgwebp/blocks/banner/wedding-767.webp"
                        media="(max-width: 767px)"
                        type="image/webp"
                    />
                    <source
                        srcSet="imgwebp/blocks/banner/wedding-1199.webp"
                        media="(max-width: 1199px)"
                        type="image/webp"
                    />
                    <source
                        srcSet="imgwebp/blocks/banner/wedding-1439.webp"
                        media="(max-width: 1439px)"
                        type="image/webp"
                    />
                    <source
                        srcSet="imgwebp/blocks/banner/wedding.webp"
                        media="(min-width: 1440px)"
                        type="image/webp"
                    />
                    <img
                        src="img/blocks/banner/wedding.jpg"
                        alt="Скидка 25% на салюты для вашей свадьбы!"
                        className="banner__img"
                    />
                </picture>
            </section>
        </>
    );
}
