import { useState } from "react";
import "../css/reviews .css";
import axios from "axios";
export default function Reviews(params) {
    const [rev, setrev] = useState(false)


    function reviewsForm(event) {
        event.preventDefault();
        console.log(event);
        let obj = {
            userSurname: event.target.userSurname.value,
            userName: event.target.userName.value,
            theme: event.target.theme.value,
            review: event.target.review.value,
        };
        axios
            .post(
                "/date/mailReviews.php",
                JSON.stringify(obj)
            )
            .then((response) => {
                console.log(response.data);
                if (response.data) {
                    document.querySelector(
                        "#reviewsForm"
                    ).style.display = "none";
                    document.querySelector(".modal__message_success").style.display = "block";
                  
                }
            })
            .catch((error) => {
                console.log(error);
                document.querySelector(".modal__message_success").style.display = "block";

            });
    }
    return (
        <main className="main">
            <nav
                className="breadcrumbs"
                aria-label="Хлебные крошки"
            >
                <ol
                    className="breadcrumbs__list"
                    itemScope
                    itemType="https://schema.org/BreadcrumbList"
                >
                    <li
                        className="breadcrumbs__item"
                        itemProp="itemListElement"
                        itemScope
                        itemType="https://schema.org/ListItem"
                    >
                        <a
                            href="/"
                            className="breadcrumbs__link"
                            itemProp="item"
                        >
                            <span itemProp="name">
                                Главная
                            </span>
                            <meta
                                itemProp="position"
                                content="1"
                            />
                        </a>
                    </li>
                    <li
                        className="breadcrumbs__item"
                        itemProp="itemListElement"
                        itemScope
                        itemType="https://schema.org/ListItem"
                    >
                        <p
                            className="breadcrumbs__current"
                            aria-current="page"
                            itemProp="item"
                        >
                            <span itemProp="name">
                                Отзывы
                            </span>
                        </p>
                        <meta
                            itemProp="position"
                            content="2"
                        />
                        <p></p>
                    </li>
                </ol>
            </nav>

            <section className="portfolio section-first">
                <div className="reviews__flex">
                    <h1 className="reviews__title section-title">
                        Отзывы
                    </h1>
                    <button onClick={()=>setrev(!rev)} className="reviews__button">
                        Оставить отзыв
                    </button>
                </div>
                <ul className="reviews__list">
                    <li
                        className="reviews__item"
                        itemScope
                        itemType="https://schema.org/Review"
                    >
                        <div className="reviews__author">
                            <span
                                className="reviews__name"
                                itemProp="author"
                            >
                                Максим К.
                            </span>{" "}
                            <time
                                className="reviews__date"
                                dateTime="2021-10-02"
                                itemProp="datePublished"
                                content="2022-09-17"
                            >
                                17.09.2022 г.
                            </time>
                        </div>
                        <div className="reviews__content">
                            <h2
                                className="reviews__subtitle"
                                itemProp="name"
                            >
                                Детям понравились фейерверки
                            </h2>
                            <div
                                className="reviews__descr"
                                itemProp="reviewBody"
                            >
                                <p>
                                    Заказ 17 сентября. Хотел
                                    бы поблагодарить за
                                    отличную работу.
                                    Доверился в подборе
                                    фейерверка сыну на др,
                                    посоветовали очень
                                    красивый за вменяемые
                                    деньги. Привезли, как
                                    просил максимально
                                    поздно, оплатил картой
                                    при получении, позже
                                    прислали чек. Всё чётко,
                                    скорее всего буду
                                    обращаться снова.
                                </p>
                            </div>
                        </div>
                    </li>
                    <li
                        className="reviews__item"
                        itemScope
                        itemType="https://schema.org/Review"
                    >
                        <div className="reviews__author">
                            <span
                                className="reviews__name"
                                itemProp="author"
                            >
                                Алина
                            </span>{" "}
                            <time
                                className="reviews__date"
                                dateTime="2021-10-02"
                                itemProp="datePublished"
                                content="2022-08-19"
                            >
                                19.08.2022 г.
                            </time>
                        </div>
                        <div className="reviews__content">
                            <h2
                                className="reviews__subtitle"
                                itemProp="name"
                            >
                                Корпоратив получился
                                замечательным. Спасибо
                            </h2>
                            <div
                                className="reviews__descr"
                                itemProp="reviewBody"
                            >
                                <p>
                                    Подобрали классный салют
                                    на свадьбу и сделали
                                    скидку, привезли очень
                                    быстро прямо в ресторан!
                                    Ребята вы молодцы,
                                    отлично работаете)
                                </p>
                            </div>
                        </div>
                    </li>
                    <li
                        className="reviews__item"
                        itemScope
                        itemType="https://schema.org/Review"
                    >
                        <div className="reviews__author">
                            <span
                                className="reviews__name"
                                itemProp="author"
                            >
                                Елена
                            </span>{" "}
                            <time
                                className="reviews__date"
                                dateTime="2021-10-02"
                                itemProp="datePublished"
                                content="2022-06-22"
                            >
                                22.06.2022 г.
                            </time>
                        </div>
                        <div className="reviews__content">
                            <h2
                                className="reviews__subtitle"
                                itemProp="name"
                            >
                                Большой ассортимент, быстро
                                работают
                            </h2>
                            <div
                                className="reviews__descr"
                                itemProp="reviewBody"
                            >
                                <p>
                                    Быстрая доставка, даже
                                    за город! И было очень
                                    приятно, когда
                                    оперативно решили нашу
                                    путаницу с цветным дымом
                                    - вечером была доставка,
                                    на следующий день утром
                                    привезли замену.
                                    Подробная консультация
                                    по товару и как его
                                    использовать. Очень
                                    здорово что есть такие
                                    магазины!
                                </p>
                            </div>
                        </div>
                    </li>
                    <li
                        className="reviews__item"
                        itemScope
                        itemType="https://schema.org/Review"
                    >
                        <div className="reviews__author">
                            <span
                                className="reviews__name"
                                itemProp="author"
                            >
                                Александр
                            </span>{" "}
                            <time
                                className="reviews__date"
                                dateTime="2021-10-02"
                                itemProp="datePublished"
                                content="2022-05-11"
                            >
                                11.05.2022 г.
                            </time>
                        </div>
                        <div className="reviews__content">
                            <h2
                                className="reviews__subtitle"
                                itemProp="name"
                            >
                                Благодарю за оперативность и
                                качество
                            </h2>
                            <div
                                className="reviews__descr"
                                itemProp="reviewBody"
                            >
                                <p>
                                    Покупаю не первый раз
                                    салют в данном магазине.
                                    Все отлично, цены
                                    адекватные
                                </p>
                            </div>
                        </div>
                    </li>
                    <li
                        className="reviews__item"
                        itemScope
                        itemType="https://schema.org/Review"
                    >
                        <div className="reviews__author">
                            <span
                                className="reviews__name"
                                itemProp="author"
                            >
                                Алексей Р.
                            </span>{" "}
                            <time
                                className="reviews__date"
                                dateTime="2021-10-02"
                                itemProp="datePublished"
                                content="2021-12-28"
                            >
                                28.12.2021 г.
                            </time>
                        </div>
                        <div className="reviews__content">
                            <h2
                                className="reviews__subtitle"
                                itemProp="name"
                            >
                                Все прошло круто!!
                            </h2>
                            <div
                                className="reviews__descr"
                                itemProp="reviewBody"
                            >
                                <p>
                                    Все что было на сайте,
                                    было в наличии, привезли
                                    все четко, вовремя, как
                                    и обещали, несмотря на
                                    9-тибальные
                                    предновогодние пробки.
                                    Спасибо, удачи!
                                </p>
                            </div>
                        </div>
                    </li>
                    <li
                        className="reviews__item"
                        itemScope
                        itemType="https://schema.org/Review"
                    >
                        <div className="reviews__author">
                            <span
                                className="reviews__name"
                                itemProp="author"
                            >
                                Наталия
                            </span>{" "}
                            <time
                                className="reviews__date"
                                dateTime="2021-10-02"
                                itemProp="datePublished"
                                content="2021-11-17"
                            >
                                17.11.2021 г.
                            </time>
                        </div>
                        <div className="reviews__content">
                            <h2
                                className="reviews__subtitle"
                                itemProp="name"
                            >
                                спасибо за мероприятие
                            </h2>
                            <div
                                className="reviews__descr"
                                itemProp="reviewBody"
                            >
                                <p>
                                    Спасибо Вашей компании
                                    за всё! За консультации,
                                    подробный сайт, быструю
                                    доставку, отличное
                                    качество! С Вами я
                                    начала любить
                                    Фейерверки)))
                                    процветания Вам!
                                </p>
                            </div>
                        </div>
                    </li>
                    <li
                        className="reviews__item"
                        itemScope
                        itemType="https://schema.org/Review"
                    >
                        <div className="reviews__author">
                            <span
                                className="reviews__name"
                                itemProp="author"
                            >
                                Инна
                            </span>{" "}
                            <time
                                className="reviews__date"
                                dateTime="2021-10-02"
                                itemProp="datePublished"
                                content="2021-11-05"
                            >
                                05.11.2021 г.
                            </time>
                        </div>
                        <div className="reviews__content">
                            <h2
                                className="reviews__subtitle"
                                itemProp="name"
                            >
                                Отличные салюты
                            </h2>
                            <div
                                className="reviews__descr"
                                itemProp="reviewBody"
                            >
                                <p>
                                    Покупаю не первый раз
                                    салют в данном магазине.
                                    Все отлично, цены
                                    адекватные, доставка
                                    день в день. Спасибо
                                    магазину.
                                </p>
                            </div>
                        </div>
                    </li>
                    <li
                        className="reviews__item"
                        itemScope
                        itemType="https://schema.org/Review"
                    >
                        <div className="reviews__author">
                            <span
                                className="reviews__name"
                                itemProp="author"
                            >
                                Инна
                            </span>{" "}
                            <time
                                className="reviews__date"
                                dateTime="2021-10-02"
                                itemProp="datePublished"
                                content="2021-10-19"
                            >
                                19.10.2021 г.
                            </time>
                        </div>
                        <div className="reviews__content">
                            <h2
                                className="reviews__subtitle"
                                itemProp="name"
                            >
                                Спасибо
                            </h2>
                            <div
                                className="reviews__descr"
                                itemProp="reviewBody"
                            >
                                <p>
                                    заказала через сайт, тут
                                    же отзвонились, и
                                    привезли через полтора
                                    часа на работу-очень
                                    быстро, четко,
                                    приветливо, курьер очень
                                    милый) спасибо
                                </p>
                            </div>
                        </div>
                    </li>
                    <li
                        className="reviews__item"
                        itemScope
                        itemType="https://schema.org/Review"
                    >
                        <div className="reviews__author">
                            <span
                                className="reviews__name"
                                itemProp="author"
                            >
                                Александра Г.
                            </span>
                            <time
                                className="reviews__date"
                                dateTime="2021-10-02"
                                itemProp="datePublished"
                                content="2021-10-02"
                            >
                                02.10.2021 г.
                            </time>
                        </div>
                        <div className="reviews__content">
                            <h2
                                className="reviews__subtitle"
                                itemProp="name"
                            >
                                Благодарность от всего
                                коллектива!
                            </h2>
                            <div
                                className="reviews__descr"
                                itemProp="reviewBody"
                            >
                                <p>
                                    Долго искала подходящий
                                    салют для корпоратива, а
                                    все потому что на мне
                                    лежала большая
                                    ответственность. У нас в
                                    офисе вроде и юморные
                                    все, дружим хорошо,
                                    часто ходим куда-то, но
                                    все равно переживала за
                                    заказ, начиная от
                                    дизайна, заканчивая
                                    непосредственно
                                    качеством. Буду теперь
                                    знать, а фейерверк
                                    отметили все, очень
                                    удачно на вас
                                    наткнулась,
                                    благодарность от всего
                                    коллектива!
                                </p>
                            </div>
                        </div>
                    </li>
                    <li
                        className="reviews__item"
                        itemScope
                        itemType="https://schema.org/Review"
                    >
                        <div className="reviews__author">
                            <span
                                className="reviews__name"
                                itemProp="author"
                            >
                                Михаил В.
                            </span>{" "}
                            <time
                                className="reviews__date"
                                dateTime="2021-09-20"
                                itemProp="datePublished"
                                content="2021-09-20"
                            >
                                20.09.2021 г.
                            </time>
                        </div>
                        <div className="reviews__content">
                            <h2
                                className="reviews__subtitle"
                                itemProp="name"
                            >
                                Комфортные цены, очень
                                хороший выбор салютов
                            </h2>
                            <div
                                className="reviews__descr"
                                itemProp="reviewBody"
                            >
                                <p>
                                    Комфортные цены, очень
                                    хороший выбор салютов
                                    марки "Русский
                                    фейерверк". Комфортные
                                    условия доставки по
                                    Москве, либо бесплатно,
                                    либо очень недорого.
                                    Молодцы, что сказать!
                                    Заказывал уже 5 раз,
                                    накопилась скидочная
                                    карта на 7%, плюс
                                    кэшбэк, весомо
                                    получается в конце.
                                </p>
                            </div>
                        </div>
                    </li>
                    <li
                        className="reviews__item"
                        itemScope
                        itemType="https://schema.org/Review"
                    >
                        <div className="reviews__author">
                            <span
                                className="reviews__name"
                                itemProp="author"
                            >
                                Валерий Н.
                            </span>{" "}
                            <time
                                className="reviews__date"
                                dateTime="2021-09-15"
                                itemProp="datePublished"
                                content="2021-09-15"
                            >
                                15.09.2021 г.
                            </time>
                        </div>
                        <div className="reviews__content">
                            <h2
                                className="reviews__subtitle"
                                itemProp="name"
                            >
                                Стреляли супер салютами на
                                выходных
                            </h2>
                            <div
                                className="reviews__descr"
                                itemProp="reviewBody"
                            >
                                <p>
                                    Покупали на прошлой
                                    неделе пару
                                    супер-салютов за 6-7 тыс
                                    ( 27 мая). Запускали на
                                    выходных, прекрасно
                                    отстреляли! единственно
                                    привезли под вечер
                                    курьер, хотя просили в
                                    дневное время доставить.
                                    Не успел говорит до нас
                                    вовремя доехать) ну ок
                                </p>
                            </div>
                        </div>
                    </li>
                    <li
                        className="reviews__item"
                        itemScope
                        itemType="https://schema.org/Review"
                    >
                        <div className="reviews__author">
                            <span
                                className="reviews__name"
                                itemProp="author"
                            >
                                Мария Н.
                            </span>{" "}
                            <time
                                className="reviews__date"
                                dateTime="2021-09-10"
                                itemProp="datePublished"
                                content="2021-09-10"
                            >
                                10.09.2021 г.
                            </time>
                        </div>
                        <div className="reviews__content">
                            <h2
                                className="reviews__subtitle"
                                itemProp="name"
                            >
                                Купили несколько салютов
                            </h2>
                            <div
                                className="reviews__descr"
                                itemProp="reviewBody"
                            >
                                <p>
                                    Купили несколько
                                    салютов. Малибу (цена
                                    793 руб, ярко-зеленая с
                                    дамой в купальнике),
                                    Сказки Пушкина (цена
                                    1150 руб, упаковка с
                                    героями сказок). Сумма
                                    за эти три салюта, как
                                    понимаете не особо
                                    большая вышла, но была
                                    скидка и доставку
                                    сделали бесплатно, благо
                                    я в пределах МКАДа живу.
                                    Показатель – хорошо
                                    хранится, бережная
                                    доставка. Остался
                                    доволен, к Новому Году
                                    еще закажу.
                                </p>
                            </div>
                        </div>
                    </li>
                </ul>
            </section>
            <div onClick={()=>setrev(!rev)} className={rev ? "modal review__modal modal_active":"modal review__modal"} >
                <div onClick={e=>e.stopPropagation()} className="modal__container">
                    <button
                        className="modal__close"
                        aria-label="Закрыть модальное окно"
                        onClick={()=>setrev(!rev)}
                    >
                        &times;
                    </button>
                    <span className="modal__text">
                        Здесь Вы можете оставить свой отзыв:
                    </span>
                    <form action="#" onSubmit={reviewsForm} id="reviewsForm" className="modal__form ">
                        <fieldset className="modal__user">
                            <input
                                className="modal__form-input modal__user-surname"
                                type="text"
                                name="userSurname"
                                minLength="2"
                                maxLength="50"
                                placeholder="Фамилия"
                                required
                            />{" "}
                            <input
                                className="modal__form-input modal__user-name"
                                type="text"
                                name="userName"
                                minLength="2"
                                maxLength="50"
                                placeholder="Имя"
                                required
                            />
                        </fieldset>
                        <input
                            className="modal__form-input modal__theme"
                            type="text"
                            name="theme"
                            minLength="2"
                            maxLength="50"
                            placeholder="Тема отзыва"
                            required
                        />{" "}
                        <textarea
                            className="modal__form-input modal__textarea"
                            name="review"
                            minLength="2"
                            maxLength="1000"
                            placeholder="Текст отзыва"
                            required
                        ></textarea>
                        {/* <input
                            type="hidden"
                            name="recaptcha_response"
                            value=""
                            className="form-token"
                        />{" "} */}
                        <button
                            className="modal__form-submit"
                            type="submit"
                        >
                            Отправить
                        </button>
                    </form>
                    <span className="modal__message modal__message_success">
                        Спасибо, Ваш отзыв успешно
                        отправлен!
                    </span>
                    <span className="modal__message modal__message_error">
                        Произошла ошибка! Пожалуйста,
                        попробуйте позже.
                    </span>
                </div>
            </div>
        </main>
    );
}
