import { useEffect } from "react"
import { AddLibrary } from "../pages/contacts";


export default function  Footer(params) {
    useEffect(() => {
        setTimeout(() => {
            AddLibrary("js/fslightbox.js");
        }, 500);
    }, [document.querySelectorAll("[data-fslightbox]")])
    

    return (
        <footer className="footer">

        </footer>
    )
}