import { useEffect, useState } from "react";
import Header from "../bloc/header";
import "../css/articles.css";

export default function Articles(params) {
    const [razdel, setrazdel] = useState(false);
    const [punkt, setpunkt] = useState(0);
    function Prev() {
        setpunkt(punkt - 1);
        window.scrollTo(0, 0);
    }

    function Next() {
        setpunkt(punkt + 1);
        window.scrollTo(0, 0);
    }
    function PolezIts(e) {
        setpunkt(e);
        window.scrollTo(0, 0);
    }
    return (
        <>
            <Header
                consul={true}
                setrazdel={setrazdel}
                razdel={razdel}
            />
            <main className="main">
                <section className="articles">
                    <div className="articles__container">
                        <nav
                            className="breadcrumbs"
                            aria-label="Хлебные крошки"
                        >
                            <ol
                                className="breadcrumbs__list"
                                itemScope
                                itemType="https://schema.org/BreadcrumbList"
                            >
                                <li
                                    className="breadcrumbs__item"
                                    itemProp="itemListElement"
                                    itemScope
                                    itemType="https://schema.org/ListItem"
                                >
                                    <a
                                        href="/"
                                        className="breadcrumbs__link"
                                        itemProp="item"
                                    >
                                        <span itemProp="name">
                                            Главная
                                        </span>
                                        <meta
                                            itemProp="position"
                                            content="1"
                                        />
                                    </a>
                                </li>
                                <li
                                    className="breadcrumbs__item"
                                    itemProp="itemListElement"
                                    itemScope
                                    itemType="https://schema.org/ListItem"
                                >
                                    <p
                                        className="breadcrumbs__current breadcrumbs__current_white"
                                        aria-current="page"
                                        itemProp="item"
                                        style={{color:"#11255f"}}
                                    >
                                        <span itemProp="name">
                                            Полезно знать
                                        </span>
                                    </p>
                                    <meta
                                        itemProp="position"
                                        content="2"
                                    />
                                    <p></p>
                                </li>
                            </ol>
                        </nav>
                        <h1 className="articles__title">
                            Полезно знать
                        </h1>
                        <div className="preload">
                            <img
                                src="imgwebp/pages/articles/bg/kto-takoi-pirotechnik.webp"
                                alt="Кто такой пиротехник?"
                            />{" "}
                            <img
                                src="imgwebp/pages/articles/bg/a-mi-smozhem.webp"
                                alt="А мы сможем запустить фонтаны сами?"
                            />{" "}
                            <img
                                src="imgwebp/pages/articles/bg/s-feerwerkami-na-ti.webp"
                                alt="С фейерверками на “ТЫ”"
                            />{" "}
                            <img
                                src="imgwebp/pages/articles/bg/esli-zapretili.webp"
                                alt="Если запретили фейерферк на территории банкетного зала"
                            />{" "}
                            <img
                                src="imgwebp/pages/articles/bg/chto-nuzhno-znat-zaranee.webp"
                                alt="Бенгальские огни на свадьбе"
                            />
                            <img
                                src="imgwebp/pages/articles/bg/plusi-i-minusi-tsvetnogo-dima.webp"
                                alt="Плюсы и минусы цветного дыма на фотосессии"
                            />
                        </div>
                        <ul className="articles__list">
                            <li className="articles__item">
                                <button
                                    onClick={() =>
                                        PolezIts(0)
                                    }
                                    className={
                                        punkt === 0
                                            ? "articles__item-btn articles__item-btn_active"
                                            : "articles__item-btn"
                                    }
                                >
                                    <span className="articles__item-btn-text">
                                        Кто такой
                                        пиротехник?
                                    </span>
                                </button>
                                <article
                                    className={punkt === 0 ? "articles__tabs-article article article_991 article_active":"articles__tabs-article article article_991"}
                                    itemScope
                                    itemType="https://schema.org/Article"
                                >
                                    <h2
                                        className="sr-only"
                                        itemProp="name"
                                    >
                                        Кто такой
                                        пиротехник?
                                    </h2>
                                    <time
                                        className="article__time"
                                        dateTime="2022-01-10"
                                    >
                                        10/01/2022
                                    </time>

                                    <div
                                        className="article__flex article__flex_intro"
                                        itemProp="description"
                                    >
                                        <div className="article__info">
                                            <h3 className="article__subtitle">
                                                Это редкая,
                                                но
                                                удивительная
                                                профессия,
                                                которая
                                                сочетает в
                                                себе науку,
                                                технику и
                                                искусство
                                            </h3>
                                            <div className="article__descr">
                                                <p>
                                                    Все мы
                                                    любим
                                                    фейерверки:
                                                    какой же
                                                    праздник
                                                    можно
                                                    представить
                                                    без
                                                    ярких
                                                    огней в
                                                    небе.
                                                    Ведь
                                                    фейерверк
                                                    это
                                                    взрыв:
                                                    взрыв
                                                    эмоций,
                                                    чувств,
                                                    переживаний.
                                                </p>
                                                <p>
                                                    Для нас
                                                    это
                                                    безумно
                                                    интересная,
                                                    зрелищная
                                                    и даже
                                                    романтичная
                                                    профессия.
                                                    Тут есть
                                                    риск,
                                                    красота,
                                                    волшебство,
                                                    химия и
                                                    праздник.
                                                    С 2007
                                                    года мы
                                                    развиваемся
                                                    в этом
                                                    направлении
                                                    и дарим
                                                    людям
                                                    эмоции.
                                                    Это уже
                                                    наш
                                                    стиль
                                                    жизни, в
                                                    котором
                                                    заложен
                                                    творческий
                                                    подход и
                                                    даже
                                                    дизайнерская
                                                    жилка.
                                                </p>
                                            </div>
                                        </div>
                                        <picture itemProp="image">
                                            <source
                                                srcSet="imgwebp/pages/articles/kto-takoi-pirotechnik/eto-redkaya-no-udivitelnaya-professiya.webp"
                                                type="image/webp"
                                            />
                                            <img
                                                src="#"
                                                data-src="img/pages/articles/kto-takoi-pirotechnik/eto-redkaya-no-udivitelnaya-professiya.jpg"
                                                alt="Это редкая, но удивительная профессия, которая сочетает в себе науку, технику и искусство"
                                                className="article__img article__img_intro"
                                            />
                                        </picture>
                                    </div>
                                    <p className="article__warning">
                                        Во время запуска
                                        пиротехнического шоу
                                        или
                                        профессионального
                                        фейерверка у нас под
                                        контролем масса
                                        устройств, мы
                                        отвечаем не только
                                        за красочное шоу, но
                                        и за безопасность
                                        людей.
                                    </p>
                                    <div
                                        className="article__flex"
                                        itemProp="description"
                                    >
                                        <picture itemProp="image">
                                            <source
                                                srcSet="imgwebp/pages/articles/kto-takoi-pirotechnik/kontsentratsiya-i-tochnost.webp"
                                                type="image/webp"
                                            />
                                            <img
                                                src="#"
                                                data-src="img/pages/articles/kto-takoi-pirotechnik/kontsentratsiya-i-tochnost.jpg"
                                                alt="Наша работа требует особой концентрации и точности"
                                                className="article__img"
                                            />
                                        </picture>
                                        <div className="article__info">
                                            <h3 className="article__subtitle">
                                                Наша работа
                                                требует
                                                особой
                                                концентрации
                                                и точности
                                            </h3>
                                            <div className="article__descr">
                                                <p>
                                                    Если
                                                    бытовые
                                                    фейерверки
                                                    может
                                                    запустить
                                                    любой
                                                    взрослый,
                                                    следуя
                                                    нашим
                                                    рекомендациям,
                                                    то с
                                                    более
                                                    мощным
                                                    салютом
                                                    могут
                                                    справиться
                                                    только
                                                    профессиональные
                                                    пиротехники.
                                                </p>
                                                <p>
                                                    Мы
                                                    создаём
                                                    концепцию
                                                    салюта –
                                                    пишем
                                                    специальную
                                                    программу;
                                                    оцениваем
                                                    и
                                                    прорабатываемся
                                                    масштаб
                                                    запуска,
                                                    его
                                                    интенсивность,
                                                    площадь;
                                                    отбираем
                                                    необходимые
                                                    изделия;
                                                    доставляем
                                                    к месту
                                                    проведения
                                                    шоу;
                                                    делаем
                                                    монтаж
                                                    пиротехнических
                                                    элементов
                                                    на месте
                                                    запуска;
                                                    проверяем
                                                    правильность
                                                    подключения
                                                    и
                                                    технику
                                                    безопасности,
                                                    и только
                                                    после
                                                    этого
                                                    запускаем
                                                    мощное
                                                    пиротехническое
                                                    шоу –
                                                    это и
                                                    есть
                                                    чувство
                                                    праздника.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <ul className="article__gallery">
                                        <li className="article__gallery-item">
                                            <a
                                                className="article__gallery-link"
                                                href="imgwebp/pages/articles/kto-takoi-pirotechnik/gallery/full/kto-takoi-pirotechnik-1.webp"
                                                data-fslightbox="kto-takoi-pirotechnik"
                                            >
                                                <picture>
                                                    <source
                                                        srcSet="imgwebp/pages/articles/kto-takoi-pirotechnik/gallery/preview/kto-takoi-pirotechnik-1.webp"
                                                        type="image/webp"
                                                    />
                                                    <img
                                                        src="#"
                                                        data-src="img/pages/articles/kto-takoi-pirotechnik/gallery/preview/kto-takoi-pirotechnik-1.jpg"
                                                        alt="Кто такой пиротехник 1"
                                                        className="article__gallery-img"
                                                    />
                                                </picture>
                                            </a>
                                        </li>
                                        <li className="article__gallery-item">
                                            <a
                                                className="article__gallery-link"
                                                href="imgwebp/pages/articles/kto-takoi-pirotechnik/gallery/full/kto-takoi-pirotechnik-2.webp"
                                                data-fslightbox="kto-takoi-pirotechnik"
                                            >
                                                <picture>
                                                    <source
                                                        srcSet="imgwebp/pages/articles/kto-takoi-pirotechnik/gallery/preview/kto-takoi-pirotechnik-2.webp"
                                                        type="image/webp"
                                                    />
                                                    <img
                                                        src="#"
                                                        data-src="img/pages/articles/kto-takoi-pirotechnik/gallery/preview/kto-takoi-pirotechnik-2.jpg"
                                                        alt="Кто такой пиротехник 2"
                                                        className="article__gallery-img"
                                                    />
                                                </picture>
                                            </a>
                                        </li>
                                        <li className="article__gallery-item">
                                            <a
                                                className="article__gallery-link"
                                                href="imgwebp/pages/articles/kto-takoi-pirotechnik/gallery/full/kto-takoi-pirotechnik-3.webp"
                                                data-fslightbox="kto-takoi-pirotechnik"
                                            >
                                                <picture>
                                                    <source
                                                        srcSet="imgwebp/pages/articles/kto-takoi-pirotechnik/gallery/preview/kto-takoi-pirotechnik-3.webp"
                                                        type="image/webp"
                                                    />
                                                    <img
                                                        src="#"
                                                        data-src="img/pages/articles/kto-takoi-pirotechnik/gallery/preview/kto-takoi-pirotechnik-3.jpg"
                                                        alt="Кто такой пиротехник 3"
                                                        className="article__gallery-img"
                                                    />
                                                </picture>
                                            </a>
                                        </li>
                                        <li className="article__gallery-item">
                                            <a
                                                className="article__gallery-link"
                                                href="imgwebp/pages/articles/kto-takoi-pirotechnik/gallery/full/kto-takoi-pirotechnik-4.webp"
                                                data-fslightbox="kto-takoi-pirotechnik"
                                            >
                                                <picture>
                                                    <source
                                                        srcSet="imgwebp/pages/articles/kto-takoi-pirotechnik/gallery/preview/kto-takoi-pirotechnik-4.webp"
                                                        type="image/webp"
                                                    />
                                                    <img
                                                        src="#"
                                                        data-src="img/pages/articles/kto-takoi-pirotechnik/gallery/preview/kto-takoi-pirotechnik-4.jpg"
                                                        alt="Кто такой пиротехник 4"
                                                        className="article__gallery-img"
                                                    />
                                                </picture>
                                            </a>
                                        </li>
                                    </ul>
                                    <div
                                        className="article__flex"
                                        itemProp="description"
                                    >
                                        <div className="article__info">
                                            <h3 className="article__subtitle">
                                                Мы любим
                                                свою работу,
                                                свой стиль
                                                жизни и
                                                делимся этой
                                                любовью с
                                                вами. А
                                                лучший
                                                подарок для
                                                пиротехника
                                                – ваши
                                                эмоции и
                                                восторг
                                            </h3>
                                        </div>
                                        <picture itemProp="image">
                                            <source
                                                srcSet="imgwebp/pages/articles/kto-takoi-pirotechnik/mi-lyubim-svoyu-rabotu.webp"
                                                type="image/webp"
                                            />
                                            <img
                                                src="#"
                                                data-src="img/pages/articles/kto-takoi-pirotechnik/mi-lyubim-svoyu-rabotu.jpg"
                                                alt="Мы любим свою работу"
                                                className="article__img"
                                            />
                                        </picture>
                                    </div>
                                    <button className="article__close">
                                        Свернуть статью
                                    </button>
                                </article>
                            </li>
                            <li className="articles__item">
                                <button
                                    onClick={() =>
                                        PolezIts(1)
                                    }
                                    className={
                                        punkt === 1
                                            ? "articles__item-btn articles__item-btn_active"
                                            : "articles__item-btn"
                                    }
                                >
                                    <span className="articles__item-btn-text">
                                        А мы сможем
                                        запустить фонтаны
                                        сами?
                                    </span>
                                </button>
                                <article  className={punkt ===1 ? "articles__tabs-article article article_991 article_active":"articles__tabs-article article article_991"}>
                                    <h2 className="sr-only">
                                        А мы сможем
                                        запустить фонтаны
                                        сами?
                                    </h2>
                                    <time
                                        className="article__time"
                                        dateTime="2022-01-16"
                                    >
                                        16/01/2022
                                    </time>
                                    <div className="article__flex article__flex_intro">
                                        <div className="article__info">
                                            <h3 className="article__subtitle">
                                                Вопрос,
                                                который
                                                возникает у
                                                заказчика
                                                достаточно
                                                часто
                                            </h3>
                                            <div className="article__descr">
                                                <p>
                                                    Отвечаем:
                                                    нет.
                                                    Наши
                                                    фонтаны
                                                    запускаются
                                                    со
                                                    специальных
                                                    пусковых
                                                    установок,
                                                    с
                                                    применением
                                                    пульта.
                                                    Это
                                                    позволяет
                                                    зажигать
                                                    все
                                                    фонтаны
                                                    одновременно
                                                    или же
                                                    по
                                                    очереди,
                                                    по
                                                    вашему
                                                    желанию.
                                                </p>
                                                <p>
                                                    Одно
                                                    нажатие
                                                    на
                                                    кнопку,
                                                    и вы
                                                    окружены
                                                    великолепием
                                                    светящихся
                                                    искр.
                                                </p>
                                            </div>
                                        </div>
                                        <picture>
                                            <source
                                                srcSet="imgwebp/pages/articles/a-mi-smozhem/vopros.webp"
                                                type="image/webp"
                                            />
                                            <img
                                                src="#"
                                                data-src="img/pages/articles/a-mi-smozhem/vopros.jpg"
                                                alt="Вопрос, который возникает у заказчика достаточно часто"
                                                className="article__img article__img_intro"
                                            />
                                        </picture>
                                    </div>
                                    <p className="article__warning">
                                        Команда «Салют
                                        Ульяновск» сделает
                                        ваш свадебный вечер
                                        ярким и максимально
                                        комфортным для вас,
                                        взяв на себя все
                                        заботы по
                                        организации и
                                        запуску.
                                    </p>
                                    <div className="article__flex">
                                        <picture>
                                            <source
                                                srcSet="imgwebp/pages/articles/a-mi-smozhem/pirotechnik.webp"
                                                type="image/webp"
                                            />
                                            <img
                                                src="#"
                                                data-src="img/pages/articles/a-mi-smozhem/pirotechnik.jpg"
                                                alt="Наш пиротехник"
                                                className="article__img"
                                            />
                                        </picture>
                                        <div className="article__info">
                                            <div className="article__descr">
                                                <p>
                                                    Наш
                                                    пиротехник,
                                                    учитывая
                                                    все ваши
                                                    пожелания,
                                                    которые
                                                    обговариваются
                                                    заранее,
                                                    приедет
                                                    на ваше
                                                    мероприятие
                                                    в
                                                    обговорённое
                                                    время и
                                                    установит
                                                    холодные
                                                    фонтаны
                                                    на
                                                    подходящее
                                                    место.
                                                    Вам не
                                                    придётся
                                                    беспокоиться
                                                    об
                                                    организации
                                                    и
                                                    запуске,
                                                    просто
                                                    наслаждайтесь
                                                    моментом.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <button className="article__close">
                                        Свернуть статью
                                    </button>
                                </article>
                            </li>
                            <li className="articles__item">
                                <button
                                    onClick={() =>
                                        PolezIts(2)
                                    }
                                    className={
                                        punkt === 2
                                            ? "articles__item-btn articles__item-btn_active"
                                            : "articles__item-btn"
                                    }
                                >
                                    <span className="articles__item-btn-text">
                                        С фейерверками на
                                        “ТЫ”
                                    </span>
                                </button>
                                <article  className={punkt === 2 ? "articles__tabs-article article article_991 article_active":"articles__tabs-article article article_991"}>
                                    <h2 className="sr-only">
                                        С фейерверками на
                                        “ТЫ”
                                    </h2>
                                    <time
                                        className="article__time"
                                        dateTime="2022-01-23"
                                    >
                                        23/01/2022
                                    </time>
                                    <div className="article__flex article__flex_intro">
                                        <div className="article__info">
                                            <h3 className="article__subtitle">
                                                Какой салют
                                                лучше
                                                выбрать?
                                            </h3>
                                            <div className="article__descr">
                                                <p>
                                                    Нас
                                                    часто
                                                    спрашивают
                                                    какой
                                                    салют
                                                    лучше
                                                    выбрать
                                                    и просят
                                                    показать
                                                    видеозаписи
                                                    для
                                                    наглядности.
                                                    Мы с
                                                    удовольствием
                                                    делимся
                                                    подборками,
                                                    но видео
                                                    не
                                                    отражает
                                                    всех
                                                    эмоций,
                                                    мощности
                                                    зарядов
                                                    и
                                                    эффектов,
                                                    как
                                                    вживую.
                                                </p>
                                                <p>
                                                    Поэтому
                                                    мы
                                                    всегда
                                                    просим
                                                    довериться
                                                    нам в
                                                    выборе
                                                    красивого
                                                    фейерверка
                                                    для вас.
                                                    Достаточно
                                                    сказать
                                                    бюджет,
                                                    в
                                                    которой
                                                    нужно
                                                    уложиться,
                                                    место
                                                    проведения
                                                    и ваши
                                                    пожелания
                                                    к
                                                    фейерверку.
                                                </p>
                                            </div>
                                        </div>
                                        <picture itemProp="image">
                                            <source
                                                srcSet="imgwebp/pages/articles/s-feerwerkami-na-ti/kakoi-salut.webp"
                                                type="image/webp"
                                            />
                                            <img
                                                src="#"
                                                data-src="img/pages/articles/s-feerwerkami-na-ti/kakoi-salut.jpg"
                                                alt="Какой салют лучше выбрать?"
                                                className="article__img article__img_intro"
                                            />
                                        </picture>
                                    </div>
                                    <p className="article__warning">
                                        Просто доверьтесь
                                        нам и наслаждайтесь
                                        красочным
                                        фейерверком!
                                    </p>
                                    <div className="article__flex">
                                        <picture itemProp="image">
                                            <source
                                                srcSet="imgwebp/pages/articles/s-feerwerkami-na-ti/za-15-let.webp"
                                                type="image/webp"
                                            />
                                            <img
                                                src="#"
                                                data-src="img/pages/articles/s-feerwerkami-na-ti/za-15-let.jpg"
                                                alt="За 15 лет мы создали много красоты"
                                                className="article__img"
                                            />
                                        </picture>
                                        <div className="article__info">
                                            <h3 className="article__subtitle">
                                                За 15 лет мы
                                                создали
                                                много
                                                красоты, с
                                                фейерверками
                                                на «ты»
                                            </h3>
                                            <div className="article__descr">
                                                <ul>
                                                    <li>
                                                        Не
                                                        привязываемся
                                                        к
                                                        одной
                                                        коробке
                                                        салютов,
                                                        комбинируем
                                                        несколько
                                                        для
                                                        разнообразия
                                                        и
                                                        насыщенности.
                                                    </li>
                                                    <li>
                                                        Добавляем
                                                        профессиональные
                                                        заряды
                                                        и
                                                        другие
                                                        интересные
                                                        пиротехнические
                                                        изделия
                                                        для
                                                        оригинальности
                                                        и
                                                        «вау-эффекта».
                                                    </li>
                                                    <li>
                                                        Выезжаем
                                                        на
                                                        место
                                                        торжества
                                                        и
                                                        взрываем
                                                        салют
                                                        с
                                                        эффектом
                                                        неожиданности.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <ul className="article__gallery">
                                        <li className="article__gallery-item">
                                            <a
                                                className="article__gallery-link"
                                                href="imgwebp/pages/articles/s-feerwerkami-na-ti/gallery/full/s-feerwerkami-na-ti-1.webp"
                                                data-fslightbox="s-feerwerkami-na-ti"
                                            >
                                                <picture>
                                                    <source
                                                        srcSet="imgwebp/pages/articles/s-feerwerkami-na-ti/gallery/preview/s-feerwerkami-na-ti-1.webp"
                                                        type="image/webp"
                                                    />
                                                    <img
                                                        src="#"
                                                        data-src="img/pages/articles/s-feerwerkami-na-ti/gallery/preview/s-feerwerkami-na-ti-1.jpg"
                                                        alt="С фейерверками на “ТЫ” 1"
                                                        className="article__gallery-img"
                                                    />
                                                </picture>
                                            </a>
                                        </li>
                                        <li className="article__gallery-item">
                                            <a
                                                className="article__gallery-link"
                                                href="imgwebp/pages/articles/s-feerwerkami-na-ti/gallery/full/s-feerwerkami-na-ti-2.webp"
                                                data-fslightbox="s-feerwerkami-na-ti"
                                            >
                                                <picture>
                                                    <source
                                                        srcSet="imgwebp/pages/articles/s-feerwerkami-na-ti/gallery/preview/s-feerwerkami-na-ti-2.webp"
                                                        type="image/webp"
                                                    />
                                                    <img
                                                        src="#"
                                                        data-src="img/pages/articles/s-feerwerkami-na-ti/gallery/preview/s-feerwerkami-na-ti-2.jpg"
                                                        alt="С фейерверками на “ТЫ” 2"
                                                        className="article__gallery-img"
                                                    />
                                                </picture>
                                            </a>
                                        </li>
                                        <li className="article__gallery-item">
                                            <a
                                                className="article__gallery-link"
                                                href="imgwebp/pages/articles/s-feerwerkami-na-ti/gallery/full/s-feerwerkami-na-ti-3.webp"
                                                data-fslightbox="s-feerwerkami-na-ti"
                                            >
                                                <picture>
                                                    <source
                                                        srcSet="imgwebp/pages/articles/s-feerwerkami-na-ti/gallery/preview/s-feerwerkami-na-ti-3.webp"
                                                        type="image/webp"
                                                    />
                                                    <img
                                                        src="#"
                                                        data-src="img/pages/articles/s-feerwerkami-na-ti/gallery/preview/s-feerwerkami-na-ti-3.jpg"
                                                        alt="С фейерверками на “ТЫ” 3"
                                                        className="article__gallery-img"
                                                    />
                                                </picture>
                                            </a>
                                        </li>
                                        <li className="article__gallery-item">
                                            <a
                                                className="article__gallery-link"
                                                href="imgwebp/pages/articles/s-feerwerkami-na-ti/gallery/full/s-feerwerkami-na-ti-4.webp"
                                                data-fslightbox="s-feerwerkami-na-ti"
                                            >
                                                <picture>
                                                    <source
                                                        srcSet="imgwebp/pages/articles/s-feerwerkami-na-ti/gallery/preview/s-feerwerkami-na-ti-4.webp"
                                                        type="image/webp"
                                                    />
                                                    <img
                                                        src="#"
                                                        data-src="img/pages/articles/s-feerwerkami-na-ti/gallery/preview/s-feerwerkami-na-ti-4.jpg"
                                                        alt="С фейерверками на “ТЫ” 4"
                                                        className="article__gallery-img"
                                                    />
                                                </picture>
                                            </a>
                                        </li>
                                    </ul>
                                    <div className="article__flex">
                                        <div className="article__info">
                                            <h3 className="article__subtitle">
                                                Стоимость
                                                фейерверка
                                                зависит от
                                                количества
                                                залпов и
                                                калибра.
                                            </h3>
                                            <div className="article__descr">
                                                <p>
                                                    Чем
                                                    больше
                                                    калибр –
                                                    тем выше
                                                    взлетает,
                                                    мощнее
                                                    взрывается
                                                    (больше
                                                    радиус
                                                    взрыва)
                                                    и дольше
                                                    светиться
                                                    в небе.
                                                    Чем
                                                    больше
                                                    зарядов
                                                    – тем
                                                    дольше и
                                                    насыщеннее.
                                                </p>
                                            </div>
                                        </div>
                                        <picture itemProp="image">
                                            <source
                                                srcSet="imgwebp/pages/articles/s-feerwerkami-na-ti/stoimost.webp"
                                                type="image/webp"
                                            />
                                            <img
                                                src="#"
                                                data-src="img/pages/articles/s-feerwerkami-na-ti/stoimost.jpg"
                                                alt="Стоимость фейерверка зависит от количества залпов и калибра."
                                                className="article__img"
                                            />
                                        </picture>
                                    </div>
                                    <button className="article__close">
                                        Свернуть статью
                                    </button>
                                </article>
                            </li>
                            <li className="articles__item">
                                <button
                                    onClick={() =>
                                        PolezIts(3)
                                    }
                                    className={
                                        punkt === 3
                                            ? "articles__item-btn articles__item-btn_active"
                                            : "articles__item-btn"
                                    }
                                >
                                    <span className="articles__item-btn-text">
                                        Если запретили
                                        фейерферк на
                                        территории
                                        банкетного зала
                                    </span>
                                </button>
                                <article className={punkt === 3 ? "articles__tabs-article article article_991 article_active":"articles__tabs-article article article_991"}>
                                    <h2 className="sr-only">
                                        Если запретили
                                        фейерферк на
                                        территории
                                        банкетного зала
                                    </h2>
                                    <time
                                        className="article__time"
                                        dateTime="2022-02-07"
                                    >
                                        07/02/2022
                                    </time>
                                    <div className="article__flex article__flex_intro">
                                        <div className="article__info">
                                            <h3 className="article__subtitle">
                                                В этом
                                                случае, мы
                                                поможем вам
                                                организовать
                                                эффектное
                                                назменое
                                                пиротехническоу
                                                шоу.
                                            </h3>
                                            <div className="article__descr">
                                                <p>
                                                    Сценическая
                                                    пиротехника,
                                                    которая
                                                    применяется
                                                    в
                                                    наземном
                                                    шоу,
                                                    имеет
                                                    небольшой
                                                    радиус
                                                    опасной
                                                    зоны,
                                                    это
                                                    позволяет
                                                    установить
                                                    её
                                                    практически
                                                    на любой
                                                    площадке
                                                    банкетного
                                                    зала.
                                                </p>
                                                <p>
                                                    Один из
                                                    самых
                                                    популярных
                                                    вариантов
                                                    наземного
                                                    шоу –
                                                    холодные
                                                    фонтаны.
                                                    Только
                                                    представьте
                                                    этот
                                                    прекрасный
                                                    момент,
                                                    где вы –
                                                    в
                                                    главной
                                                    роли и в
                                                    самом
                                                    центре
                                                    россыпи
                                                    сверкающих
                                                    искр.
                                                </p>
                                            </div>
                                        </div>
                                        <picture itemProp="image">
                                            <source
                                                srcSet="imgwebp/pages/articles/esli-zapretili/organizatsiya.webp"
                                                type="image/webp"
                                            />
                                            <img
                                                src="#"
                                                data-src="img/pages/articles/esli-zapretili/organizatsiya.jpg"
                                                alt="Организация"
                                                className="article__img article__img_intro"
                                            />
                                        </picture>
                                    </div>
                                    <p className="article__warning">
                                        Хотите устроить
                                        настоящее шоу со
                                        своим участием? Мы
                                        поможем вам оформить
                                        площадку
                                        действительно
                                        эффектно!
                                    </p>
                                    <div className="article__flex">
                                        <picture itemProp="image">
                                            <source
                                                srcSet="imgwebp/pages/articles/esli-zapretili/ustanovka.webp"
                                                type="image/webp"
                                            />
                                            <img
                                                src="#"
                                                data-src="img/pages/articles/esli-zapretili/ustanovka.jpg"
                                                alt="Установка"
                                                className="article__img"
                                            />
                                        </picture>
                                        <div className="article__info">
                                            <h3 className="article__subtitle">
                                                Приезжаем,
                                                безопасно
                                                устанавливаем
                                                холодные
                                                фонтаны и
                                                управляем
                                                поджигом
                                                фонтанов с
                                                помощью
                                                дистанционных
                                                пультов.
                                            </h3>
                                            <div className="article__descr">
                                                <ul>
                                                    <li>
                                                        Установка
                                                        холодных
                                                        фонтанов
                                                        может
                                                        быть
                                                        разнообразна:
                                                        классическая
                                                        дорожка,
                                                        в
                                                        одну
                                                        линию,
                                                        полукругом,
                                                        квадратом,
                                                        всё
                                                        зависит
                                                        от
                                                        ваших
                                                        желаний.
                                                    </li>
                                                    <li>
                                                        Вам
                                                        не
                                                        нужно
                                                        рисковать
                                                        и
                                                        переживать
                                                        за
                                                        то,
                                                        как
                                                        всё
                                                        пройдёт.
                                                        По
                                                        вашему
                                                        желанию,
                                                        фонтаны
                                                        могут
                                                        зажечься
                                                        все
                                                        сразу
                                                        в
                                                        определённый
                                                        музыкальный
                                                        момент,
                                                        или
                                                        пошагово
                                                        –
                                                        пока
                                                        вы
                                                        проходите
                                                        сквозь
                                                        дорожку.
                                                    </li>
                                                    <li>
                                                        Стандартное
                                                        время
                                                        работы
                                                        холодных
                                                        фонтанов:{" "}
                                                        <strong>
                                                            30-60
                                                            секунд
                                                        </strong>{" "}
                                                        –
                                                        этого
                                                        времени
                                                        достаточно
                                                        для
                                                        съёмки
                                                        отличных
                                                        кадров.
                                                    </li>
                                                    <li>
                                                        Высота
                                                        фонтанов
                                                        –{" "}
                                                        <strong>
                                                            3
                                                            или
                                                            6
                                                            метров
                                                        </strong>
                                                        .
                                                        Цвет
                                                        искр
                                                        –
                                                        серебро
                                                        или
                                                        золото.
                                                    </li>
                                                    <li>
                                                        Фонтаны
                                                        работают{" "}
                                                        <strong>
                                                            в
                                                            любую
                                                            погоду
                                                        </strong>{" "}
                                                        –
                                                        дождь
                                                        не
                                                        страшен.
                                                        А
                                                        ещё,
                                                        как
                                                        они
                                                        горят
                                                        –
                                                        видно{" "}
                                                        <strong>
                                                            в
                                                            любое
                                                            время
                                                            суток
                                                        </strong>
                                                        .
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <ul className="article__gallery">
                                        <li className="article__gallery-item">
                                            <a
                                                className="article__gallery-link"
                                                href="imgwebp/pages/articles/esli-zapretili/gallery/full/esli-zapretili-1.webp"
                                                data-fslightbox="esli-zapretili"
                                            >
                                                <picture>
                                                    <source
                                                        srcSet="imgwebp/pages/articles/esli-zapretili/gallery/preview/esli-zapretili-1.webp"
                                                        type="image/webp"
                                                    />
                                                    <img
                                                        src="#"
                                                        data-src="img/pages/articles/esli-zapretili/gallery/preview/esli-zapretili-1.jpg"
                                                        alt="Если запретили фейерферк на территории банкетного зала 1"
                                                        className="article__gallery-img"
                                                    />
                                                </picture>
                                            </a>
                                        </li>
                                        <li className="article__gallery-item">
                                            <a
                                                className="article__gallery-link"
                                                href="imgwebp/pages/articles/esli-zapretili/gallery/full/esli-zapretili-2.webp"
                                                data-fslightbox="esli-zapretili"
                                            >
                                                <picture>
                                                    <source
                                                        srcSet="imgwebp/pages/articles/esli-zapretili/gallery/preview/esli-zapretili-2.webp"
                                                        type="image/webp"
                                                    />
                                                    <img
                                                        src="#"
                                                        data-src="img/pages/articles/esli-zapretili/gallery/preview/esli-zapretili-2.jpg"
                                                        alt="Если запретили фейерферк на территории банкетного зала 2"
                                                        className="article__gallery-img"
                                                    />
                                                </picture>
                                            </a>
                                        </li>
                                        <li className="article__gallery-item">
                                            <a
                                                className="article__gallery-link"
                                                href="imgwebp/pages/articles/esli-zapretili/gallery/full/esli-zapretili-3.webp"
                                                data-fslightbox="esli-zapretili"
                                            >
                                                <picture>
                                                    <source
                                                        srcSet="imgwebp/pages/articles/esli-zapretili/gallery/preview/esli-zapretili-3.webp"
                                                        type="image/webp"
                                                    />
                                                    <img
                                                        src="#"
                                                        data-src="img/pages/articles/esli-zapretili/gallery/preview/esli-zapretili-3.jpg"
                                                        alt="Если запретили фейерферк на территории банкетного зала 3"
                                                        className="article__gallery-img"
                                                    />
                                                </picture>
                                            </a>
                                        </li>
                                        <li className="article__gallery-item">
                                            <a
                                                className="article__gallery-link"
                                                href="imgwebp/pages/articles/esli-zapretili/gallery/full/esli-zapretili-4.webp"
                                                data-fslightbox="esli-zapretili"
                                            >
                                                <picture>
                                                    <source
                                                        srcSet="imgwebp/pages/articles/esli-zapretili/gallery/preview/esli-zapretili-4.webp"
                                                        type="image/webp"
                                                    />
                                                    <img
                                                        src="#"
                                                        data-src="img/pages/articles/esli-zapretili/gallery/preview/esli-zapretili-4.jpg"
                                                        alt="Если запретили фейерферк на территории банкетного зала 4"
                                                        className="article__gallery-img"
                                                    />
                                                </picture>
                                            </a>
                                        </li>
                                    </ul>
                                    <div className="article__flex">
                                        <div className="article__info">
                                            <h3 className="article__subtitle">
                                                Кроме
                                                холодных
                                                фонтанов,
                                                есть ещё
                                                немало
                                                эффектных
                                                вариантов.
                                            </h3>
                                            <div className="article__descr">
                                                <p>
                                                    Это
                                                    пиротехнические
                                                    вертушки,
                                                    горящие
                                                    буквы/цифры
                                                    и
                                                    огненное
                                                    сердце,
                                                    которое
                                                    молодожёны
                                                    зажигают
                                                    специальным
                                                    факелом.
                                                    Это
                                                    трогательная
                                                    и
                                                    романтичная
                                                    церемония,
                                                    символизирующая
                                                    неугасающий
                                                    огонь
                                                    любви.
                                                </p>
                                            </div>
                                        </div>
                                        <picture itemProp="image">
                                            <source
                                                srcSet="imgwebp/pages/articles/esli-zapretili/raznoobrazie.webp"
                                                type="image/webp"
                                            />
                                            <img
                                                src="#"
                                                data-src="img/pages/articles/esli-zapretili/raznoobrazie.jpg"
                                                alt="Разнообразие"
                                                className="article__img"
                                            />
                                        </picture>
                                    </div>
                                    <button className="article__close">
                                        Свернуть статью
                                    </button>
                                </article>
                            </li>
                            <li className="articles__item">
                                <button
                                    onClick={() =>
                                        PolezIts(4)
                                    }
                                    className={
                                        punkt === 4
                                            ? "articles__item-btn articles__item-btn_active"
                                            : "articles__item-btn"
                                    }
                                >
                                    <span className="articles__item-btn-text">
                                        Бенгальские огни на
                                        свадьбе
                                    </span>
                                </button>
                                <article  className={punkt === 4 ? "articles__tabs-article article article_991 article_active":"articles__tabs-article article article_991"}>
                                    <h2 className="sr-only">
                                        Бенгальские огни на
                                        свадьбе
                                    </h2>
                                    <time
                                        className="article__time"
                                        dateTime="2022-03-10"
                                    >
                                        10/03/2022
                                    </time>
                                    <div className="article__flex article__flex_intro">
                                        <div className="article__info">
                                            <h3 className="article__subtitle">
                                                Что нужно
                                                знать
                                                заранее
                                            </h3>
                                            <div className="article__descr">
                                                <p>
                                                    <strong>
                                                        Локация.
                                                    </strong>{" "}
                                                    При
                                                    бронировании
                                                    банкетного
                                                    зала,
                                                    уточните
                                                    -
                                                    разрешено
                                                    ли у них
                                                    использовать
                                                    бенгальские
                                                    огни.
                                                    Обычно,
                                                    огни
                                                    предлагают
                                                    использовать
                                                    только
                                                    на
                                                    открытом
                                                    воздухе
                                                    или в
                                                    специально
                                                    отведенном
                                                    месте,
                                                    где
                                                    много
                                                    свободного
                                                    пространства.
                                                </p>
                                                <p>
                                                    <strong>
                                                        Подготовка.
                                                    </strong>{" "}
                                                    Заранее
                                                    предупредите
                                                    фотографа
                                                    и
                                                    видеографа
                                                    о том,
                                                    как и
                                                    когда вы
                                                    хотите
                                                    использовать
                                                    бенгальские
                                                    огни.
                                                    Попросите
                                                    свадебного
                                                    координатора
                                                    помочь
                                                    ускорить
                                                    процесс
                                                    зажигания
                                                    бенгальских
                                                    огней.
                                                    Спички,
                                                    конечно,
                                                    работают,
                                                    но могут
                                                    быть
                                                    неудобными
                                                    и
                                                    ненадежными,
                                                    поэтому
                                                    зажигалки
                                                    в форме
                                                    факела
                                                    или с
                                                    длинным
                                                    наконечником
                                                    лучше (в
                                                    идеале,
                                                    используйте
                                                    одну на
                                                    два
                                                    бенгальских
                                                    огня).
                                                    Другой
                                                    вариант
                                                    - иметь
                                                    рядом
                                                    несколько
                                                    стационарных
                                                    свечей-колонн,
                                                    которые
                                                    являются
                                                    эффективным
                                                    способом
                                                    одновременно
                                                    зажечь
                                                    свои
                                                    бенгальские
                                                    огни от
                                                    их
                                                    пламени
                                                    для
                                                    нескольких
                                                    гостей.
                                                </p>
                                            </div>
                                        </div>
                                        <picture>
                                            <source
                                                srcSet="imgwebp/pages/articles/bengalskie-ogni-na-svadbe/chto-nuzhno-znat-zaranee.webp"
                                                type="image/webp"
                                            />
                                            <img
                                                src="#"
                                                data-src="img/pages/articles/bengalskie-ogni-na-svadbe/chto-nuzhno-znat-zaranee.jpg"
                                                alt="Что нужно знать заранее"
                                                className="article__img article__img_intro"
                                            />
                                        </picture>
                                    </div>
                                    <p className="article__warning">
                                        Съемки с
                                        бенгальскими огнями
                                        обычно выглядят
                                        наиболее
                                        впечатляюще, когда
                                        они делаются либо В
                                        СУМЕРКАХ, либо после
                                        заката, когда небо
                                        достаточно темное.
                                    </p>
                                    <div className="article__flex">
                                        <picture>
                                            <source
                                                srcSet="imgwebp/pages/articles/bengalskie-ogni-na-svadbe/bezopansost-tipi-ognei.webp"
                                                type="image/webp"
                                            />
                                            <img
                                                src="#"
                                                data-src="img/pages/articles/bengalskie-ogni-na-svadbe/bezopansost-tipi-ognei.jpg"
                                                alt="Безопасность. Типы огней"
                                                className="article__img"
                                            />
                                        </picture>
                                        <div className="article__info">
                                            <div className="article__descr">
                                                <p>
                                                    <strong>
                                                        Безопасность.
                                                    </strong>{" "}
                                                    Планируя,
                                                    где
                                                    будут
                                                    зажигаться
                                                    ваши
                                                    бенгальские
                                                    огни,
                                                    убедитесь,
                                                    что у
                                                    вас
                                                    достаточно
                                                    места.
                                                    Например,
                                                    для
                                                    живого
                                                    коридора
                                                    запланируйте
                                                    ширину,
                                                    достаточную
                                                    для двух
                                                    шеренг
                                                    людей с
                                                    бенгальскими
                                                    огнями,
                                                    и чтобы
                                                    осталось
                                                    место
                                                    для
                                                    безопасного
                                                    прохода
                                                    молодоженов.
                                                </p>
                                                <p>
                                                    <strong>
                                                        Типы
                                                        огней.
                                                    </strong>{" "}
                                                    Для
                                                    красивого
                                                    огненного
                                                    коридора
                                                    выбирайте
                                                    самые
                                                    длинные
                                                    бенгальски
                                                    огни с
                                                    наименьшей
                                                    степенью
                                                    горения
                                                    – так
                                                    фотограф
                                                    успеет
                                                    сделать
                                                    много
                                                    красивых
                                                    кадров,
                                                    а
                                                    видеограф
                                                    снимет
                                                    эффектное
                                                    видео.
                                                    Необходимое
                                                    количество
                                                    таких
                                                    бенгальских
                                                    огней
                                                    можно
                                                    заказать
                                                    заранее.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <ul className="article__gallery">
                                        <li className="article__gallery-item">
                                            <a
                                                className="article__gallery-link"
                                                href="imgwebp/pages/articles/bengalskie-ogni-na-svadbe/gallery/full/bengalskie-ogni-na-svadbe-1.webp"
                                                data-fslightbox="bengalskie-ogni-na-svadbe"
                                            >
                                                <picture>
                                                    <source
                                                        srcSet="imgwebp/pages/articles/bengalskie-ogni-na-svadbe/gallery/preview/bengalskie-ogni-na-svadbe-1.webp"
                                                        type="image/webp"
                                                    />
                                                    <img
                                                        src="#"
                                                        data-src="img/pages/articles/bengalskie-ogni-na-svadbe/gallery/preview/bengalskie-ogni-na-svadbe-1.jpg"
                                                        alt="Бенгальские огни на свадьбе 1"
                                                        className="article__gallery-img"
                                                    />
                                                </picture>
                                            </a>
                                        </li>
                                        <li className="article__gallery-item">
                                            <a
                                                className="article__gallery-link"
                                                href="imgwebp/pages/articles/bengalskie-ogni-na-svadbe/gallery/full/bengalskie-ogni-na-svadbe-2.webp"
                                                data-fslightbox="bengalskie-ogni-na-svadbe"
                                            >
                                                <picture>
                                                    <source
                                                        srcSet="imgwebp/pages/articles/bengalskie-ogni-na-svadbe/gallery/preview/bengalskie-ogni-na-svadbe-2.webp"
                                                        type="image/webp"
                                                    />
                                                    <img
                                                        src="#"
                                                        data-src="img/pages/articles/bengalskie-ogni-na-svadbe/gallery/preview/bengalskie-ogni-na-svadbe-2.jpg"
                                                        alt="Бенгальские огни на свадьбе 2"
                                                        className="article__gallery-img"
                                                    />
                                                </picture>
                                            </a>
                                        </li>
                                        <li className="article__gallery-item">
                                            <a
                                                className="article__gallery-link"
                                                href="imgwebp/pages/articles/bengalskie-ogni-na-svadbe/gallery/full/bengalskie-ogni-na-svadbe-3.webp"
                                                data-fslightbox="bengalskie-ogni-na-svadbe"
                                            >
                                                <picture>
                                                    <source
                                                        srcSet="imgwebp/pages/articles/bengalskie-ogni-na-svadbe/gallery/preview/bengalskie-ogni-na-svadbe-3.webp"
                                                        type="image/webp"
                                                    />
                                                    <img
                                                        src="#"
                                                        data-src="img/pages/articles/bengalskie-ogni-na-svadbe/gallery/preview/bengalskie-ogni-na-svadbe-3.jpg"
                                                        alt="Бенгальские огни на свадьбе 3"
                                                        className="article__gallery-img"
                                                    />
                                                </picture>
                                            </a>
                                        </li>
                                        <li className="article__gallery-item">
                                            <a
                                                className="article__gallery-link"
                                                href="imgwebp/pages/articles/bengalskie-ogni-na-svadbe/gallery/full/bengalskie-ogni-na-svadbe-4.webp"
                                                data-fslightbox="bengalskie-ogni-na-svadbe"
                                            >
                                                <picture>
                                                    <source
                                                        srcSet="imgwebp/pages/articles/bengalskie-ogni-na-svadbe/gallery/preview/bengalskie-ogni-na-svadbe-4.webp"
                                                        type="image/webp"
                                                    />
                                                    <img
                                                        src="#"
                                                        data-src="img/pages/articles/bengalskie-ogni-na-svadbe/gallery/preview/bengalskie-ogni-na-svadbe-4.jpg"
                                                        alt="Бенгальские огни на свадьбе 4"
                                                        className="article__gallery-img"
                                                    />
                                                </picture>
                                            </a>
                                        </li>
                                    </ul>
                                    <div className="article__flex">
                                        <div className="article__info">
                                            <h3 className="article__subtitle">
                                                Тот самый
                                                момент
                                            </h3>
                                            <div className="article__descr">
                                                <p>
                                                    Когда вы
                                                    будете
                                                    проходить
                                                    через
                                                    коридор
                                                    бенгальских
                                                    огней –
                                                    не
                                                    спешите
                                                    и
                                                    <strong>
                                                        наслаждайтесь
                                                    </strong>{" "}
                                                    этим
                                                    моментом.
                                                    Медленное
                                                    движение
                                                    позволит
                                                    фотографу
                                                    и
                                                    видеографу
                                                    легче
                                                    сфокусироваться,
                                                    да и вы
                                                    получите
                                                    гораздо
                                                    больше
                                                    удовольствия
                                                    от этого
                                                    волшебного
                                                    момента!
                                                </p>
                                                <p>
                                                    А если
                                                    вам
                                                    нужны{" "}
                                                    <strong>
                                                        динамичные
                                                        кадры
                                                    </strong>
                                                    , но вы
                                                    хотите
                                                    избежать
                                                    спешки,
                                                    подумайте
                                                    о том,
                                                    чтобы
                                                    пройти
                                                    через
                                                    тоннель
                                                    в танце
                                                    или
                                                    остановиться
                                                    посередине
                                                    и в
                                                    конце,
                                                    чтобы
                                                    поднять
                                                    невесту
                                                    на руки,
                                                    обняться
                                                    и
                                                    поцеловать
                                                    друг
                                                    друга!
                                                </p>
                                            </div>
                                        </div>
                                        <picture itemProp="image">
                                            <source
                                                srcSet="imgwebp/pages/articles/bengalskie-ogni-na-svadbe/tot-samii-moment.webp"
                                                type="image/webp"
                                            />
                                            <img
                                                src="#"
                                                data-src="img/pages/articles/bengalskie-ogni-na-svadbe/tot-samii-moment.jpg"
                                                alt="Тот самый момент"
                                                className="article__img"
                                            />
                                        </picture>
                                    </div>
                                    <button className="article__close">
                                        Свернуть статью
                                    </button>
                                </article>
                            </li>
                            <li className="articles__item">
                                <button
                                    onClick={() =>
                                        PolezIts(5)
                                    }
                                    className={
                                        punkt === 5
                                            ? "articles__item-btn articles__item-btn_active"
                                            : "articles__item-btn"
                                    }
                                >
                                    <span className="articles__item-btn-text">
                                        Плюсы и минусы
                                        цветного дыма на
                                        фотосессии
                                    </span>
                                </button>
                                <article  className={punkt === 5 ? "articles__tabs-article article article_991 article_active":"articles__tabs-article article article_991"}>
                                    <h2 className="sr-only">
                                        Плюсы и минусы
                                        цветного дыма на
                                        фотосессии
                                    </h2>
                                    <time
                                        className="article__time"
                                        dateTime="2022-03-24"
                                    >
                                        24/03/2022
                                    </time>
                                    <div className="article__flex article__flex_intro">
                                        <div className="article__info">
                                            <h3 className="article__subtitle">
                                                Плюсы
                                            </h3>
                                            <div className="article__descr">
                                                <p>
                                                    <strong>
                                                        Безопасность.
                                                    </strong>{" "}
                                                    Цветной
                                                    дым
                                                    относится
                                                    к
                                                    первому
                                                    классу
                                                    пиротехнических
                                                    изделий.
                                                    Он
                                                    безопасен
                                                    для
                                                    здоровья.
                                                </p>
                                                <p>
                                                    <strong>
                                                        Широкая
                                                        цветовая
                                                        палитра.
                                                    </strong>{" "}
                                                    Можно
                                                    выбрать
                                                    яркий
                                                    дым в
                                                    тон
                                                    торжества,
                                                    тематики
                                                    или
                                                    придать
                                                    сочность
                                                    оттенкам
                                                    природы.
                                                    Можно
                                                    комбинировать
                                                    цвета,
                                                    создавая
                                                    красивые
                                                    эффекты.
                                                </p>
                                                <p>
                                                    <strong>
                                                        Без
                                                        следов
                                                        на
                                                        одежде
                                                        и
                                                        мусора.
                                                    </strong>{" "}
                                                    Цветной
                                                    дым не
                                                    пачкает
                                                    одежду и
                                                    волосы.
                                                    После
                                                    его
                                                    использования
                                                    не
                                                    потребуется
                                                    уборка.
                                                </p>
                                                <p>
                                                    <strong>
                                                        Для
                                                        дневной
                                                        и
                                                        ночной
                                                        фотосессии.
                                                    </strong>{" "}
                                                    Цветной
                                                    дым
                                                    красиво
                                                    смотрится
                                                    не
                                                    только
                                                    при
                                                    дневном
                                                    свете. В
                                                    темноте
                                                    он
                                                    производит
                                                    не менее
                                                    яркое
                                                    впечатление
                                                    и
                                                    эффектно
                                                    преображает
                                                    фото.
                                                </p>
                                                <p>
                                                    <strong>
                                                        Яркие
                                                        впечатления
                                                        для
                                                        всех.
                                                    </strong>{" "}
                                                    Яркий
                                                    дым
                                                    поднимает
                                                    настроение
                                                    даже в
                                                    самую
                                                    унылую
                                                    погоду.
                                                </p>
                                                <p>
                                                    <strong>
                                                        Коррекция
                                                        места
                                                        фотосессии.
                                                    </strong>{" "}
                                                    Разноцветный
                                                    дым
                                                    создаёт
                                                    невероятный
                                                    сказочный
                                                    антураж.
                                                    Это
                                                    впечатляющее
                                                    зрелище,
                                                    которое
                                                    помогает
                                                    скрыть
                                                    «нефотогеничный
                                                    пейзаж».
                                                </p>
                                            </div>
                                        </div>
                                        <picture>
                                            <source
                                                srcSet="imgwebp/pages/articles/plusi-i-minusi-tsvetnogo-dima/plusi.webp"
                                                type="image/webp"
                                            />
                                            <img
                                                src="#"
                                                data-src="img/pages/articles/plusi-i-minusi-tsvetnogo-dima/plusi.jpg"
                                                alt="Плюсы"
                                                className="article__img article__img_intro"
                                            />
                                        </picture>
                                    </div>
                                    <p className="article__warning">
                                        Разноцветный дым
                                        создаёт невероятный
                                        сказочный антураж.
                                        Это впечатляющее
                                        зрелище, которое
                                        помогает скрыть
                                        «нефотогеничный
                                        пейзаж».
                                    </p>
                                    <div className="article__flex">
                                        <picture>
                                            <source
                                                srcSet="imgwebp/pages/articles/plusi-i-minusi-tsvetnogo-dima/minusi.webp"
                                                type="image/webp"
                                            />
                                            <img
                                                src="#"
                                                data-src="img/pages/articles/plusi-i-minusi-tsvetnogo-dima/minusi.jpg"
                                                alt="Минусы"
                                                className="article__img"
                                            />
                                        </picture>
                                        <div className="article__info">
                                            <h3 className="article__subtitle">
                                                Минусы
                                            </h3>
                                            <div className="article__descr">
                                                <p>
                                                    <strong>
                                                        Неприятный
                                                        запах.
                                                    </strong>{" "}
                                                    Для
                                                    большинства
                                                    шашек
                                                    типичен
                                                    отчетливый
                                                    едкий
                                                    запах.
                                                    Поэтому
                                                    лучше
                                                    отказаться
                                                    от идеи
                                                    съемки в
                                                    абсолютно
                                                    закрытых
                                                    пространствах.
                                                </p>
                                                <p>
                                                    <strong>
                                                        Проверка
                                                        профессионализма
                                                        фотографа.
                                                    </strong>{" "}
                                                    Действие
                                                    цветного
                                                    дыма
                                                    длится
                                                    от 30 до
                                                    60сек.
                                                    Казалось
                                                    бы
                                                    –немного,
                                                    но этого
                                                    достаточно,
                                                    чтобы
                                                    опытный
                                                    фотограф
                                                    сделал
                                                    несколько
                                                    необычных
                                                    фотокадров,
                                                    уточните
                                                    у
                                                    фотографа,
                                                    есть ли
                                                    у него
                                                    опыт
                                                    работы с
                                                    таким
                                                    эффектом.
                                                    Вместе с
                                                    фотографом
                                                    придумайтесь
                                                    сценарий
                                                    съемки –
                                                    так вы
                                                    сохраните
                                                    время,
                                                    когда
                                                    дым
                                                    будет в
                                                    действии.
                                                </p>
                                                <p>
                                                    <strong>
                                                        Не
                                                        для
                                                        ветреной
                                                        погоды.
                                                    </strong>{" "}
                                                    Сильный
                                                    ветер –
                                                    враг
                                                    цветного
                                                    дыма на
                                                    фотосессии.
                                                    Несмотря
                                                    на
                                                    насыщенность
                                                    и
                                                    густоту
                                                    эффекта,
                                                    вся
                                                    красота
                                                    развеется
                                                    в
                                                    считанные
                                                    секунды.
                                                </p>
                                                <p>
                                                    <strong>
                                                        Нужен
                                                        запас.
                                                    </strong>{" "}
                                                    Чтобы не
                                                    испортить
                                                    настроение,
                                                    если дым
                                                    развеется
                                                    слишком
                                                    быстро,
                                                    а
                                                    классных
                                                    фото
                                                    получилось
                                                    не так
                                                    много –
                                                    возьмите
                                                    с собой
                                                    запасной
                                                    дым.
                                                </p>
                                                <p>
                                                    <strong>
                                                        Необходим
                                                        ассистент.
                                                    </strong>{" "}
                                                    Во время
                                                    фотосессии
                                                    не
                                                    всегда
                                                    можно
                                                    обойтись
                                                    без
                                                    помощника,
                                                    который
                                                    запустит
                                                    дым и
                                                    разбросает
                                                    его в
                                                    нужное
                                                    место.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <ul className="article__gallery">
                                        <li className="article__gallery-item">
                                            <a
                                                className="article__gallery-link"
                                                href="imgwebp/pages/articles/plusi-i-minusi-tsvetnogo-dima/gallery/full/plusi-i-minusi-tsvetnogo-dima-1.webp"
                                                data-fslightbox="plusi-i-minusi-tsvetnogo-dima"
                                            >
                                                <picture>
                                                    <source
                                                        srcSet="imgwebp/pages/articles/plusi-i-minusi-tsvetnogo-dima/gallery/preview/plusi-i-minusi-tsvetnogo-dima-1.webp"
                                                        type="image/webp"
                                                    />
                                                    <img
                                                        src="#"
                                                        data-src="img/pages/articles/plusi-i-minusi-tsvetnogo-dima/gallery/preview/plusi-i-minusi-tsvetnogo-dima-1.jpg"
                                                        alt="Плюсы и минусы цветного дыма 1"
                                                        className="article__gallery-img"
                                                    />
                                                </picture>
                                            </a>
                                        </li>
                                        <li className="article__gallery-item">
                                            <a
                                                className="article__gallery-link"
                                                href="imgwebp/pages/articles/plusi-i-minusi-tsvetnogo-dima/gallery/full/plusi-i-minusi-tsvetnogo-dima-2.webp"
                                                data-fslightbox="plusi-i-minusi-tsvetnogo-dima"
                                            >
                                                <picture>
                                                    <source
                                                        srcSet="imgwebp/pages/articles/plusi-i-minusi-tsvetnogo-dima/gallery/preview/plusi-i-minusi-tsvetnogo-dima-2.webp"
                                                        type="image/webp"
                                                    />
                                                    <img
                                                        src="#"
                                                        data-src="img/pages/articles/plusi-i-minusi-tsvetnogo-dima/gallery/preview/plusi-i-minusi-tsvetnogo-dima-2.jpg"
                                                        alt="Плюсы и минусы цветного дыма 2"
                                                        className="article__gallery-img"
                                                    />
                                                </picture>
                                            </a>
                                        </li>
                                        <li className="article__gallery-item">
                                            <a
                                                className="article__gallery-link"
                                                href="imgwebp/pages/articles/plusi-i-minusi-tsvetnogo-dima/gallery/full/plusi-i-minusi-tsvetnogo-dima-3.webp"
                                                data-fslightbox="plusi-i-minusi-tsvetnogo-dima"
                                            >
                                                <picture>
                                                    <source
                                                        srcSet="imgwebp/pages/articles/plusi-i-minusi-tsvetnogo-dima/gallery/preview/plusi-i-minusi-tsvetnogo-dima-3.webp"
                                                        type="image/webp"
                                                    />
                                                    <img
                                                        src="#"
                                                        data-src="img/pages/articles/plusi-i-minusi-tsvetnogo-dima/gallery/preview/plusi-i-minusi-tsvetnogo-dima-3.jpg"
                                                        alt="Плюсы и минусы цветного дыма 3"
                                                        className="article__gallery-img"
                                                    />
                                                </picture>
                                            </a>
                                        </li>
                                        <li className="article__gallery-item">
                                            <a
                                                className="article__gallery-link"
                                                href="imgwebp/pages/articles/plusi-i-minusi-tsvetnogo-dima/gallery/full/plusi-i-minusi-tsvetnogo-dima-4.webp"
                                                data-fslightbox="plusi-i-minusi-tsvetnogo-dima"
                                            >
                                                <picture>
                                                    <source
                                                        srcSet="imgwebp/pages/articles/plusi-i-minusi-tsvetnogo-dima/gallery/preview/plusi-i-minusi-tsvetnogo-dima-4.webp"
                                                        type="image/webp"
                                                    />
                                                    <img
                                                        src="#"
                                                        data-src="img/pages/articles/plusi-i-minusi-tsvetnogo-dima/gallery/preview/plusi-i-minusi-tsvetnogo-dima-4.jpg"
                                                        alt="Плюсы и минусы цветного дыма 4"
                                                        className="article__gallery-img"
                                                    />
                                                </picture>
                                            </a>
                                        </li>
                                    </ul>
                                    <div className="article__flex">
                                        <div className="article__info">
                                            <h3 className="article__subtitle">
                                                Подводим
                                                итоги
                                            </h3>
                                            <div className="article__descr">
                                                <p>
                                                    Как
                                                    любой
                                                    пиротехнический
                                                    продукт,
                                                    цветной
                                                    дым
                                                    требует
                                                    осторожности
                                                    во время
                                                    его
                                                    использования.
                                                    Но ведь
                                                    ты
                                                    хочешь
                                                    красивую
                                                    и яркую
                                                    фотосессию?
                                                    Если так
                                                    – даже
                                                    не
                                                    сомневайся!
                                                </p>
                                            </div>
                                        </div>
                                        <picture>
                                            <source
                                                srcSet="imgwebp/pages/articles/plusi-i-minusi-tsvetnogo-dima/itogi.webp"
                                                type="image/webp"
                                            />
                                            <img
                                                src="#"
                                                data-src="img/pages/articles/plusi-i-minusi-tsvetnogo-dima/itogi.jpg"
                                                alt="Итоги"
                                                className="article__img"
                                            />
                                        </picture>
                                    </div>
                                    <button className="article__close">
                                        Свернуть статью
                                    </button>
                                </article>
                            </li>
                            <li className="articles__item">
                                <button
                                    onClick={() =>
                                        PolezIts(6)
                                    }
                                    className={
                                        punkt === 6
                                            ? "articles__item-btn articles__item-btn_active"
                                            : "articles__item-btn"
                                    }
                                >
                                    <span className="articles__item-btn-text">
                                        Цветной дым на
                                        свадьбе
                                    </span>
                                </button>
                                <article  className={punkt === 6 ? "articles__tabs-article article article_991 article_active":"articles__tabs-article article article_991"}>
                                    <h2 className="sr-only">
                                        Цветной дым на
                                        свадьбе
                                    </h2>
                                    <time
                                        className="article__time"
                                        dateTime="2022-04-09"
                                    >
                                        09/04/2022
                                    </time>
                                    <div className="article__flex article__flex_intro">
                                        <div className="article__info">
                                            <h3 className="article__subtitle">
                                                Креативный,
                                                компактный,
                                                добавляет
                                                динамики и
                                                объём
                                                фотографиям,
                                                недорогой
                                            </h3>
                                            <div className="article__descr">
                                                <p>
                                                    Для
                                                    эффектной
                                                    и
                                                    интересной
                                                    съёмки в
                                                    первую
                                                    очередь
                                                    отталкивайтесь
                                                    от общей
                                                    концепции
                                                    свадьбы.
                                                    Если у
                                                    торжества
                                                    нет
                                                    четкой
                                                    цели, то
                                                    фантазируйте,
                                                    придумайте
                                                    свой
                                                    сюжет.
                                                </p>
                                                <p>
                                                    Поиграйте
                                                    с
                                                    цветом:
                                                    создайте
                                                    контраст
                                                    (например
                                                    с
                                                    платьем
                                                    невесты)
                                                    или
                                                    наоборот
                                                    продублируйте
                                                    оттенки,
                                                    которые
                                                    есть в
                                                    вашем
                                                    образе
                                                    (букет,
                                                    аксессуары),
                                                    ️создайте
                                                    мини-фотоисторию,
                                                    где дым
                                                    добавит
                                                    экшена и
                                                    атмосферы,
                                                    ️привлекайте
                                                    гостей
                                                    для
                                                    воплощения
                                                    масштабных
                                                    идей,
                                                    фотограф
                                                    сможет
                                                    поиграть
                                                    с
                                                    ракурсом.
                                                </p>
                                            </div>
                                        </div>
                                        <picture>
                                            <source
                                                srcSet="imgwebp/pages/articles/tsvetnoi-dim-na-svadbe/kreativnii.webp"
                                                type="image/webp"
                                            />
                                            <img
                                                src="#"
                                                data-src="img/pages/articles/tsvetnoi-dim-na-svadbe/kreativnii.jpg"
                                                alt="Креативный, компактный, недорогой"
                                                className="article__img article__img_intro"
                                            />
                                        </picture>
                                    </div>
                                    <p className="article__warning">
                                        Не только идеальные
                                        постановочные, но и
                                        забавные фото имеют
                                        право быть в
                                        семейном архиве
                                    </p>
                                    <div className="article__flex">
                                        <picture>
                                            <source
                                                srcSet="imgwebp/pages/articles/tsvetnoi-dim-na-svadbe/kruto.webp"
                                                type="image/webp"
                                            />
                                            <img
                                                src="#"
                                                data-src="img/pages/articles/tsvetnoi-dim-na-svadbe/kruto.jpg"
                                                alt="Цветной дым - это круто!"
                                                className="article__img"
                                            />
                                        </picture>
                                        <div className="article__info">
                                            <h3 className="article__subtitle">
                                                Цветной дым
                                                – это круто
                                                и необычно,
                                                но не стоит
                                                делать его
                                                главным
                                                «героем»
                                                фотокадров.
                                                Пусть в
                                                главной роли
                                                будет любовь
                                                двоих,
                                                искренняя
                                                радость и
                                                счастье.
                                            </h3>
                                        </div>
                                    </div>
                                    <ul className="article__gallery">
                                        <li className="article__gallery-item">
                                            <a
                                                className="article__gallery-link"
                                                href="imgwebp/pages/articles/tsvetnoi-dim-na-svadbe/gallery/full/tsvetnoi-dim-na-svadbe-1.webp"
                                                data-fslightbox="tsvetnoi-dim-na-svadbe"
                                            >
                                                <picture>
                                                    <source
                                                        srcSet="imgwebp/pages/articles/tsvetnoi-dim-na-svadbe/gallery/preview/tsvetnoi-dim-na-svadbe-1.webp"
                                                        type="image/webp"
                                                    />
                                                    <img
                                                        src="#"
                                                        data-src="img/pages/articles/tsvetnoi-dim-na-svadbe/gallery/preview/tsvetnoi-dim-na-svadbe-1.jpg"
                                                        alt="Цветной дым на свадьбе 1"
                                                        className="article__gallery-img"
                                                    />
                                                </picture>
                                            </a>
                                        </li>
                                        <li className="article__gallery-item">
                                            <a
                                                className="article__gallery-link"
                                                href="imgwebp/pages/articles/tsvetnoi-dim-na-svadbe/gallery/full/tsvetnoi-dim-na-svadbe-2.webp"
                                                data-fslightbox="tsvetnoi-dim-na-svadbe"
                                            >
                                                <picture>
                                                    <source
                                                        srcSet="imgwebp/pages/articles/tsvetnoi-dim-na-svadbe/gallery/preview/tsvetnoi-dim-na-svadbe-2.webp"
                                                        type="image/webp"
                                                    />
                                                    <img
                                                        src="#"
                                                        data-src="img/pages/articles/tsvetnoi-dim-na-svadbe/gallery/preview/tsvetnoi-dim-na-svadbe-2.jpg"
                                                        alt="Цветной дым на свадьбе 2"
                                                        className="article__gallery-img"
                                                    />
                                                </picture>
                                            </a>
                                        </li>
                                        <li className="article__gallery-item">
                                            <a
                                                className="article__gallery-link"
                                                href="imgwebp/pages/articles/tsvetnoi-dim-na-svadbe/gallery/full/tsvetnoi-dim-na-svadbe-3.webp"
                                                data-fslightbox="tsvetnoi-dim-na-svadbe"
                                            >
                                                <picture>
                                                    <source
                                                        srcSet="imgwebp/pages/articles/tsvetnoi-dim-na-svadbe/gallery/preview/tsvetnoi-dim-na-svadbe-3.webp"
                                                        type="image/webp"
                                                    />
                                                    <img
                                                        src="#"
                                                        data-src="img/pages/articles/tsvetnoi-dim-na-svadbe/gallery/preview/tsvetnoi-dim-na-svadbe-3.jpg"
                                                        alt="Цветной дым на свадьбе 3"
                                                        className="article__gallery-img"
                                                    />
                                                </picture>
                                            </a>
                                        </li>
                                        <li className="article__gallery-item">
                                            <a
                                                className="article__gallery-link"
                                                href="imgwebp/pages/articles/tsvetnoi-dim-na-svadbe/gallery/full/tsvetnoi-dim-na-svadbe-4.webp"
                                                data-fslightbox="tsvetnoi-dim-na-svadbe"
                                            >
                                                <picture>
                                                    <source
                                                        srcSet="imgwebp/pages/articles/tsvetnoi-dim-na-svadbe/gallery/preview/tsvetnoi-dim-na-svadbe-4.webp"
                                                        type="image/webp"
                                                    />
                                                    <img
                                                        src="#"
                                                        data-src="img/pages/articles/tsvetnoi-dim-na-svadbe/gallery/preview/tsvetnoi-dim-na-svadbe-4.jpg"
                                                        alt="Цветной дым на свадьбе 4"
                                                        className="article__gallery-img"
                                                    />
                                                </picture>
                                            </a>
                                        </li>
                                    </ul>
                                    <button className="article__close">
                                        Свернуть статью
                                    </button>
                                </article>
                            </li>
                            <li className="articles__item">
                                <button
                                    onClick={() =>
                                        PolezIts(7)
                                    }
                                    className={
                                        punkt === 7
                                            ? "articles__item-btn articles__item-btn_active"
                                            : "articles__item-btn"
                                    }
                                >
                                    <span className="articles__item-btn-text">
                                        10 правил
                                        безопасного запуска
                                        фейерверка
                                    </span>
                                </button>
                                <article  className={punkt === 7 ? "articles__tabs-article article article_991 article_active":"articles__tabs-article article article_991"}>
                                    <h2 className="sr-only">
                                        10 правил
                                        безопасного запуска
                                        фейерверка
                                    </h2>
                                    <time
                                        className="article__time"
                                        dateTime="2022-04-09"
                                    >
                                        03/05/2022
                                    </time>
                                    <div className="article__flex article__flex_intro">
                                        <div className="article__info">
                                            <h3 className="article__subtitle">
                                                Купили
                                                фейерверк?
                                                Отлично, мы
                                                расскажем,
                                                как
                                                правильно и
                                                безопасно
                                                его
                                                запустить!
                                            </h3>
                                            <div className="article__descr">
                                                <p>
                                                    <strong>
                                                        ПОКУПАЕМ
                                                        СЕРТИФИЦИРОВАННУЮ
                                                        ПИРОТЕХНИКУ.
                                                    </strong>{" "}
                                                    Покупайте
                                                    пиротехнику
                                                    только в
                                                    специализированных
                                                    магазинах,
                                                    именно
                                                    там вам
                                                    предоставят
                                                    сертификацию
                                                    качества
                                                    на всю
                                                    продукцию.
                                                </p>
                                                <p>
                                                    <strong>
                                                        СМОТРИМ
                                                        НА
                                                        СРОК
                                                        ГОДНОСТИ.
                                                    </strong>{" "}
                                                    При
                                                    покупке,
                                                    обратите
                                                    внимание
                                                    на срок
                                                    годности
                                                    и
                                                    состояние
                                                    упаковки.
                                                    ️Если вы
                                                    планируете
                                                    запускать
                                                    фейерверк
                                                    НЕ В
                                                    ДЕНЬ
                                                    ПОКУПКИ
                                                    –
                                                    выберете
                                                    для его
                                                    хранения
                                                    сухое
                                                    место и
                                                    подальше
                                                    от
                                                    нагревательных
                                                    приборов,
                                                    солнечных
                                                    лучей.
                                                </p>
                                                <p>
                                                    <strong>
                                                        ВЫБИРАЕМ
                                                        ПРАВИЛЬНОЕ
                                                        МЕСТО
                                                        ДЛЯ
                                                        УСТАНОВКИ.
                                                    </strong>{" "}
                                                    Выберете
                                                    площадку
                                                    не
                                                    меньше
                                                    50х50
                                                    метров,
                                                    свободную
                                                    от
                                                    препятствий
                                                    (не
                                                    должно
                                                    быть
                                                    деревьев,
                                                    автомобилей
                                                    и
                                                    построек
                                                    в
                                                    радиусе
                                                    50м).
                                                </p>
                                                <p>
                                                    <strong>
                                                        УЧИТЫВАЕМ
                                                        ПОГОДНЫЕ
                                                        УСЛОВИЯ.
                                                    </strong>{" "}
                                                    Запрещается
                                                    запускать
                                                    фейерверк
                                                    при
                                                    сильном
                                                    порывистом
                                                    ветре.
                                                </p>
                                            </div>
                                        </div>
                                        <picture>
                                            <source
                                                srcSet="imgwebp/pages/articles/10-pravil/bezopasnost.webp"
                                                type="image/webp"
                                            />
                                            <img
                                                src="#"
                                                data-src="img/pages/articles/10-pravil/bezopasnost.jpg"
                                                alt="Безопасность"
                                                className="article__img article__img_intro"
                                            />
                                        </picture>
                                    </div>
                                    <p className="article__warning">
                                        Бытовой фейерверк вы
                                        можете запустить
                                        самостоятельно, без
                                        помощи пиротехника,
                                        следуя нашим
                                        рекомендациям. При
                                        этом, вы должны быть
                                        совершеннолетним и в
                                        трезвом состоянии.
                                    </p>
                                    <div className="article__flex">
                                        <picture>
                                            <source
                                                srcSet="imgwebp/pages/articles/10-pravil/pravila.webp"
                                                type="image/webp"
                                            />
                                            <img
                                                src="#"
                                                data-src="img/pages/articles/10-pravil/pravila.jpg"
                                                alt="Правила"
                                                className="article__img"
                                            />
                                        </picture>
                                        <div className="article__info">
                                            <div className="article__descr">
                                                <p>
                                                    <strong>
                                                        УСТАНАВЛИВАЕМ
                                                        ФЕЙЕРВЕРК.
                                                    </strong>{" "}
                                                    Установите
                                                    фейерверк
                                                    строго
                                                    вертикально
                                                    на
                                                    ровную
                                                    поверхность
                                                    и
                                                    зафиксируйте
                                                    (песком
                                                    или
                                                    кирпичами).
                                                    Это
                                                    связано
                                                    с тем,
                                                    что
                                                    отдача
                                                    при
                                                    выстреле
                                                    может
                                                    перевернуть
                                                    батарею,
                                                    непредсказуемо
                                                    изменив
                                                    направление
                                                    залпов.
                                                    На
                                                    упаковке
                                                    написано
                                                    – что
                                                    поджигать,
                                                    куда
                                                    направлять
                                                    и как
                                                    устанавливать,
                                                    ознакомьтесь
                                                    с
                                                    инструкцией.
                                                </p>
                                                <p>
                                                    <strong>
                                                        ЗАЖИГАЕМ
                                                        ФИТИЛЬ.
                                                    </strong>{" "}
                                                    Поджигайте
                                                    фитиль
                                                    фейерверка
                                                    только
                                                    НА
                                                    ВЫТЯНУТОЙ
                                                    РУКЕ, не
                                                    наклоняйтесь
                                                    над
                                                    установкой.
                                                </p>
                                                <p>
                                                    <strong>
                                                        ДЕРЖИМ
                                                        ДИСТАНЦИЮ.
                                                    </strong>{" "}
                                                    Сразу
                                                    после
                                                    воспламенения
                                                    отойдите
                                                    на
                                                    безопасное
                                                    расстояние
                                                    – 20м
                                                    или
                                                    более.
                                                    Когда
                                                    будет
                                                    стрелять
                                                    фейерверк,
                                                    не
                                                    подходите
                                                    ближе,
                                                    выберете
                                                    точку с
                                                    хорошим
                                                    обзором
                                                    заранее.
                                                    Радиус
                                                    опасной
                                                    зоны для
                                                    батареи
                                                    салютов
                                                    –
                                                    20-50м.
                                                </p>
                                                <p>
                                                    <strong>
                                                        НАСЛАЖДАЕМСЯ
                                                        КРАСОТОЙ.
                                                    </strong>{" "}
                                                    Теперь,
                                                    когда
                                                    всё
                                                    готово –
                                                    можно
                                                    наслаждаться
                                                    красивыми
                                                    огнями в
                                                    небе.
                                                </p>
                                                <p>
                                                    <strong>
                                                        ВЫЖИДАЕМ
                                                        ВРЕМЯ.
                                                    </strong>{" "}
                                                    После
                                                    завершения
                                                    фейерверка,
                                                    не
                                                    подходите
                                                    к
                                                    установке
                                                    ещё
                                                    минут
                                                    5-10.
                                                    Внутри
                                                    могут
                                                    остаться
                                                    заряды,
                                                    которые
                                                    сработают
                                                    только
                                                    через
                                                    некоторое
                                                    время
                                                    из-за
                                                    тления
                                                    корпуса.
                                                </p>
                                                <p>
                                                    <strong>
                                                        УТИЛИЗИРУЕМ
                                                        КОРОБКУ.
                                                    </strong>{" "}
                                                    Тщательно
                                                    соберите
                                                    всё, что
                                                    осталось
                                                    после
                                                    запуска
                                                    фейерверка
                                                    и
                                                    утилизируйте.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <ul className="article__gallery">
                                        <li className="article__gallery-item">
                                            <a
                                                className="article__gallery-link"
                                                href="imgwebp/pages/articles/10-pravil/gallery/full/10-pravil-1.webp"
                                                data-fslightbox="10-pravil"
                                            >
                                                <picture>
                                                    <source
                                                        srcSet="imgwebp/pages/articles/10-pravil/gallery/preview/10-pravil-1.webp"
                                                        type="image/webp"
                                                    />
                                                    <img
                                                        src="#"
                                                        data-src="img/pages/articles/10-pravil/gallery/preview/10-pravil-1.jpg"
                                                        alt="10 правил безопасного запуска фейерверка 1"
                                                        className="article__gallery-img"
                                                    />
                                                </picture>
                                            </a>
                                        </li>
                                        <li className="article__gallery-item">
                                            <a
                                                className="article__gallery-link"
                                                href="imgwebp/pages/articles/10-pravil/gallery/full/10-pravil-2.webp"
                                                data-fslightbox="10-pravil"
                                            >
                                                <picture>
                                                    <source
                                                        srcSet="imgwebp/pages/articles/10-pravil/gallery/preview/10-pravil-2.webp"
                                                        type="image/webp"
                                                    />
                                                    <img
                                                        src="#"
                                                        data-src="img/pages/articles/10-pravil/gallery/preview/10-pravil-2.jpg"
                                                        alt="10 правил безопасного запуска фейерверка 2"
                                                        className="article__gallery-img"
                                                    />
                                                </picture>
                                            </a>
                                        </li>
                                        <li className="article__gallery-item">
                                            <a
                                                className="article__gallery-link"
                                                href="imgwebp/pages/articles/10-pravil/gallery/full/10-pravil-3.webp"
                                                data-fslightbox="10-pravil"
                                            >
                                                <picture>
                                                    <source
                                                        srcSet="imgwebp/pages/articles/10-pravil/gallery/preview/10-pravil-3.webp"
                                                        type="image/webp"
                                                    />
                                                    <img
                                                        src="#"
                                                        data-src="img/pages/articles/10-pravil/gallery/preview/10-pravil-3.jpg"
                                                        alt="10 правил безопасного запуска фейерверка 3"
                                                        className="article__gallery-img"
                                                    />
                                                </picture>
                                            </a>
                                        </li>
                                        <li className="article__gallery-item">
                                            <a
                                                className="article__gallery-link"
                                                href="imgwebp/pages/articles/10-pravil/gallery/full/10-pravil-4.webp"
                                                data-fslightbox="10-pravil"
                                            >
                                                <picture>
                                                    <source
                                                        srcSet="imgwebp/pages/articles/10-pravil/gallery/preview/10-pravil-4.webp"
                                                        type="image/webp"
                                                    />
                                                    <img
                                                        src="#"
                                                        data-src="img/pages/articles/10-pravil/gallery/preview/10-pravil-4.jpg"
                                                        alt="10 правил безопасного запуска фейерверка 4"
                                                        className="article__gallery-img"
                                                    />
                                                </picture>
                                            </a>
                                        </li>
                                    </ul>
                                    <div
                                        className="article__flex"
                                        itemProp="description"
                                    >
                                        <div className="article__info">
                                            <h3 className="article__subtitle">
                                                Что делать,
                                                если
                                                фейерверк не
                                                выстрелил
                                            </h3>
                                            <div className="article__descr">
                                                <p>
                                                    Главное
                                                    – не
                                                    бегите
                                                    сразу же
                                                    к нему,
                                                    бракованные
                                                    салюты
                                                    могут
                                                    сработать
                                                    с
                                                    задержкой.
                                                    Поэтому,
                                                    подождите
                                                    10-15
                                                    минут,
                                                    убедитесь,
                                                    что
                                                    фейерверк
                                                    не горит
                                                    и не
                                                    тлеет
                                                    (нет
                                                    искр и
                                                    дыма).
                                                    Только
                                                    после
                                                    этого
                                                    можно
                                                    подойти
                                                    и
                                                    аккуратно
                                                    посмотреть.
                                                    Попробуйте
                                                    зажечь
                                                    запасной
                                                    фитиль.
                                                    Если же
                                                    и в этом
                                                    случае
                                                    фейерверк
                                                    не
                                                    выстрелил
                                                    – через
                                                    некоторое
                                                    время
                                                    погрузите
                                                    батарею
                                                    в воду
                                                    на
                                                    несколько
                                                    часов и
                                                    только
                                                    потом
                                                    утилизируйте.
                                                </p>
                                                <p>
                                                    Мы даём
                                                    гарантию
                                                    на всю
                                                    пиротехнику.
                                                    Если
                                                    вдруг
                                                    наш
                                                    фейерверк
                                                    не
                                                    взорвался
                                                    – мы
                                                    поменяем
                                                    вам
                                                    коробку
                                                    или
                                                    вернём
                                                    деньги
                                                    (для
                                                    этого
                                                    нужна
                                                    будет
                                                    коробка
                                                    от
                                                    вашего
                                                    салюта).
                                                    Мы
                                                    уверены
                                                    в своей
                                                    продукции.
                                                </p>
                                                <p>
                                                    Запускайте
                                                    фейерверк
                                                    правильно
                                                    и
                                                    безопасно
                                                    и
                                                    наслаждайтесь
                                                    красивыми
                                                    спецэффектами.
                                                </p>
                                            </div>
                                        </div>
                                        <picture itemProp="image">
                                            <source
                                                srcSet="imgwebp/pages/articles/10-pravil/ne-vistrelil.webp"
                                                type="image/webp"
                                            />
                                            <img
                                                src="#"
                                                data-src="img/pages/articles/10-pravil/ne-vistrelil.jpg"
                                                alt="Что делать, если фейерверк не выстрелил"
                                                className="article__img"
                                            />
                                        </picture>
                                    </div>
                                    <button className="article__close">
                                        Свернуть статью
                                    </button>
                                </article>
                            </li>
                        </ul>
                    </div>
                    <ul className="articles__tabs">
                        <li
                            className={
                                punkt === 0
                                    ? "articles__tabs-item articles__tabs-item_active"
                                    : "articles__tabs-item"
                            }
                        >
                            <article
                                className="articles__tabs-article article"
                                itemScope
                                itemType="https://schema.org/Article"
                            >
                                <h2
                                    className="article__title"
                                    itemProp="name"
                                >
                                    Кто такой пиротехник?
                                </h2>
                                <time
                                    className="article__time"
                                    dateTime="2022-01-10"
                                    itemProp="datePublished"
                                    content="2022-01-10"
                                >
                                    10/01/2022{" "}
                                </time>

                                <div
                                    className="article__flex article__flex_intro"
                                    itemProp="description"
                                >
                                    <div className="article__info">
                                        <h3 className="article__subtitle">
                                            Это редкая, но
                                            удивительная
                                            профессия,
                                            которая сочетает
                                            в себе науку,
                                            технику и
                                            искусство
                                        </h3>
                                        <div className="article__descr">
                                            <p>
                                                Все мы любим
                                                фейерверки:
                                                какой же
                                                праздник
                                                можно
                                                представить
                                                без ярких
                                                огней в
                                                небе. Ведь
                                                фейерверк
                                                это взрыв:
                                                взрыв
                                                эмоций,
                                                чувств,
                                                переживаний.
                                            </p>
                                            <p>
                                                Для нас это
                                                безумно
                                                интересная,
                                                зрелищная и
                                                даже
                                                романтичная
                                                профессия.
                                                Тут есть
                                                риск,
                                                красота,
                                                волшебство,
                                                химия и
                                                праздник. С
                                                2007 года мы
                                                развиваемся
                                                в этом
                                                направлении
                                                и дарим
                                                людям
                                                эмоции. Это
                                                уже наш
                                                стиль жизни,
                                                в котором
                                                заложен
                                                творческий
                                                подход и
                                                даже
                                                дизайнерская
                                                жилка.
                                            </p>
                                        </div>
                                    </div>
                                    <picture itemProp="image">
                                        <source
                                            srcSet="imgwebp/pages/articles/kto-takoi-pirotechnik/eto-redkaya-no-udivitelnaya-professiya.webp"
                                            type="image/webp"
                                        />
                                        <img
                                            src="#"
                                            data-src="img/pages/articles/kto-takoi-pirotechnik/eto-redkaya-no-udivitelnaya-professiya.jpg"
                                            alt="Это редкая, но удивительная профессия, которая сочетает в себе науку, технику и искусство"
                                            className="article__img article__img_intro"
                                        />
                                    </picture>
                                </div>
                                <p className="article__warning">
                                    Во время запуска
                                    пиротехнического шоу или
                                    профессионального
                                    фейерверка у нас под
                                    контролем масса
                                    устройств, мы отвечаем
                                    не только за красочное
                                    шоу, но и за
                                    безопасность людей.
                                </p>
                                <div
                                    className="article__flex"
                                    itemProp="description"
                                >
                                    <picture itemProp="image">
                                        <source
                                            srcSet="imgwebp/pages/articles/kto-takoi-pirotechnik/kontsentratsiya-i-tochnost.webp"
                                            type="image/webp"
                                        />
                                        <img
                                            src="#"
                                            data-src="img/pages/articles/kto-takoi-pirotechnik/kontsentratsiya-i-tochnost.jpg"
                                            alt="Наша работа требует особой концентрации и точности"
                                            className="article__img"
                                        />
                                    </picture>
                                    <div className="article__info">
                                        <h3 className="article__subtitle">
                                            Наша работа
                                            требует особой
                                            концентрации и
                                            точности
                                        </h3>
                                        <div className="article__descr">
                                            <p>
                                                Если бытовые
                                                фейерверки
                                                может
                                                запустить
                                                любой
                                                взрослый,
                                                следуя нашим
                                                рекомендациям,
                                                то с более
                                                мощным
                                                салютом
                                                могут
                                                справиться
                                                только
                                                профессиональные
                                                пиротехники.
                                            </p>
                                            <p>
                                                Мы создаём
                                                концепцию
                                                салюта –
                                                пишем
                                                специальную
                                                программу;
                                                оцениваем и
                                                прорабатываемся
                                                масштаб
                                                запуска, его
                                                интенсивность,
                                                площадь;
                                                отбираем
                                                необходимые
                                                изделия;
                                                доставляем к
                                                месту
                                                проведения
                                                шоу; делаем
                                                монтаж
                                                пиротехнических
                                                элементов на
                                                месте
                                                запуска;
                                                проверяем
                                                правильность
                                                подключения
                                                и технику
                                                безопасности,
                                                и только
                                                после этого
                                                запускаем
                                                мощное
                                                пиротехническое
                                                шоу – это и
                                                есть чувство
                                                праздника.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <ul className="article__gallery">
                                    <li className="article__gallery-item">
                                        <a
                                            className="article__gallery-link"
                                            href="imgwebp/pages/articles/kto-takoi-pirotechnik/gallery/full/kto-takoi-pirotechnik-1.webp"
                                            data-fslightbox="kto-takoi-pirotechnik"
                                        >
                                            <picture>
                                                <source
                                                    srcSet="imgwebp/pages/articles/kto-takoi-pirotechnik/gallery/preview/kto-takoi-pirotechnik-1.webp"
                                                    type="image/webp"
                                                />
                                                <img
                                                    src="#"
                                                    data-src="img/pages/articles/kto-takoi-pirotechnik/gallery/preview/kto-takoi-pirotechnik-1.jpg"
                                                    alt="Кто такой пиротехник 1"
                                                    className="article__gallery-img"
                                                />
                                            </picture>
                                        </a>
                                    </li>
                                    <li className="article__gallery-item">
                                        <a
                                            className="article__gallery-link"
                                            href="imgwebp/pages/articles/kto-takoi-pirotechnik/gallery/full/kto-takoi-pirotechnik-2.webp"
                                            data-fslightbox="kto-takoi-pirotechnik"
                                        >
                                            <picture>
                                                <source
                                                    srcSet="imgwebp/pages/articles/kto-takoi-pirotechnik/gallery/preview/kto-takoi-pirotechnik-2.webp"
                                                    type="image/webp"
                                                />
                                                <img
                                                    src="#"
                                                    data-src="img/pages/articles/kto-takoi-pirotechnik/gallery/preview/kto-takoi-pirotechnik-2.jpg"
                                                    alt="Кто такой пиротехник 2"
                                                    className="article__gallery-img"
                                                />
                                            </picture>
                                        </a>
                                    </li>
                                    <li className="article__gallery-item">
                                        <a
                                            className="article__gallery-link"
                                            href="imgwebp/pages/articles/kto-takoi-pirotechnik/gallery/full/kto-takoi-pirotechnik-3.webp"
                                            data-fslightbox="kto-takoi-pirotechnik"
                                        >
                                            <picture>
                                                <source
                                                    srcSet="imgwebp/pages/articles/kto-takoi-pirotechnik/gallery/preview/kto-takoi-pirotechnik-3.webp"
                                                    type="image/webp"
                                                />
                                                <img
                                                    src="#"
                                                    data-src="img/pages/articles/kto-takoi-pirotechnik/gallery/preview/kto-takoi-pirotechnik-3.jpg"
                                                    alt="Кто такой пиротехник 3"
                                                    className="article__gallery-img"
                                                />
                                            </picture>
                                        </a>
                                    </li>
                                    <li className="article__gallery-item">
                                        <a
                                            className="article__gallery-link"
                                            href="imgwebp/pages/articles/kto-takoi-pirotechnik/gallery/full/kto-takoi-pirotechnik-4.webp"
                                            data-fslightbox="kto-takoi-pirotechnik"
                                        >
                                            <picture>
                                                <source
                                                    srcSet="imgwebp/pages/articles/kto-takoi-pirotechnik/gallery/preview/kto-takoi-pirotechnik-4.webp"
                                                    type="image/webp"
                                                />
                                                <img
                                                    src="#"
                                                    data-src="img/pages/articles/kto-takoi-pirotechnik/gallery/preview/kto-takoi-pirotechnik-4.jpg"
                                                    alt="Кто такой пиротехник 4"
                                                    className="article__gallery-img"
                                                />
                                            </picture>
                                        </a>
                                    </li>
                                </ul>
                                <div
                                    className="article__flex"
                                    itemProp="description"
                                >
                                    <div className="article__info">
                                        <h3 className="article__subtitle">
                                            Мы любим свою
                                            работу, свой
                                            стиль жизни и
                                            делимся этой
                                            любовью с вами.
                                            А лучший подарок
                                            для пиротехника
                                            – ваши эмоции и
                                            восторг
                                        </h3>
                                    </div>
                                    <picture itemProp="image">
                                        <source
                                            srcSet="imgwebp/pages/articles/kto-takoi-pirotechnik/mi-lyubim-svoyu-rabotu.webp"
                                            type="image/webp"
                                        />
                                        <img
                                            src="#"
                                            data-src="img/pages/articles/kto-takoi-pirotechnik/mi-lyubim-svoyu-rabotu.jpg"
                                            alt="Мы любим свою работу"
                                            className="article__img"
                                        />
                                    </picture>
                                </div>
                                <ul className="article__buttons">
                                    <li className="article__buttons-item">
                                        <button className="article__buttons-button article__buttons-button_prev article__buttons-button_inactive">
                                            <span
                                                className="article__buttons-arrow article__buttons-arrow_prev"
                                                data-animate-fill
                                            >
                                                <svg
                                                    className="article__buttons-img"
                                                    width="12"
                                                    height="22"
                                                    viewBox="0 0 12 22"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M11 21L0.999999 11L11 1"
                                                        strokeWidth="1.5"
                                                    />
                                                </svg>{" "}
                                            </span>
                                            <span className="article__buttons-text article__buttons-text_prev">
                                                Предыдущая
                                                статья
                                            </span>
                                        </button>
                                    </li>
                                    <li className="article__buttons-item">
                                        <button
                                            onClick={Next}
                                            className="article__buttons-button article__buttons-button_next"
                                        >
                                            <span className="article__buttons-text article__buttons-text_next">
                                                Следующая
                                                статья
                                            </span>
                                            <span
                                                className="article__buttons-arrow article__buttons-arrow_next"
                                                data-animate-fill
                                            >
                                                <svg
                                                    className="article__buttons-img"
                                                    width="12"
                                                    height="22"
                                                    viewBox="0 0 12 22"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1 1L11 11L0.999997 21"
                                                        strokeWidth="1.5"
                                                    />
                                                </svg>
                                            </span>
                                        </button>
                                    </li>
                                </ul>
                            </article>
                        </li>
                        <li
                            className={
                                punkt === 1
                                    ? "articles__tabs-item articles__tabs-item_active"
                                    : "articles__tabs-item"
                            }
                        >
                            <article
                                className="articles__tabs-article article"
                                itemScope
                                itemType="https://schema.org/Article"
                            >
                                <h2
                                    className="article__title"
                                    itemProp="name"
                                >
                                    А мы сможем запустить
                                    фонтаны сами?
                                </h2>
                                <time
                                    className="article__time"
                                    dateTime="2022-01-16"
                                    itemProp="datePublished"
                                    content="2022-01-16"
                                >
                                    16/01/2022
                                </time>
                                <div
                                    className="article__flex article__flex_intro"
                                    itemProp="description"
                                >
                                    <div className="article__info">
                                        <h3 className="article__subtitle">
                                            Вопрос, который
                                            возникает у
                                            заказчика
                                            достаточно часто
                                        </h3>
                                        <div className="article__descr">
                                            <p>
                                                Отвечаем:
                                                нет. Наши
                                                фонтаны
                                                запускаются
                                                со
                                                специальных
                                                пусковых
                                                установок, с
                                                применением
                                                пульта. Это
                                                позволяет
                                                зажигать все
                                                фонтаны
                                                одновременно
                                                или же по
                                                очереди, по
                                                вашему
                                                желанию.
                                            </p>
                                            <p>
                                                Одно нажатие
                                                на кнопку, и
                                                вы окружены
                                                великолепием
                                                светящихся
                                                искр.
                                            </p>
                                        </div>
                                    </div>
                                    <picture itemProp="image">
                                        <source
                                            srcSet="imgwebp/pages/articles/a-mi-smozhem/vopros.webp"
                                            type="image/webp"
                                        />
                                        <img
                                            src="#"
                                            data-src="img/pages/articles/a-mi-smozhem/vopros.jpg"
                                            alt="Вопрос, который возникает у заказчика достаточно часто"
                                            className="article__img article__img_intro"
                                        />
                                    </picture>
                                </div>
                                <p className="article__warning">
                                    Команда «Салют
                                    Ульяновск» сделает ваш
                                    свадебный вечер ярким и
                                    максимально комфортным
                                    для вас, взяв на себя
                                    все заботы по
                                    организации и запуску.
                                </p>
                                <div
                                    className="article__flex"
                                    itemProp="description"
                                >
                                    <picture itemProp="image">
                                        <source
                                            srcSet="imgwebp/pages/articles/a-mi-smozhem/pirotechnik.webp"
                                            type="image/webp"
                                        />
                                        <img
                                            src="#"
                                            data-src="img/pages/articles/a-mi-smozhem/pirotechnik.jpg"
                                            alt="Наш пиротехник"
                                            className="article__img"
                                        />
                                    </picture>
                                    <div className="article__info">
                                        <div className="article__descr">
                                            <p>
                                                Наш
                                                пиротехник,
                                                учитывая все
                                                ваши
                                                пожелания,
                                                которые
                                                обговариваются
                                                заранее,
                                                приедет на
                                                ваше
                                                мероприятие
                                                в
                                                обговорённое
                                                время и
                                                установит
                                                холодные
                                                фонтаны на
                                                подходящее
                                                место. Вам
                                                не придётся
                                                беспокоиться
                                                об
                                                организации
                                                и запуске,
                                                просто
                                                наслаждайтесь
                                                моментом.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <ul className="article__buttons">
                                    <li className="article__buttons-item">
                                        <button
                                            onClick={Prev}
                                            className="article__buttons-button article__buttons-button_prev"
                                        >
                                            <span
                                                className="article__buttons-arrow article__buttons-arrow_prev"
                                                data-animate-fill
                                            >
                                                <svg
                                                    className="article__buttons-img"
                                                    width="12"
                                                    height="22"
                                                    viewBox="0 0 12 22"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M11 21L0.999999 11L11 1"
                                                        strokeWidth="1.5"
                                                    />
                                                </svg>{" "}
                                            </span>
                                            <span className="article__buttons-text article__buttons-text_prev">
                                                Предыдущая
                                                статья
                                            </span>
                                        </button>
                                    </li>
                                    <li className="article__buttons-item">
                                        <button
                                            onClick={Next}
                                            className="article__buttons-button article__buttons-button_next"
                                        >
                                            <span className="article__buttons-text article__buttons-text_next">
                                                Следующая
                                                статья
                                            </span>
                                            <span
                                                className="article__buttons-arrow article__buttons-arrow_next"
                                                data-animate-fill
                                            >
                                                <svg
                                                    className="article__buttons-img"
                                                    width="12"
                                                    height="22"
                                                    viewBox="0 0 12 22"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1 1L11 11L0.999997 21"
                                                        strokeWidth="1.5"
                                                    />
                                                </svg>
                                            </span>
                                        </button>
                                    </li>
                                </ul>
                            </article>
                        </li>
                        <li
                            className={
                                punkt === 2
                                    ? "articles__tabs-item articles__tabs-item_active"
                                    : "articles__tabs-item"
                            }
                        >
                            <article
                                className="articles__tabs-article article"
                                itemScope
                                itemType="https://schema.org/Article"
                            >
                                <h2
                                    className="article__title"
                                    itemProp="name"
                                >
                                    С фейерверками на “ТЫ”
                                </h2>
                                <time
                                    className="article__time"
                                    dateTime="2022-01-23"
                                    itemProp="datePublished"
                                    content="2022-01-23"
                                >
                                    23/01/2022
                                </time>
                                <div
                                    className="article__flex article__flex_intro"
                                    itemProp="description"
                                >
                                    <div className="article__info">
                                        <h3 className="article__subtitle">
                                            Какой салют
                                            лучше выбрать?
                                        </h3>
                                        <div className="article__descr">
                                            <p>
                                                Нас часто
                                                спрашивают
                                                какой салют
                                                лучше
                                                выбрать и
                                                просят
                                                показать
                                                видеозаписи
                                                для
                                                наглядности.
                                                Мы с
                                                удовольствием
                                                делимся
                                                подборками,
                                                но видео не
                                                отражает
                                                всех эмоций,
                                                мощности
                                                зарядов и
                                                эффектов,
                                                как вживую.
                                            </p>
                                            <p>
                                                Поэтому мы
                                                всегда
                                                просим
                                                довериться
                                                нам в выборе
                                                красивого
                                                фейерверка
                                                для вас.
                                                Достаточно
                                                сказать
                                                бюджет, в
                                                которой
                                                нужно
                                                уложиться,
                                                место
                                                проведения и
                                                ваши
                                                пожелания к
                                                фейерверку.
                                            </p>
                                        </div>
                                    </div>
                                    <picture itemProp="image">
                                        <source
                                            srcSet="imgwebp/pages/articles/s-feerwerkami-na-ti/kakoi-salut.webp"
                                            type="image/webp"
                                        />
                                        <img
                                            src="#"
                                            data-src="img/pages/articles/s-feerwerkami-na-ti/kakoi-salut.jpg"
                                            alt="Какой салют лучше выбрать?"
                                            className="article__img article__img_intro"
                                        />
                                    </picture>
                                </div>
                                <p className="article__warning">
                                    Просто доверьтесь нам и
                                    наслаждайтесь красочным
                                    фейерверком!
                                </p>
                                <div
                                    className="article__flex"
                                    itemProp="description"
                                >
                                    <picture itemProp="image">
                                        <source
                                            srcSet="imgwebp/pages/articles/s-feerwerkami-na-ti/za-15-let.webp"
                                            type="image/webp"
                                        />
                                        <img
                                            src="#"
                                            data-src="img/pages/articles/s-feerwerkami-na-ti/za-15-let.jpg"
                                            alt="За 15 лет мы создали много красоты"
                                            className="article__img"
                                        />
                                    </picture>
                                    <div className="article__info">
                                        <h3 className="article__subtitle">
                                            За 15 лет мы
                                            создали много
                                            красоты, с
                                            фейерверками на
                                            «ты»
                                        </h3>
                                        <div className="article__descr">
                                            <ul>
                                                <li>
                                                    Не
                                                    привязываемся
                                                    к одной
                                                    коробке
                                                    салютов,
                                                    комбинируем
                                                    несколько
                                                    для
                                                    разнообразия
                                                    и
                                                    насыщенности.
                                                </li>
                                                <li>
                                                    Добавляем
                                                    профессиональные
                                                    заряды и
                                                    другие
                                                    интересные
                                                    пиротехнические
                                                    изделия
                                                    для
                                                    оригинальности
                                                    и
                                                    «вау-эффекта».
                                                </li>
                                                <li>
                                                    Выезжаем
                                                    на место
                                                    торжества
                                                    и
                                                    взрываем
                                                    салют с
                                                    эффектом
                                                    неожиданности.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <ul className="article__gallery">
                                    <li className="article__gallery-item">
                                        <a
                                            className="article__gallery-link"
                                            href="imgwebp/pages/articles/s-feerwerkami-na-ti/gallery/full/s-feerwerkami-na-ti-1.webp"
                                            data-fslightbox="s-feerwerkami-na-ti"
                                        >
                                            <picture>
                                                <source
                                                    srcSet="imgwebp/pages/articles/s-feerwerkami-na-ti/gallery/preview/s-feerwerkami-na-ti-1.webp"
                                                    type="image/webp"
                                                />
                                                <img
                                                    src="#"
                                                    data-src="img/pages/articles/s-feerwerkami-na-ti/gallery/preview/s-feerwerkami-na-ti-1.jpg"
                                                    alt="С фейерверками на “ТЫ” 1"
                                                    className="article__gallery-img"
                                                />
                                            </picture>
                                        </a>
                                    </li>
                                    <li className="article__gallery-item">
                                        <a
                                            className="article__gallery-link"
                                            href="imgwebp/pages/articles/s-feerwerkami-na-ti/gallery/full/s-feerwerkami-na-ti-2.webp"
                                            data-fslightbox="s-feerwerkami-na-ti"
                                        >
                                            <picture>
                                                <source
                                                    srcSet="imgwebp/pages/articles/s-feerwerkami-na-ti/gallery/preview/s-feerwerkami-na-ti-2.webp"
                                                    type="image/webp"
                                                />
                                                <img
                                                    src="#"
                                                    data-src="img/pages/articles/s-feerwerkami-na-ti/gallery/preview/s-feerwerkami-na-ti-2.jpg"
                                                    alt="С фейерверками на “ТЫ” 2"
                                                    className="article__gallery-img"
                                                />
                                            </picture>
                                        </a>
                                    </li>
                                    <li className="article__gallery-item">
                                        <a
                                            className="article__gallery-link"
                                            href="imgwebp/pages/articles/s-feerwerkami-na-ti/gallery/full/s-feerwerkami-na-ti-3.webp"
                                            data-fslightbox="s-feerwerkami-na-ti"
                                        >
                                            <picture>
                                                <source
                                                    srcSet="imgwebp/pages/articles/s-feerwerkami-na-ti/gallery/preview/s-feerwerkami-na-ti-3.webp"
                                                    type="image/webp"
                                                />
                                                <img
                                                    src="#"
                                                    data-src="img/pages/articles/s-feerwerkami-na-ti/gallery/preview/s-feerwerkami-na-ti-3.jpg"
                                                    alt="С фейерверками на “ТЫ” 3"
                                                    className="article__gallery-img"
                                                />
                                            </picture>
                                        </a>
                                    </li>
                                    <li className="article__gallery-item">
                                        <a
                                            className="article__gallery-link"
                                            href="imgwebp/pages/articles/s-feerwerkami-na-ti/gallery/full/s-feerwerkami-na-ti-4.webp"
                                            data-fslightbox="s-feerwerkami-na-ti"
                                        >
                                            <picture>
                                                <source
                                                    srcSet="imgwebp/pages/articles/s-feerwerkami-na-ti/gallery/preview/s-feerwerkami-na-ti-4.webp"
                                                    type="image/webp"
                                                />
                                                <img
                                                    src="#"
                                                    data-src="img/pages/articles/s-feerwerkami-na-ti/gallery/preview/s-feerwerkami-na-ti-4.jpg"
                                                    alt="С фейерверками на “ТЫ” 4"
                                                    className="article__gallery-img"
                                                />
                                            </picture>
                                        </a>
                                    </li>
                                </ul>
                                <div
                                    className="article__flex"
                                    itemProp="description"
                                >
                                    <div className="article__info">
                                        <h3 className="article__subtitle">
                                            Стоимость
                                            фейерверка
                                            зависит от
                                            количества
                                            залпов и
                                            калибра.
                                        </h3>
                                        <div className="article__descr">
                                            <p>
                                                Чем больше
                                                калибр – тем
                                                выше
                                                взлетает,
                                                мощнее
                                                взрывается
                                                (больше
                                                радиус
                                                взрыва) и
                                                дольше
                                                светиться в
                                                небе. Чем
                                                больше
                                                зарядов –
                                                тем дольше и
                                                насыщеннее.
                                            </p>
                                        </div>
                                    </div>
                                    <picture itemProp="image">
                                        <source
                                            srcSet="imgwebp/pages/articles/s-feerwerkami-na-ti/stoimost.webp"
                                            type="image/webp"
                                        />
                                        <img
                                            src="#"
                                            data-src="img/pages/articles/s-feerwerkami-na-ti/stoimost.jpg"
                                            alt="Стоимость фейерверка зависит от количества залпов и калибра."
                                            className="article__img"
                                        />
                                    </picture>
                                </div>
                                <ul className="article__buttons">
                                    <li className="article__buttons-item">
                                        <button
                                            onClick={Prev}
                                            className="article__buttons-button article__buttons-button_prev"
                                        >
                                            <span
                                                className="article__buttons-arrow article__buttons-arrow_prev"
                                                data-animate-fill
                                            >
                                                <svg
                                                    className="article__buttons-img"
                                                    width="12"
                                                    height="22"
                                                    viewBox="0 0 12 22"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M11 21L0.999999 11L11 1"
                                                        strokeWidth="1.5"
                                                    />
                                                </svg>{" "}
                                            </span>
                                            <span className="article__buttons-text article__buttons-text_prev">
                                                Предыдущая
                                                статья
                                            </span>
                                        </button>
                                    </li>
                                    <li className="article__buttons-item">
                                        <button
                                            onClick={Next}
                                            className="article__buttons-button article__buttons-button_next"
                                        >
                                            <span className="article__buttons-text article__buttons-text_next">
                                                Следующая
                                                статья
                                            </span>
                                            <span
                                                className="article__buttons-arrow article__buttons-arrow_next"
                                                data-animate-fill
                                            >
                                                <svg
                                                    className="article__buttons-img"
                                                    width="12"
                                                    height="22"
                                                    viewBox="0 0 12 22"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1 1L11 11L0.999997 21"
                                                        strokeWidth="1.5"
                                                    />
                                                </svg>
                                            </span>
                                        </button>
                                    </li>
                                </ul>
                            </article>
                        </li>
                        <li
                            className={
                                punkt === 3
                                    ? "articles__tabs-item articles__tabs-item_active"
                                    : "articles__tabs-item"
                            }
                        >
                            <article
                                className="articles__tabs-article article"
                                itemScope
                                itemType="https://schema.org/Article"
                            >
                                <h2
                                    className="article__title"
                                    itemProp="name"
                                >
                                    Если запретили фейерферк
                                    на территории банкетного
                                    зала
                                </h2>
                                <time
                                    className="article__time"
                                    dateTime="2022-02-07"
                                    itemProp="datePublished"
                                    content="2022-02-07"
                                >
                                    07/02/2022
                                </time>
                                <div
                                    className="article__flex article__flex_intro"
                                    itemProp="description"
                                >
                                    <div className="article__info">
                                        <h3 className="article__subtitle">
                                            В этом случае,
                                            мы поможем вам
                                            организовать
                                            эффектное
                                            наземное
                                            пиротехническое
                                            шоу.
                                        </h3>
                                        <div className="article__descr">
                                            <p>
                                                Сценическая
                                                пиротехника,
                                                которая
                                                применяется
                                                в наземном
                                                шоу, имеет
                                                небольшой
                                                радиус
                                                опасной
                                                зоны, это
                                                позволяет
                                                установить
                                                её
                                                практически
                                                на любой
                                                площадке
                                                банкетного
                                                зала.
                                            </p>
                                            <p>
                                                Один из
                                                самых
                                                популярных
                                                вариантов
                                                наземного
                                                шоу –
                                                холодные
                                                фонтаны.
                                                Только
                                                представьте
                                                этот
                                                прекрасный
                                                момент, где
                                                вы – в
                                                главной роли
                                                и в самом
                                                центре
                                                россыпи
                                                сверкающих
                                                искр.
                                            </p>
                                        </div>
                                    </div>
                                    <picture itemProp="image">
                                        <source
                                            srcSet="imgwebp/pages/articles/esli-zapretili/organizatsiya.webp"
                                            type="image/webp"
                                        />
                                        <img
                                            src="#"
                                            data-src="img/pages/articles/esli-zapretili/organizatsiya.jpg"
                                            alt="Организация"
                                            className="article__img article__img_intro"
                                        />
                                    </picture>
                                </div>
                                <p className="article__warning">
                                    Хотите устроить
                                    настоящее шоу со своим
                                    участием? Мы поможем вам
                                    оформить площадку
                                    действительно эффектно!
                                </p>
                                <div
                                    className="article__flex"
                                    itemProp="description"
                                >
                                    <picture itemProp="image">
                                        <source
                                            srcSet="imgwebp/pages/articles/esli-zapretili/ustanovka.webp"
                                            type="image/webp"
                                        />
                                        <img
                                            src="#"
                                            data-src="img/pages/articles/esli-zapretili/ustanovka.jpg"
                                            alt="Установка"
                                            className="article__img"
                                        />
                                    </picture>
                                    <div className="article__info">
                                        <h3 className="article__subtitle">
                                            Приезжаем,
                                            безопасно
                                            устанавливаем
                                            холодные фонтаны
                                            и управляем
                                            поджигом
                                            фонтанов с
                                            помощью
                                            дистанционных
                                            пультов.
                                        </h3>
                                        <div className="article__descr">
                                            <ul>
                                                <li>
                                                    Установка
                                                    холодных
                                                    фонтанов
                                                    может
                                                    быть
                                                    разнообразна:
                                                    классическая
                                                    дорожка,
                                                    в одну
                                                    линию,
                                                    полукругом,
                                                    квадратом,
                                                    всё
                                                    зависит
                                                    от ваших
                                                    желаний.
                                                </li>
                                                <li>
                                                    Вам не
                                                    нужно
                                                    рисковать
                                                    и
                                                    переживать
                                                    за то,
                                                    как всё
                                                    пройдёт.
                                                    По
                                                    вашему
                                                    желанию,
                                                    фонтаны
                                                    могут
                                                    зажечься
                                                    все
                                                    сразу в
                                                    определённый
                                                    музыкальный
                                                    момент,
                                                    или
                                                    пошагово
                                                    – пока
                                                    вы
                                                    проходите
                                                    сквозь
                                                    дорожку.
                                                </li>
                                                <li>
                                                    Стандартное
                                                    время
                                                    работы
                                                    холодных
                                                    фонтанов:{" "}
                                                    <strong>
                                                        30-60
                                                        секунд
                                                    </strong>{" "}
                                                    – этого
                                                    времени
                                                    достаточно
                                                    для
                                                    съёмки
                                                    отличных
                                                    кадров.
                                                </li>
                                                <li>
                                                    Высота
                                                    фонтанов
                                                    –{" "}
                                                    <strong>
                                                        3
                                                        или
                                                        6
                                                        метров
                                                    </strong>
                                                    . Цвет
                                                    искр –
                                                    серебро
                                                    или
                                                    золото.
                                                </li>
                                                <li>
                                                    Фонтаны
                                                    работают{" "}
                                                    <strong>
                                                        в
                                                        любую
                                                        погоду
                                                    </strong>{" "}
                                                    – дождь
                                                    не
                                                    страшен.
                                                    А ещё,
                                                    как они
                                                    горят –
                                                    видно{" "}
                                                    <strong>
                                                        в
                                                        любое
                                                        время
                                                        суток
                                                    </strong>
                                                    .
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <ul className="article__gallery">
                                    <li className="article__gallery-item">
                                        <a
                                            className="article__gallery-link"
                                            href="imgwebp/pages/articles/esli-zapretili/gallery/full/esli-zapretili-1.webp"
                                            data-fslightbox="esli-zapretili"
                                        >
                                            <picture>
                                                <source
                                                    srcSet="imgwebp/pages/articles/esli-zapretili/gallery/preview/esli-zapretili-1.webp"
                                                    type="image/webp"
                                                />
                                                <img
                                                    src="#"
                                                    data-src="img/pages/articles/esli-zapretili/gallery/preview/esli-zapretili-1.jpg"
                                                    alt="Если запретили фейерферк на территории банкетного зала 1"
                                                    className="article__gallery-img"
                                                />
                                            </picture>
                                        </a>
                                    </li>
                                    <li className="article__gallery-item">
                                        <a
                                            className="article__gallery-link"
                                            href="imgwebp/pages/articles/esli-zapretili/gallery/full/esli-zapretili-2.webp"
                                            data-fslightbox="esli-zapretili"
                                        >
                                            <picture>
                                                <source
                                                    srcSet="imgwebp/pages/articles/esli-zapretili/gallery/preview/esli-zapretili-2.webp"
                                                    type="image/webp"
                                                />
                                                <img
                                                    src="#"
                                                    data-src="img/pages/articles/esli-zapretili/gallery/preview/esli-zapretili-2.jpg"
                                                    alt="Если запретили фейерферк на территории банкетного зала 2"
                                                    className="article__gallery-img"
                                                />
                                            </picture>
                                        </a>
                                    </li>
                                    <li className="article__gallery-item">
                                        <a
                                            className="article__gallery-link"
                                            href="imgwebp/pages/articles/esli-zapretili/gallery/full/esli-zapretili-3.webp"
                                            data-fslightbox="esli-zapretili"
                                        >
                                            <picture>
                                                <source
                                                    srcSet="imgwebp/pages/articles/esli-zapretili/gallery/preview/esli-zapretili-3.webp"
                                                    type="image/webp"
                                                />
                                                <img
                                                    src="#"
                                                    data-src="img/pages/articles/esli-zapretili/gallery/preview/esli-zapretili-3.jpg"
                                                    alt="Если запретили фейерферк на территории банкетного зала 3"
                                                    className="article__gallery-img"
                                                />
                                            </picture>
                                        </a>
                                    </li>
                                    <li className="article__gallery-item">
                                        <a
                                            className="article__gallery-link"
                                            href="imgwebp/pages/articles/esli-zapretili/gallery/full/esli-zapretili-4.webp"
                                            data-fslightbox="esli-zapretili"
                                        >
                                            <picture>
                                                <source
                                                    srcSet="imgwebp/pages/articles/esli-zapretili/gallery/preview/esli-zapretili-4.webp"
                                                    type="image/webp"
                                                />
                                                <img
                                                    src="#"
                                                    data-src="img/pages/articles/esli-zapretili/gallery/preview/esli-zapretili-4.jpg"
                                                    alt="Если запретили фейерферк на территории банкетного зала 4"
                                                    className="article__gallery-img"
                                                />
                                            </picture>
                                        </a>
                                    </li>
                                </ul>
                                <div
                                    className="article__flex"
                                    itemProp="description"
                                >
                                    <div className="article__info">
                                        <h3 className="article__subtitle">
                                            Кроме холодных
                                            фонтанов, есть
                                            ещё немало
                                            эффектных
                                            вариантов.
                                        </h3>
                                        <div className="article__descr">
                                            <p>
                                                Это
                                                пиротехнические
                                                вертушки,
                                                горящие
                                                буквы/цифры
                                                и огненное
                                                сердце,
                                                которое
                                                молодожёны
                                                зажигают
                                                специальным
                                                факелом. Это
                                                трогательная
                                                и
                                                романтичная
                                                церемония,
                                                символизирующая
                                                неугасающий
                                                огонь любви.
                                            </p>
                                        </div>
                                    </div>
                                    <picture itemProp="image">
                                        <source
                                            srcSet="imgwebp/pages/articles/esli-zapretili/raznoobrazie.webp"
                                            type="image/webp"
                                        />
                                        <img
                                            src="#"
                                            data-src="img/pages/articles/esli-zapretili/raznoobrazie.jpg"
                                            alt="Разнообразие"
                                            className="article__img"
                                        />
                                    </picture>
                                </div>
                                <ul className="article__buttons">
                                    <li className="article__buttons-item">
                                        <button
                                            onClick={Prev}
                                            className="article__buttons-button article__buttons-button_prev"
                                        >
                                            <span
                                                className="article__buttons-arrow article__buttons-arrow_prev"
                                                data-animate-fill
                                            >
                                                <svg
                                                    className="article__buttons-img"
                                                    width="12"
                                                    height="22"
                                                    viewBox="0 0 12 22"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M11 21L0.999999 11L11 1"
                                                        strokeWidth="1.5"
                                                    />
                                                </svg>{" "}
                                            </span>
                                            <span className="article__buttons-text article__buttons-text_prev">
                                                Предыдущая
                                                статья
                                            </span>
                                        </button>
                                    </li>
                                    <li className="article__buttons-item">
                                        <button
                                            onClick={Next}
                                            className="article__buttons-button article__buttons-button_next"
                                        >
                                            <span className="article__buttons-text article__buttons-text_next">
                                                Следующая
                                                статья
                                            </span>
                                            <span
                                                className="article__buttons-arrow article__buttons-arrow_next"
                                                data-animate-fill
                                            >
                                                <svg
                                                    className="article__buttons-img"
                                                    width="12"
                                                    height="22"
                                                    viewBox="0 0 12 22"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1 1L11 11L0.999997 21"
                                                        strokeWidth="1.5"
                                                    />
                                                </svg>
                                            </span>
                                        </button>
                                    </li>
                                </ul>
                            </article>
                        </li>
                        <li
                            className={
                                punkt === 4
                                    ? "articles__tabs-item articles__tabs-item_active"
                                    : "articles__tabs-item"
                            }
                        >
                            <article
                                className="articles__tabs-article article"
                                itemScope
                                itemType="https://schema.org/Article"
                            >
                                <h2
                                    className="article__title"
                                    itemProp="name"
                                >
                                    Бенгальские огни на
                                    свадьбе
                                </h2>
                                <time
                                    className="article__time"
                                    dateTime="2022-03-10"
                                    itemProp="datePublished"
                                    content="2022-03-10"
                                >
                                    10/03/2022
                                </time>
                                <div
                                    className="article__flex article__flex_intro"
                                    itemProp="description"
                                >
                                    <div className="article__info">
                                        <h3 className="article__subtitle">
                                            Что нужно знать
                                            заранее
                                        </h3>
                                        <div className="article__descr">
                                            <p>
                                                <strong>
                                                    Локация.
                                                </strong>{" "}
                                                При
                                                бронировании
                                                банкетного
                                                зала,
                                                уточните -
                                                разрешено ли
                                                у них
                                                использовать
                                                бенгальские
                                                огни.
                                                Обычно, огни
                                                предлагают
                                                использовать
                                                только на
                                                открытом
                                                воздухе или
                                                в специально
                                                отведенном
                                                месте, где
                                                много
                                                свободного
                                                пространства.
                                            </p>
                                            <p>
                                                <strong>
                                                    Подготовка.
                                                </strong>{" "}
                                                Заранее
                                                предупредите
                                                фотографа и
                                                видеографа о
                                                том, как и
                                                когда вы
                                                хотите
                                                использовать
                                                бенгальские
                                                огни.
                                                Попросите
                                                свадебного
                                                координатора
                                                помочь
                                                ускорить
                                                процесс
                                                зажигания
                                                бенгальских
                                                огней.
                                                Спички,
                                                конечно,
                                                работают, но
                                                могут быть
                                                неудобными и
                                                ненадежными,
                                                поэтому
                                                зажигалки в
                                                форме факела
                                                или с
                                                длинным
                                                наконечником
                                                лучше (в
                                                идеале,
                                                используйте
                                                одну на два
                                                бенгальских
                                                огня).
                                                Другой
                                                вариант -
                                                иметь рядом
                                                несколько
                                                стационарных
                                                свечей-колонн,
                                                которые
                                                являются
                                                эффективным
                                                способом
                                                одновременно
                                                зажечь свои
                                                бенгальские
                                                огни от их
                                                пламени для
                                                нескольких
                                                гостей.
                                            </p>
                                        </div>
                                    </div>
                                    <picture itemProp="image">
                                        <source
                                            srcSet="imgwebp/pages/articles/bengalskie-ogni-na-svadbe/chto-nuzhno-znat-zaranee.webp"
                                            type="image/webp"
                                        />
                                        <img
                                            src="#"
                                            data-src="img/pages/articles/bengalskie-ogni-na-svadbe/chto-nuzhno-znat-zaranee.jpg"
                                            alt="Что нужно знать заранее"
                                            className="article__img article__img_intro"
                                        />
                                    </picture>
                                </div>
                                <p className="article__warning">
                                    В целях безопасности
                                    бенгальские огни
                                    рекомендуется поджигать
                                    на открытом воздухе,
                                    подальше от предметов,
                                    которые могут загореться
                                    от попадания искр.
                                </p>
                                <div
                                    className="article__flex"
                                    itemProp="description"
                                >
                                    <picture itemProp="image">
                                        <source
                                            srcSet="imgwebp/pages/articles/bengalskie-ogni-na-svadbe/bezopansost-tipi-ognei.webp"
                                            type="image/webp"
                                        />
                                        <img
                                            src="#"
                                            data-src="img/pages/articles/bengalskie-ogni-na-svadbe/bezopansost-tipi-ognei.jpg"
                                            alt="Безопасность. Типы огней"
                                            className="article__img"
                                        />
                                    </picture>
                                    <div className="article__info">
                                        <div className="article__descr">
                                            <p>
                                                <strong>
                                                    Безопасность.
                                                </strong>{" "}
                                                Планируя,
                                                где будут
                                                зажигаться
                                                ваши
                                                бенгальские
                                                огни,
                                                убедитесь,
                                                что у вас
                                                достаточно
                                                места.
                                                Например,
                                                для живого
                                                коридора
                                                запланируйте
                                                ширину,
                                                достаточную
                                                для двух
                                                шеренг людей
                                                с
                                                бенгальскими
                                                огнями, и
                                                чтобы
                                                осталось
                                                место для
                                                безопасного
                                                прохода
                                                молодоженов.
                                            </p>
                                            <p>
                                                <strong>
                                                    Типы
                                                    огней.
                                                </strong>{" "}
                                                Для
                                                красивого
                                                огненного
                                                коридора
                                                выбирайте
                                                самые
                                                длинные
                                                бенгальски
                                                огни с
                                                наименьшей
                                                степенью
                                                горения –
                                                так фотограф
                                                успеет
                                                сделать
                                                много
                                                красивых
                                                кадров, а
                                                видеограф
                                                снимет
                                                эффектное
                                                видео.
                                                Необходимое
                                                количество
                                                таких
                                                бенгальских
                                                огней можно
                                                заказать
                                                заранее.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <ul className="article__gallery">
                                    <li className="article__gallery-item">
                                        <a
                                            className="article__gallery-link"
                                            href="imgwebp/pages/articles/bengalskie-ogni-na-svadbe/gallery/full/bengalskie-ogni-na-svadbe-1.webp"
                                            data-fslightbox="bengalskie-ogni-na-svadbe"
                                        >
                                            <picture>
                                                <source
                                                    srcSet="imgwebp/pages/articles/bengalskie-ogni-na-svadbe/gallery/preview/bengalskie-ogni-na-svadbe-1.webp"
                                                    type="image/webp"
                                                />
                                                <img
                                                    src="#"
                                                    data-src="img/pages/articles/bengalskie-ogni-na-svadbe/gallery/preview/bengalskie-ogni-na-svadbe-1.jpg"
                                                    alt="Бенгальские огни на свадьбе 1"
                                                    className="article__gallery-img"
                                                />
                                            </picture>
                                        </a>
                                    </li>
                                    <li className="article__gallery-item">
                                        <a
                                            className="article__gallery-link"
                                            href="imgwebp/pages/articles/bengalskie-ogni-na-svadbe/gallery/full/bengalskie-ogni-na-svadbe-2.webp"
                                            data-fslightbox="bengalskie-ogni-na-svadbe"
                                        >
                                            <picture>
                                                <source
                                                    srcSet="imgwebp/pages/articles/bengalskie-ogni-na-svadbe/gallery/preview/bengalskie-ogni-na-svadbe-2.webp"
                                                    type="image/webp"
                                                />
                                                <img
                                                    src="#"
                                                    data-src="img/pages/articles/bengalskie-ogni-na-svadbe/gallery/preview/bengalskie-ogni-na-svadbe-2.jpg"
                                                    alt="Бенгальские огни на свадьбе 2"
                                                    className="article__gallery-img"
                                                />
                                            </picture>
                                        </a>
                                    </li>
                                    <li className="article__gallery-item">
                                        <a
                                            className="article__gallery-link"
                                            href="imgwebp/pages/articles/bengalskie-ogni-na-svadbe/gallery/full/bengalskie-ogni-na-svadbe-3.webp"
                                            data-fslightbox="bengalskie-ogni-na-svadbe"
                                        >
                                            <picture>
                                                <source
                                                    srcSet="imgwebp/pages/articles/bengalskie-ogni-na-svadbe/gallery/preview/bengalskie-ogni-na-svadbe-3.webp"
                                                    type="image/webp"
                                                />
                                                <img
                                                    src="#"
                                                    data-src="img/pages/articles/bengalskie-ogni-na-svadbe/gallery/preview/bengalskie-ogni-na-svadbe-3.jpg"
                                                    alt="Бенгальские огни на свадьбе 3"
                                                    className="article__gallery-img"
                                                />
                                            </picture>
                                        </a>
                                    </li>
                                    <li className="article__gallery-item">
                                        <a
                                            className="article__gallery-link"
                                            href="imgwebp/pages/articles/bengalskie-ogni-na-svadbe/gallery/full/bengalskie-ogni-na-svadbe-4.webp"
                                            data-fslightbox="bengalskie-ogni-na-svadbe"
                                        >
                                            <picture>
                                                <source
                                                    srcSet="imgwebp/pages/articles/bengalskie-ogni-na-svadbe/gallery/preview/bengalskie-ogni-na-svadbe-4.webp"
                                                    type="image/webp"
                                                />
                                                <img
                                                    src="#"
                                                    data-src="img/pages/articles/bengalskie-ogni-na-svadbe/gallery/preview/bengalskie-ogni-na-svadbe-4.jpg"
                                                    alt="Бенгальские огни на свадьбе 4"
                                                    className="article__gallery-img"
                                                />
                                            </picture>
                                        </a>
                                    </li>
                                </ul>
                                <div
                                    className="article__flex"
                                    itemProp="description"
                                >
                                    <div className="article__info">
                                        <h3 className="article__subtitle">
                                            Тот самый момент
                                        </h3>
                                        <div className="article__descr">
                                            <p>
                                                Когда вы
                                                будете
                                                проходить
                                                через
                                                коридор
                                                бенгальских
                                                огней – не
                                                спешите и
                                                <strong>
                                                    наслаждайтесь
                                                </strong>{" "}
                                                этим
                                                моментом.
                                                Медленное
                                                движение
                                                позволит
                                                фотографу и
                                                видеографу
                                                легче
                                                сфокусироваться,
                                                да и вы
                                                получите
                                                гораздо
                                                больше
                                                удовольствия
                                                от этого
                                                волшебного
                                                момента!
                                            </p>
                                            <p>
                                                А если вам
                                                нужны{" "}
                                                <strong>
                                                    динамичные
                                                    кадры
                                                </strong>
                                                , но вы
                                                хотите
                                                избежать
                                                спешки,
                                                подумайте о
                                                том, чтобы
                                                пройти через
                                                тоннель в
                                                танце или
                                                остановиться
                                                посередине и
                                                в конце,
                                                чтобы
                                                поднять
                                                невесту на
                                                руки,
                                                обняться и
                                                поцеловать
                                                друг друга!
                                            </p>
                                        </div>
                                    </div>
                                    <picture itemProp="image">
                                        <source
                                            srcSet="imgwebp/pages/articles/bengalskie-ogni-na-svadbe/tot-samii-moment.webp"
                                            type="image/webp"
                                        />
                                        <img
                                            src="#"
                                            data-src="img/pages/articles/bengalskie-ogni-na-svadbe/tot-samii-moment.jpg"
                                            alt="Тот самый момент"
                                            className="article__img"
                                        />
                                    </picture>
                                </div>
                                <ul className="article__buttons">
                                    <li className="article__buttons-item">
                                        <button
                                            onClick={Prev}
                                            className="article__buttons-button article__buttons-button_prev"
                                        >
                                            <span
                                                className="article__buttons-arrow article__buttons-arrow_prev"
                                                data-animate-fill
                                            >
                                                <svg
                                                    className="article__buttons-img"
                                                    width="12"
                                                    height="22"
                                                    viewBox="0 0 12 22"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M11 21L0.999999 11L11 1"
                                                        strokeWidth="1.5"
                                                    />
                                                </svg>{" "}
                                            </span>
                                            <span className="article__buttons-text article__buttons-text_prev">
                                                Предыдущая
                                                статья
                                            </span>
                                        </button>
                                    </li>
                                    <li className="article__buttons-item">
                                        <button
                                            onClick={Next}
                                            className="article__buttons-button article__buttons-button_next"
                                        >
                                            <span className="article__buttons-text article__buttons-text_next">
                                                Следующая
                                                статья
                                            </span>
                                            <span
                                                className="article__buttons-arrow article__buttons-arrow_next"
                                                data-animate-fill
                                            >
                                                <svg
                                                    className="article__buttons-img"
                                                    width="12"
                                                    height="22"
                                                    viewBox="0 0 12 22"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1 1L11 11L0.999997 21"
                                                        strokeWidth="1.5"
                                                    />
                                                </svg>
                                            </span>
                                        </button>
                                    </li>
                                </ul>
                            </article>
                        </li>
                        <li
                            className={
                                punkt === 5
                                    ? "articles__tabs-item articles__tabs-item_active"
                                    : "articles__tabs-item"
                            }
                        >
                            <article
                                className="articles__tabs-article article"
                                itemScope
                                itemType="https://schema.org/Article"
                            >
                                <h2
                                    className="article__title"
                                    itemProp="name"
                                >
                                    Плюсы и минусы цветного
                                    дыма на фотосессии
                                </h2>
                                <time
                                    className="article__time"
                                    dateTime="2022-03-24"
                                    itemProp="datePublished"
                                    content="2022-03-24"
                                >
                                    24/03/2022
                                </time>
                                <div
                                    className="article__flex article__flex_intro"
                                    itemProp="description"
                                >
                                    <div className="article__info">
                                        <h3 className="article__subtitle">
                                            Плюсы
                                        </h3>
                                        <div className="article__descr">
                                            <p>
                                                <strong>
                                                    Безопасность.
                                                </strong>{" "}
                                                Цветной дым
                                                относится к
                                                первому
                                                классу
                                                пиротехнических
                                                изделий. Он
                                                безопасен
                                                для
                                                здоровья.
                                            </p>
                                            <p>
                                                <strong>
                                                    Широкая
                                                    цветовая
                                                    палитра.
                                                </strong>{" "}
                                                Можно
                                                выбрать
                                                яркий дым в
                                                тон
                                                торжества,
                                                тематики или
                                                придать
                                                сочность
                                                оттенкам
                                                природы.
                                                Можно
                                                комбинировать
                                                цвета,
                                                создавая
                                                красивые
                                                эффекты.
                                            </p>
                                            <p>
                                                <strong>
                                                    Без
                                                    следов
                                                    на
                                                    одежде и
                                                    мусора.
                                                </strong>{" "}
                                                Цветной дым
                                                не пачкает
                                                одежду и
                                                волосы.
                                                После его
                                                использования
                                                не
                                                потребуется
                                                уборка.
                                            </p>
                                            <p>
                                                <strong>
                                                    Для
                                                    дневной
                                                    и ночной
                                                    фотосессии.
                                                </strong>{" "}
                                                Цветной дым
                                                красиво
                                                смотрится не
                                                только при
                                                дневном
                                                свете. В
                                                темноте он
                                                производит
                                                не менее
                                                яркое
                                                впечатление
                                                и эффектно
                                                преображает
                                                фото.
                                            </p>
                                            <p>
                                                <strong>
                                                    Яркие
                                                    впечатления
                                                    для
                                                    всех.
                                                </strong>{" "}
                                                Яркий дым
                                                поднимает
                                                настроение
                                                даже в самую
                                                унылую
                                                погоду.
                                            </p>
                                            <p>
                                                <strong>
                                                    Коррекция
                                                    места
                                                    фотосессии.
                                                </strong>{" "}
                                                Разноцветный
                                                дым создаёт
                                                невероятный
                                                сказочный
                                                антураж. Это
                                                впечатляющее
                                                зрелище,
                                                которое
                                                помогает
                                                скрыть
                                                «нефотогеничный
                                                пейзаж».
                                            </p>
                                        </div>
                                    </div>
                                    <picture itemProp="image">
                                        <source
                                            srcSet="imgwebp/pages/articles/plusi-i-minusi-tsvetnogo-dima/plusi.webp"
                                            type="image/webp"
                                        />
                                        <img
                                            src="#"
                                            data-src="img/pages/articles/plusi-i-minusi-tsvetnogo-dima/plusi.jpg"
                                            alt="Плюсы"
                                            className="article__img article__img_intro"
                                        />
                                    </picture>
                                </div>
                                <p className="article__warning">
                                    Разноцветный дым создаёт
                                    невероятный сказочный
                                    антураж. Это
                                    впечатляющее зрелище,
                                    которое помогает скрыть
                                    «нефотогеничный пейзаж».
                                </p>
                                <div
                                    className="article__flex"
                                    itemProp="description"
                                >
                                    <picture itemProp="image">
                                        <source
                                            srcSet="imgwebp/pages/articles/plusi-i-minusi-tsvetnogo-dima/minusi.webp"
                                            type="image/webp"
                                        />
                                        <img
                                            src="#"
                                            data-src="img/pages/articles/plusi-i-minusi-tsvetnogo-dima/minusi.jpg"
                                            alt="Минусы"
                                            className="article__img"
                                        />
                                    </picture>
                                    <div className="article__info">
                                        <h3 className="article__subtitle">
                                            Минусы
                                        </h3>
                                        <div className="article__descr">
                                            <p>
                                                <strong>
                                                    Неприятный
                                                    запах.
                                                </strong>{" "}
                                                Для
                                                большинства
                                                шашек
                                                типичен
                                                отчетливый
                                                едкий запах.
                                                Поэтому
                                                лучше
                                                отказаться
                                                от идеи
                                                съемки в
                                                абсолютно
                                                закрытых
                                                пространствах.
                                            </p>
                                            <p>
                                                <strong>
                                                    Проверка
                                                    профессионализма
                                                    фотографа.
                                                </strong>{" "}
                                                Действие
                                                цветного
                                                дыма длится
                                                от 30 до
                                                60сек.
                                                Казалось бы
                                                –немного, но
                                                этого
                                                достаточно,
                                                чтобы
                                                опытный
                                                фотограф
                                                сделал
                                                несколько
                                                необычных
                                                фотокадров,
                                                уточните у
                                                фотографа,
                                                есть ли у
                                                него опыт
                                                работы с
                                                таким
                                                эффектом.
                                                Вместе с
                                                фотографом
                                                придумайтесь
                                                сценарий
                                                съемки – так
                                                вы сохраните
                                                время, когда
                                                дым будет в
                                                действии.
                                            </p>
                                            <p>
                                                <strong>
                                                    Не для
                                                    ветреной
                                                    погоды.
                                                </strong>{" "}
                                                Сильный
                                                ветер – враг
                                                цветного
                                                дыма на
                                                фотосессии.
                                                Несмотря на
                                                насыщенность
                                                и густоту
                                                эффекта, вся
                                                красота
                                                развеется в
                                                считанные
                                                секунды.
                                            </p>
                                            <p>
                                                <strong>
                                                    Нужен
                                                    запас.
                                                </strong>{" "}
                                                Чтобы не
                                                испортить
                                                настроение,
                                                если дым
                                                развеется
                                                слишком
                                                быстро, а
                                                классных
                                                фото
                                                получилось
                                                не так много
                                                – возьмите с
                                                собой
                                                запасной
                                                дым.
                                            </p>
                                            <p>
                                                <strong>
                                                    Необходим
                                                    ассистент.
                                                </strong>{" "}
                                                Во время
                                                фотосессии
                                                не всегда
                                                можно
                                                обойтись без
                                                помощника,
                                                который
                                                запустит дым
                                                и разбросает
                                                его в нужное
                                                место.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <ul className="article__gallery">
                                    <li className="article__gallery-item">
                                        <a
                                            className="article__gallery-link"
                                            href="imgwebp/pages/articles/plusi-i-minusi-tsvetnogo-dima/gallery/full/plusi-i-minusi-tsvetnogo-dima-1.webp"
                                            data-fslightbox="plusi-i-minusi-tsvetnogo-dima"
                                        >
                                            <picture>
                                                <source
                                                    srcSet="imgwebp/pages/articles/plusi-i-minusi-tsvetnogo-dima/gallery/preview/plusi-i-minusi-tsvetnogo-dima-1.webp"
                                                    type="image/webp"
                                                />
                                                <img
                                                    src="#"
                                                    data-src="img/pages/articles/plusi-i-minusi-tsvetnogo-dima/gallery/preview/plusi-i-minusi-tsvetnogo-dima-1.jpg"
                                                    alt="Плюсы и минусы цветного дыма 1"
                                                    className="article__gallery-img"
                                                />
                                            </picture>
                                        </a>
                                    </li>
                                    <li className="article__gallery-item">
                                        <a
                                            className="article__gallery-link"
                                            href="imgwebp/pages/articles/plusi-i-minusi-tsvetnogo-dima/gallery/full/plusi-i-minusi-tsvetnogo-dima-2.webp"
                                            data-fslightbox="plusi-i-minusi-tsvetnogo-dima"
                                        >
                                            <picture>
                                                <source
                                                    srcSet="imgwebp/pages/articles/plusi-i-minusi-tsvetnogo-dima/gallery/preview/plusi-i-minusi-tsvetnogo-dima-2.webp"
                                                    type="image/webp"
                                                />
                                                <img
                                                    src="#"
                                                    data-src="img/pages/articles/plusi-i-minusi-tsvetnogo-dima/gallery/preview/plusi-i-minusi-tsvetnogo-dima-2.jpg"
                                                    alt="Плюсы и минусы цветного дыма 2"
                                                    className="article__gallery-img"
                                                />
                                            </picture>
                                        </a>
                                    </li>
                                    <li className="article__gallery-item">
                                        <a
                                            className="article__gallery-link"
                                            href="imgwebp/pages/articles/plusi-i-minusi-tsvetnogo-dima/gallery/full/plusi-i-minusi-tsvetnogo-dima-3.webp"
                                            data-fslightbox="plusi-i-minusi-tsvetnogo-dima"
                                        >
                                            <picture>
                                                <source
                                                    srcSet="imgwebp/pages/articles/plusi-i-minusi-tsvetnogo-dima/gallery/preview/plusi-i-minusi-tsvetnogo-dima-3.webp"
                                                    type="image/webp"
                                                />
                                                <img
                                                    src="#"
                                                    data-src="img/pages/articles/plusi-i-minusi-tsvetnogo-dima/gallery/preview/plusi-i-minusi-tsvetnogo-dima-3.jpg"
                                                    alt="Плюсы и минусы цветного дыма 3"
                                                    className="article__gallery-img"
                                                />
                                            </picture>
                                        </a>
                                    </li>
                                    <li className="article__gallery-item">
                                        <a
                                            className="article__gallery-link"
                                            href="imgwebp/pages/articles/plusi-i-minusi-tsvetnogo-dima/gallery/full/plusi-i-minusi-tsvetnogo-dima-4.webp"
                                            data-fslightbox="plusi-i-minusi-tsvetnogo-dima"
                                        >
                                            <picture>
                                                <source
                                                    srcSet="imgwebp/pages/articles/plusi-i-minusi-tsvetnogo-dima/gallery/preview/plusi-i-minusi-tsvetnogo-dima-4.webp"
                                                    type="image/webp"
                                                />
                                                <img
                                                    src="#"
                                                    data-src="img/pages/articles/plusi-i-minusi-tsvetnogo-dima/gallery/preview/plusi-i-minusi-tsvetnogo-dima-4.jpg"
                                                    alt="Плюсы и минусы цветного дыма 4"
                                                    className="article__gallery-img"
                                                />
                                            </picture>
                                        </a>
                                    </li>
                                </ul>
                                <div
                                    className="article__flex"
                                    itemProp="description"
                                >
                                    <div className="article__info">
                                        <h3 className="article__subtitle">
                                            Подводим итоги
                                        </h3>
                                        <div className="article__descr">
                                            <p>
                                                Как любой
                                                пиротехнический
                                                продукт,
                                                цветной дым
                                                требует
                                                осторожности
                                                во время его
                                                использования.
                                                Но ведь ты
                                                хочешь
                                                красивую и
                                                яркую
                                                фотосессию?
                                                Если так –
                                                даже не
                                                сомневайся!
                                            </p>
                                        </div>
                                    </div>
                                    <picture itemProp="image">
                                        <source
                                            srcSet="imgwebp/pages/articles/plusi-i-minusi-tsvetnogo-dima/itogi.webp"
                                            type="image/webp"
                                        />
                                        <img
                                            src="#"
                                            data-src="img/pages/articles/plusi-i-minusi-tsvetnogo-dima/itogi.jpg"
                                            alt="Итоги"
                                            className="article__img"
                                        />
                                    </picture>
                                </div>
                                <ul className="article__buttons">
                                    <li className="article__buttons-item">
                                        <button
                                            onClick={Prev}
                                            className="article__buttons-button article__buttons-button_prev"
                                        >
                                            <span
                                                className="article__buttons-arrow article__buttons-arrow_prev"
                                                data-animate-fill
                                            >
                                                <svg
                                                    className="article__buttons-img"
                                                    width="12"
                                                    height="22"
                                                    viewBox="0 0 12 22"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M11 21L0.999999 11L11 1"
                                                        strokeWidth="1.5"
                                                    />
                                                </svg>{" "}
                                            </span>
                                            <span className="article__buttons-text article__buttons-text_prev">
                                                Предыдущая
                                                статья
                                            </span>
                                        </button>
                                    </li>
                                    <li className="article__buttons-item">
                                        <button
                                            onClick={Next}
                                            className="article__buttons-button article__buttons-button_next"
                                        >
                                            <span className="article__buttons-text article__buttons-text_next">
                                                Следующая
                                                статья
                                            </span>
                                            <span
                                                className="article__buttons-arrow article__buttons-arrow_next"
                                                data-animate-fill
                                            >
                                                <svg
                                                    className="article__buttons-img"
                                                    width="12"
                                                    height="22"
                                                    viewBox="0 0 12 22"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1 1L11 11L0.999997 21"
                                                        strokeWidth="1.5"
                                                    />
                                                </svg>
                                            </span>
                                        </button>
                                    </li>
                                </ul>
                            </article>
                        </li>
                        <li
                            className={
                                punkt === 6
                                    ? "articles__tabs-item articles__tabs-item_active"
                                    : "articles__tabs-item"
                            }
                        >
                            <article
                                className="articles__tabs-article article"
                                itemScope
                                itemType="https://schema.org/Article"
                            >
                                <h2
                                    className="article__title"
                                    itemProp="name"
                                >
                                    Цветной дым на свадьбе
                                </h2>
                                <time
                                    className="article__time"
                                    dateTime="2022-04-09"
                                    itemProp="datePublished"
                                    content="2022-04-09"
                                >
                                    09/04/2022
                                </time>
                                <div
                                    className="article__flex article__flex_intro"
                                    itemProp="description"
                                >
                                    <div className="article__info">
                                        <h3 className="article__subtitle">
                                            Креативный,
                                            компактный,
                                            добавляет
                                            динамики и объём
                                            фотографиям,
                                            недорогой
                                        </h3>
                                        <div className="article__descr">
                                            <p>
                                                Для
                                                эффектной и
                                                интересной
                                                съёмки в
                                                первую
                                                очередь
                                                отталкивайтесь
                                                от общей
                                                концепции
                                                свадьбы.
                                                Если у
                                                торжества
                                                нет четкой
                                                цели, то
                                                фантазируйте,
                                                придумайте
                                                свой сюжет.
                                            </p>
                                            <p>
                                                Поиграйте с
                                                цветом:
                                                создайте
                                                контраст
                                                (например с
                                                платьем
                                                невесты) или
                                                наоборот
                                                продублируйте
                                                оттенки,
                                                которые есть
                                                в вашем
                                                образе
                                                (букет,
                                                аксессуары),
                                                ️создайте
                                                мини-фотоисторию,
                                                где дым
                                                добавит
                                                экшена и
                                                атмосферы,
                                                ️привлекайте
                                                гостей для
                                                воплощения
                                                масштабных
                                                идей,
                                                фотограф
                                                сможет
                                                поиграть с
                                                ракурсом.
                                            </p>
                                        </div>
                                    </div>
                                    <picture>
                                        <source
                                            srcSet="imgwebp/pages/articles/tsvetnoi-dim-na-svadbe/kreativnii.webp"
                                            type="image/webp"
                                        />
                                        <img
                                            src="#"
                                            data-src="img/pages/articles/tsvetnoi-dim-na-svadbe/kreativnii.jpg"
                                            alt="Креативный, компактный, недорогой"
                                            className="article__img article__img_intro"
                                        />
                                    </picture>
                                </div>
                                <p className="article__warning">
                                    Не только идеальные
                                    постановочные, но и
                                    забавные фото имеют
                                    право быть в семейном
                                    архиве
                                </p>
                                <div
                                    className="article__flex"
                                    itemProp="description"
                                >
                                    <picture>
                                        <source
                                            srcSet="imgwebp/pages/articles/tsvetnoi-dim-na-svadbe/kruto.webp"
                                            type="image/webp"
                                        />
                                        <img
                                            src="#"
                                            data-src="img/pages/articles/tsvetnoi-dim-na-svadbe/kruto.jpg"
                                            alt="Цветной дым - это круто!"
                                            className="article__img"
                                        />
                                    </picture>
                                    <div className="article__info">
                                        <h3 className="article__subtitle">
                                            Цветной дым –
                                            это круто и
                                            необычно, но не
                                            стоит делать его
                                            главным «героем»
                                            фотокадров.
                                            Пусть в главной
                                            роли будет
                                            любовь двоих,
                                            искренняя
                                            радость и
                                            счастье.
                                        </h3>
                                    </div>
                                </div>
                                <ul className="article__gallery">
                                    <li className="article__gallery-item">
                                        <a
                                            className="article__gallery-link"
                                            href="imgwebp/pages/articles/tsvetnoi-dim-na-svadbe/gallery/full/tsvetnoi-dim-na-svadbe-1.webp"
                                            data-fslightbox="tsvetnoi-dim-na-svadbe"
                                        >
                                            <picture>
                                                <source
                                                    srcSet="imgwebp/pages/articles/tsvetnoi-dim-na-svadbe/gallery/preview/tsvetnoi-dim-na-svadbe-1.webp"
                                                    type="image/webp"
                                                />
                                                <img
                                                    src="#"
                                                    data-src="img/pages/articles/tsvetnoi-dim-na-svadbe/gallery/preview/tsvetnoi-dim-na-svadbe-1.jpg"
                                                    alt="Цветной дым на свадьбе 1"
                                                    className="article__gallery-img"
                                                />
                                            </picture>
                                        </a>
                                    </li>
                                    <li className="article__gallery-item">
                                        <a
                                            className="article__gallery-link"
                                            href="imgwebp/pages/articles/tsvetnoi-dim-na-svadbe/gallery/full/tsvetnoi-dim-na-svadbe-2.webp"
                                            data-fslightbox="tsvetnoi-dim-na-svadbe"
                                        >
                                            <picture>
                                                <source
                                                    srcSet="imgwebp/pages/articles/tsvetnoi-dim-na-svadbe/gallery/preview/tsvetnoi-dim-na-svadbe-2.webp"
                                                    type="image/webp"
                                                />
                                                <img
                                                    src="#"
                                                    data-src="img/pages/articles/tsvetnoi-dim-na-svadbe/gallery/preview/tsvetnoi-dim-na-svadbe-2.jpg"
                                                    alt="Цветной дым на свадьбе 2"
                                                    className="article__gallery-img"
                                                />
                                            </picture>
                                        </a>
                                    </li>
                                    <li className="article__gallery-item">
                                        <a
                                            className="article__gallery-link"
                                            href="imgwebp/pages/articles/tsvetnoi-dim-na-svadbe/gallery/full/tsvetnoi-dim-na-svadbe-3.webp"
                                            data-fslightbox="tsvetnoi-dim-na-svadbe"
                                        >
                                            <picture>
                                                <source
                                                    srcSet="imgwebp/pages/articles/tsvetnoi-dim-na-svadbe/gallery/preview/tsvetnoi-dim-na-svadbe-3.webp"
                                                    type="image/webp"
                                                />
                                                <img
                                                    src="#"
                                                    data-src="img/pages/articles/tsvetnoi-dim-na-svadbe/gallery/preview/tsvetnoi-dim-na-svadbe-3.jpg"
                                                    alt="Цветной дым на свадьбе 3"
                                                    className="article__gallery-img"
                                                />
                                            </picture>
                                        </a>
                                    </li>
                                    <li className="article__gallery-item">
                                        <a
                                            className="article__gallery-link"
                                            href="imgwebp/pages/articles/tsvetnoi-dim-na-svadbe/gallery/full/tsvetnoi-dim-na-svadbe-4.webp"
                                            data-fslightbox="tsvetnoi-dim-na-svadbe"
                                        >
                                            <picture>
                                                <source
                                                    srcSet="imgwebp/pages/articles/tsvetnoi-dim-na-svadbe/gallery/preview/tsvetnoi-dim-na-svadbe-4.webp"
                                                    type="image/webp"
                                                />
                                                <img
                                                    src="#"
                                                    data-src="img/pages/articles/tsvetnoi-dim-na-svadbe/gallery/preview/tsvetnoi-dim-na-svadbe-4.jpg"
                                                    alt="Цветной дым на свадьбе 4"
                                                    className="article__gallery-img"
                                                />
                                            </picture>
                                        </a>
                                    </li>
                                </ul>
                                <ul className="article__buttons">
                                    <li className="article__buttons-item">
                                        <button
                                            onClick={Prev}
                                            className="article__buttons-button article__buttons-button_prev"
                                        >
                                            <span
                                                className="article__buttons-arrow article__buttons-arrow_prev"
                                                data-animate-fill
                                            >
                                                <svg
                                                    className="article__buttons-img"
                                                    width="12"
                                                    height="22"
                                                    viewBox="0 0 12 22"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M11 21L0.999999 11L11 1"
                                                        strokeWidth="1.5"
                                                    />
                                                </svg>{" "}
                                            </span>
                                            <span className="article__buttons-text article__buttons-text_prev">
                                                Предыдущая
                                                статья
                                            </span>
                                        </button>
                                    </li>
                                    <li className="article__buttons-item">
                                        <button
                                            onClick={Next}
                                            className="article__buttons-button article__buttons-button_next"
                                        >
                                            <span className="article__buttons-text article__buttons-text_next">
                                                Следующая
                                                статья
                                            </span>
                                            <span
                                                className="article__buttons-arrow article__buttons-arrow_next"
                                                data-animate-fill
                                            >
                                                <svg
                                                    className="article__buttons-img"
                                                    width="12"
                                                    height="22"
                                                    viewBox="0 0 12 22"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1 1L11 11L0.999997 21"
                                                        strokeWidth="1.5"
                                                    />
                                                </svg>
                                            </span>
                                        </button>
                                    </li>
                                </ul>
                            </article>
                        </li>
                        <li
                            className={
                                punkt === 7
                                    ? "articles__tabs-item articles__tabs-item_active"
                                    : "articles__tabs-item"
                            }
                        >
                            <article
                                className="articles__tabs-article article"
                                itemScope
                                itemType="https://schema.org/Article"
                            >
                                <h2
                                    className="article__title"
                                    itemProp="name"
                                >
                                    10 правил безопасного
                                    запуска фейерверка
                                </h2>
                                <time
                                    className="article__time"
                                    dateTime="2022-05-03"
                                    itemProp="datePublished"
                                    content="2022-05-03"
                                >
                                    03/05/2022
                                </time>
                                <div
                                    className="article__flex article__flex_intro"
                                    itemProp="description"
                                >
                                    <div className="article__info">
                                        <h3 className="article__subtitle">
                                            Купили
                                            фейерверк?
                                            Отлично, мы
                                            расскажем, как
                                            правильно и
                                            безопасно его
                                            запустить!️
                                        </h3>
                                        <div className="article__descr">
                                            <p>
                                                <strong>
                                                    ПОКУПАЕМ
                                                    СЕРТИФИЦИРОВАННУЮ
                                                    ПИРОТЕХНИКУ.
                                                </strong>{" "}
                                                Покупайте
                                                пиротехнику
                                                только в
                                                специализированных
                                                магазинах,
                                                именно там
                                                вам
                                                предоставят
                                                сертификацию
                                                качества на
                                                всю
                                                продукцию.
                                            </p>
                                            <p>
                                                <strong>
                                                    СМОТРИМ
                                                    НА СРОК
                                                    ГОДНОСТИ.
                                                </strong>{" "}
                                                При покупке,
                                                обратите
                                                внимание на
                                                срок
                                                годности и
                                                состояние
                                                упаковки.
                                                ️Если вы
                                                планируете
                                                запускать
                                                фейерверк НЕ
                                                В ДЕНЬ
                                                ПОКУПКИ –
                                                выберете для
                                                его хранения
                                                сухое место
                                                и подальше
                                                от
                                                нагревательных
                                                приборов,
                                                солнечных
                                                лучей.
                                            </p>
                                            <p>
                                                <strong>
                                                    ВЫБИРАЕМ
                                                    ПРАВИЛЬНОЕ
                                                    МЕСТО
                                                    ДЛЯ
                                                    УСТАНОВКИ.
                                                </strong>{" "}
                                                Выберете
                                                площадку не
                                                меньше 50х50
                                                метров,
                                                свободную от
                                                препятствий
                                                (не должно
                                                быть
                                                деревьев,
                                                автомобилей
                                                и построек в
                                                радиусе
                                                50м).
                                            </p>
                                            <p>
                                                <strong>
                                                    УЧИТЫВАЕМ
                                                    ПОГОДНЫЕ
                                                    УСЛОВИЯ.
                                                </strong>{" "}
                                                Запрещается
                                                запускать
                                                фейерверк
                                                при сильном
                                                порывистом
                                                ветре.
                                            </p>
                                        </div>
                                    </div>
                                    <picture>
                                        <source
                                            srcSet="imgwebp/pages/articles/10-pravil/bezopasnost.webp"
                                            type="image/webp"
                                        />
                                        <img
                                            src="#"
                                            data-src="img/pages/articles/10-pravil/bezopasnost.jpg"
                                            alt="Безопасность"
                                            className="article__img article__img_intro"
                                        />
                                    </picture>
                                </div>
                                <p className="article__warning">
                                    Бытовой фейерверк вы
                                    можете запустить
                                    самостоятельно, без
                                    помощи пиротехника,
                                    следуя нашим
                                    рекомендациям. При этом,
                                    вы должны быть
                                    совершеннолетним и в
                                    трезвом состоянии.
                                </p>
                                <div
                                    className="article__flex"
                                    itemProp="description"
                                >
                                    <picture>
                                        <source
                                            srcSet="imgwebp/pages/articles/10-pravil/pravila.webp"
                                            type="image/webp"
                                        />
                                        <img
                                            src="#"
                                            data-src="img/pages/articles/10-pravil/pravila.jpg"
                                            alt="Правила"
                                            className="article__img"
                                        />
                                    </picture>
                                    <div className="article__info">
                                        <div className="article__descr">
                                            <p>
                                                <strong>
                                                    УСТАНАВЛИВАЕМ
                                                    ФЕЙЕРВЕРК.
                                                </strong>{" "}
                                                Установите
                                                фейерверк
                                                строго
                                                вертикально
                                                на ровную
                                                поверхность
                                                и
                                                зафиксируйте
                                                (песком или
                                                кирпичами).
                                                Это связано
                                                с тем, что
                                                отдача при
                                                выстреле
                                                может
                                                перевернуть
                                                батарею,
                                                непредсказуемо
                                                изменив
                                                направление
                                                залпов. На
                                                упаковке
                                                написано –
                                                что
                                                поджигать,
                                                куда
                                                направлять и
                                                как
                                                устанавливать,
                                                ознакомьтесь
                                                с
                                                инструкцией.
                                            </p>
                                            <p>
                                                <strong>
                                                    ЗАЖИГАЕМ
                                                    ФИТИЛЬ.
                                                </strong>{" "}
                                                Поджигайте
                                                фитиль
                                                фейерверка
                                                только НА
                                                ВЫТЯНУТОЙ
                                                РУКЕ, не
                                                наклоняйтесь
                                                над
                                                установкой.
                                            </p>
                                            <p>
                                                <strong>
                                                    ДЕРЖИМ
                                                    ДИСТАНЦИЮ.
                                                </strong>{" "}
                                                Сразу после
                                                воспламенения
                                                отойдите на
                                                безопасное
                                                расстояние –
                                                20м или
                                                более. Когда
                                                будет
                                                стрелять
                                                фейерверк,
                                                не подходите
                                                ближе,
                                                выберете
                                                точку с
                                                хорошим
                                                обзором
                                                заранее.
                                                Радиус
                                                опасной зоны
                                                для батареи
                                                салютов –
                                                20-50м.
                                            </p>
                                            <p>
                                                <strong>
                                                    НАСЛАЖДАЕМСЯ
                                                    КРАСОТОЙ.
                                                </strong>{" "}
                                                Теперь,
                                                когда всё
                                                готово –
                                                можно
                                                наслаждаться
                                                красивыми
                                                огнями в
                                                небе.
                                            </p>
                                            <p>
                                                <strong>
                                                    ВЫЖИДАЕМ
                                                    ВРЕМЯ.
                                                </strong>{" "}
                                                После
                                                завершения
                                                фейерверка,
                                                не подходите
                                                к установке
                                                ещё минут
                                                5-10. Внутри
                                                могут
                                                остаться
                                                заряды,
                                                которые
                                                сработают
                                                только через
                                                некоторое
                                                время из-за
                                                тления
                                                корпуса.
                                            </p>
                                            <p>
                                                <strong>
                                                    УТИЛИЗИРУЕМ
                                                    КОРОБКУ.
                                                </strong>{" "}
                                                Тщательно
                                                соберите
                                                всё, что
                                                осталось
                                                после
                                                запуска
                                                фейерверка и
                                                утилизируйте.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <ul className="article__gallery">
                                    <li className="article__gallery-item">
                                        <a
                                            className="article__gallery-link"
                                            href="imgwebp/pages/articles/10-pravil/gallery/full/10-pravil-1.webp"
                                            data-fslightbox="10-pravil"
                                        >
                                            <picture>
                                                <source
                                                    srcSet="imgwebp/pages/articles/10-pravil/gallery/preview/10-pravil-1.webp"
                                                    type="image/webp"
                                                />
                                                <img
                                                    src="#"
                                                    data-src="img/pages/articles/10-pravil/gallery/preview/10-pravil-1.jpg"
                                                    alt="10 правил безопасного запуска фейерверка 1"
                                                    className="article__gallery-img"
                                                />
                                            </picture>
                                        </a>
                                    </li>
                                    <li className="article__gallery-item">
                                        <a
                                            className="article__gallery-link"
                                            href="imgwebp/pages/articles/10-pravil/gallery/full/10-pravil-2.webp"
                                            data-fslightbox="10-pravil"
                                        >
                                            <picture>
                                                <source
                                                    srcSet="imgwebp/pages/articles/10-pravil/gallery/preview/10-pravil-2.webp"
                                                    type="image/webp"
                                                />
                                                <img
                                                    src="#"
                                                    data-src="img/pages/articles/10-pravil/gallery/preview/10-pravil-2.jpg"
                                                    alt="10 правил безопасного запуска фейерверка 2"
                                                    className="article__gallery-img"
                                                />
                                            </picture>
                                        </a>
                                    </li>
                                    <li className="article__gallery-item">
                                        <a
                                            className="article__gallery-link"
                                            href="imgwebp/pages/articles/10-pravil/gallery/full/10-pravil-3.webp"
                                            data-fslightbox="10-pravil"
                                        >
                                            <picture>
                                                <source
                                                    srcSet="imgwebp/pages/articles/10-pravil/gallery/preview/10-pravil-3.webp"
                                                    type="image/webp"
                                                />
                                                <img
                                                    src="#"
                                                    data-src="img/pages/articles/10-pravil/gallery/preview/10-pravil-3.jpg"
                                                    alt="10 правил безопасного запуска фейерверка 3"
                                                    className="article__gallery-img"
                                                />
                                            </picture>
                                        </a>
                                    </li>
                                    <li className="article__gallery-item">
                                        <a
                                            className="article__gallery-link"
                                            href="imgwebp/pages/articles/10-pravil/gallery/full/10-pravil-4.webp"
                                            data-fslightbox="10-pravil"
                                        >
                                            <picture>
                                                <source
                                                    srcSet="imgwebp/pages/articles/10-pravil/gallery/preview/10-pravil-4.webp"
                                                    type="image/webp"
                                                />
                                                <img
                                                    src="#"
                                                    data-src="img/pages/articles/10-pravil/gallery/preview/10-pravil-4.jpg"
                                                    alt="10 правил безопасного запуска фейерверка 4"
                                                    className="article__gallery-img"
                                                />
                                            </picture>
                                        </a>
                                    </li>
                                </ul>
                                <div
                                    className="article__flex"
                                    itemProp="description"
                                >
                                    <div className="article__info">
                                        <h3 className="article__subtitle">
                                            Что делать, если
                                            фейерверк не
                                            выстрелил
                                        </h3>
                                        <div className="article__descr">
                                            <p>
                                                Главное – не
                                                бегите сразу
                                                же к нему,
                                                бракованные
                                                салюты могут
                                                сработать с
                                                задержкой.
                                                Поэтому,
                                                подождите
                                                10-15 минут,
                                                убедитесь,
                                                что
                                                фейерверк не
                                                горит и не
                                                тлеет (нет
                                                искр и
                                                дыма).
                                                Только после
                                                этого можно
                                                подойти и
                                                аккуратно
                                                посмотреть.
                                                Попробуйте
                                                зажечь
                                                запасной
                                                фитиль. Если
                                                же и в этом
                                                случае
                                                фейерверк не
                                                выстрелил –
                                                через
                                                некоторое
                                                время
                                                погрузите
                                                батарею в
                                                воду на
                                                несколько
                                                часов и
                                                только потом
                                                утилизируйте.
                                            </p>
                                            <p>
                                                Мы даём
                                                гарантию на
                                                всю
                                                пиротехнику.
                                                Если вдруг
                                                наш
                                                фейерверк не
                                                взорвался –
                                                мы поменяем
                                                вам коробку
                                                или вернём
                                                деньги (для
                                                этого нужна
                                                будет
                                                коробка от
                                                вашего
                                                салюта). Мы
                                                уверены в
                                                своей
                                                продукции.
                                            </p>
                                            <p>
                                                Запускайте
                                                фейерверк
                                                правильно и
                                                безопасно и
                                                наслаждайтесь
                                                красивыми
                                                спецэффектами.
                                            </p>
                                        </div>
                                    </div>
                                    <picture itemProp="image">
                                        <source
                                            srcSet="imgwebp/pages/articles/10-pravil/ne-vistrelil.webp"
                                            type="image/webp"
                                        />
                                        <img
                                            src="#"
                                            data-src="img/pages/articles/10-pravil/ne-vistrelil.jpg"
                                            alt="Что делать, если фейерверк не выстрелил"
                                            className="article__img"
                                        />
                                    </picture>
                                </div>
                                <ul className="article__buttons">
                                    <li className="article__buttons-item">
                                        <button
                                            onClick={Prev}
                                            className="article__buttons-button article__buttons-button_prev"
                                        >
                                            <span
                                                className="article__buttons-arrow article__buttons-arrow_prev"
                                                data-animate-fill
                                            >
                                                <svg
                                                    className="article__buttons-img"
                                                    width="12"
                                                    height="22"
                                                    viewBox="0 0 12 22"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M11 21L0.999999 11L11 1"
                                                        strokeWidth="1.5"
                                                    />
                                                </svg>{" "}
                                            </span>
                                            <span className="article__buttons-text article__buttons-text_prev">
                                                Предыдущая
                                                статья
                                            </span>
                                        </button>
                                    </li>
                                    <li className="article__buttons-item">
                                        <button className="article__buttons-button article__buttons-button_next article__buttons-button_inactive">
                                            <span className="article__buttons-text article__buttons-text_next">
                                                Следующая
                                                статья
                                            </span>
                                            <span
                                                className="article__buttons-arrow article__buttons-arrow_next"
                                                data-animate-fill
                                            >
                                                <svg
                                                    className="article__buttons-img"
                                                    width="12"
                                                    height="22"
                                                    viewBox="0 0 12 22"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1 1L11 11L0.999997 21"
                                                        strokeWidth="1.5"
                                                    />
                                                </svg>
                                            </span>
                                        </button>
                                    </li>
                                </ul>
                            </article>
                        </li>
                    </ul>
                </section>
            </main>
        </>
    );
}
