import ActionBlock from "../bloc/actionBlock";
import Description from "../bloc/description";
import Stages from "../bloc/stages";
import Subscribe from "../bloc/subscribe";
import "../css/heavy-smoke.css";

export default function HeavySmoke(params) {
    return (
        <main className="main">
            <Description
                name={"Тяжелый дым"}
                text={
                    "«Салют Ульяновск» поможет добавить Вашему празднику ощущение сказочности, таинственности, использовав эффект «тяжёлого дыма», внешне напоминающего живой, ползущий по земле туман, впоследствии бесследно исчезающий."
                }
                gall={
                    <ul className="description__gallery">
                        <li className="description__gallery-item">
                            <a
                                className="description__gallery-link"
                                href="img/pages/heavy-smoke/gallery/1.jpg"
                                data-fslightbox="heavy-smoke"
                            >
                                <img
                                    className="description__gallery-image"
                                    src="img/pages/heavy-smoke/gallery/1.jpg"
                                />
                            </a>
                        </li>
                        <li className="description__gallery-item">
                            <a
                                className="description__gallery-link"
                                href="img/pages/heavy-smoke/gallery/2.jpg"
                                data-fslightbox="heavy-smoke"
                            >
                                <img
                                    className="description__gallery-image"
                                    src="img/pages/heavy-smoke/gallery/2.jpg"
                                />
                            </a>
                        </li>
                        <li className="description__gallery-item">
                            <a
                                className="description__gallery-link"
                                href="img/pages/heavy-smoke/gallery/3.jpg"
                                data-fslightbox="heavy-smoke"
                            >
                                <img
                                    className="description__gallery-image"
                                    src="img/pages/heavy-smoke/gallery/3.jpg"
                                />
                            </a>
                        </li>
                        <li className="description__gallery-item">
                            <a
                                className="description__gallery-link"
                                href="img/pages/heavy-smoke/gallery/4.jpg"
                                data-fslightbox="heavy-smoke"
                            >
                                <img
                                    className="description__gallery-image"
                                    src="img/pages/heavy-smoke/gallery/4.jpg"
                                />
                            </a>
                        </li>
                    </ul>
                }
                fease={
                    <>
                        <source
                            srcSet="img/pages/heavy-smoke/bg.jpg"
                            // srcSet="imgwebp/pages/heavy-smoke/bg.webp"
                            type="image/webp"
                        />
                        <img
                            src="img/pages/heavy-smoke/bg.jpg"
                            alt="Тяжелый дым"
                            className="description__img"
                        />
                    </>
                }
            />

            <section className="content section">
                <div className="content__wrapper">
                    <div className="content__info">
                        <h2 className="section-title">
                            Заказать
                            тяжелый дым
                        </h2>
                        <div className="content__descr">
                            <p className="content__descr-text">
                                Густой пар генерируется
                                специальным генератором,
                                основой является сухой лёд.
                                20-25 кг. льда достаточно,
                                чтобы обеспечить действие на
                                3-5 минут. За это время
                                фотограф успеет сделать
                                отличные кадры, а Ваши гости
                                насладятся волнующей и
                                романтичной атмосферой.
                                Следов на одежде не
                                остаётся, как и запаха, что
                                делает данный элемент
                                привлекательным. При покупке
                                нескольких спецэффектов
                                дарим приятные скидки и
                                бонусы. Заказать тяжёлый дым
                                и установить технику – дело
                                нескольких минут, а
                                впечатления, оставшиеся от
                                данного спецэффекта
                                останутся надолго!
                            </p>
                            <p className="content__descr-text">
                                Тяжёлый дым не имеет
                                неприятного запаха, не
                                пачкает одежду и предметы
                                интерьера, совершенно
                                безвреден для окружающих.
                                Благодаря высокой плотности,
                                он рассеивается достаточно
                                медленно, что позволяет
                                провести целую фотосессию, в
                                которой могут поучаствовать
                                все гости.
                            </p>
                        </div>
                        <button
                            className="content__order final__button"
                            data-animate-fill
                            data-fos
                        >
                            <span>Забронировать</span>
                        </button>
                    </div>
                    <picture>
                        <source
                            srcSet="img/pages/heavy-smoke/content/order.jpg"
                            type="image/webp"
                        />
                        <img
                            src="img/pages/heavy-smoke/content/order.jpg"
                            alt="Тяжелый дым"
                            className="content__img"
                        />
                    </picture>
                </div>
            </section>
            <Stages/>
            <ActionBlock/>
            <Subscribe/>
        </main>
    );
}
