import ActionBlock from "../bloc/actionBlock";
import Description from "../bloc/description";
import Stages from "../bloc/stages";
import Subscribe from "../bloc/subscribe";
import "../css/salut-ulyanovsk.css";
import ModalMain from "../modul/modalMainPopup";

export default function SalutUlyanovsk(params) {
    return (
       <>
        <main className="main">
        <Description
                    name={"Салют Ульяновск"}
                    text={
                        "В жизни людей случаются яркие моменты, которые не хочется отпускать, прокручивая снова и снова. Мы хотим сделать любое знаковое для Вас событие запоминающимся, красочным и фееричным. Нам помогут необычные огненные эффекты, чьё разнообразие приятно Вас удивит."
                    }
                    gall={
                        <ul className="description__gallery">
                            <li className="description__gallery-item">
                                <a
                                    className="description__gallery-link"
                                    href="img/pages/salut-ulyanovsk/gallery/1.jpg"
                                    data-fslightbox="salut-ulyanovsk"
                                >
                                    <img
                                        className="description__gallery-image"
                                        src="img/pages/salut-ulyanovsk/gallery/1.jpg"
                                    />
                                </a>
                            </li>
                            <li className="description__gallery-item">
                                <a
                                    className="description__gallery-link"
                                    href="img/pages/salut-ulyanovsk/gallery/2.jpg"
                                    data-fslightbox="salut-ulyanovsk"
                                >
                                    <img
                                        className="description__gallery-image"
                                        src="img/pages/salut-ulyanovsk/gallery/2.jpg"
                                    />
                                </a>
                            </li>
                            <li className="description__gallery-item">
                                <a
                                    className="description__gallery-link"
                                    href="img/pages/salut-ulyanovsk/gallery/3.jpg"
                                    data-fslightbox="salut-ulyanovsk"
                                >
                                    <img
                                        className="description__gallery-image"
                                        src="img/pages/salut-ulyanovsk/gallery/3.jpg"
                                    />
                                </a>
                            </li>
                            <li className="description__gallery-item">
                                <a
                                    className="description__gallery-link"
                                    href="img/pages/salut-ulyanovsk/gallery/4.jpg"
                                    data-fslightbox="salut-ulyanovsk"
                                >
                                    <img
                                        className="description__gallery-image"
                                        src="img/pages/salut-ulyanovsk/gallery/4.jpg"
                                    />
                                </a>
                            </li>
                        </ul>
                    }
                    fease={
                        <>
                        <source
                srcSet="img/pages/salut-ulyanovsk/bg.jpg"
                type="image/webp"
            />
            <img
                src="img/pages/salut-ulyanovsk/bg.jpg"
                alt="Салют Ульяновск"
                className="description__img"
            /></>
                    }
                />
            <Stages/>
            <ActionBlock/>
            <Subscribe/>
            

        </main>
        {/* <ModalMain/> */}
        
        </>
    );
}
